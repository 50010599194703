import React from "react";
import ClassNames from 'classnames';
import { Hidden } from 'react-grid-system';
import componentStyles from './Tab.module.scss';

const Tab = ({
    id,
    icon,
    text, 
    badgeNum,
    isSelected,
    clickEvt,
    disabled,
    ...props}) => {

    let badgeClass;
    if(badgeNum===undefined) badgeClass=ClassNames(componentStyles.badge, componentStyles.hidden);
    else if(parseInt(badgeNum)>=1) badgeClass=ClassNames(componentStyles.badge, componentStyles.bad)
    else badgeClass=componentStyles.badge

    return (
        <button
            id={id}
            role="tab"
            disabled={disabled}
            onClick={clickEvt}
            aria-selected={isSelected}
            aria-controls={`${id}-tab`}
            className={isSelected ? ClassNames(componentStyles.tab, componentStyles.active) : componentStyles.tab}>
            <Hidden md sm xs>
                <div aria-hidden="true" className={componentStyles.icon}>
                    {icon}
                </div>
            </Hidden>
    
            <div>{text}</div>

            <div className={badgeClass}>
                <Hidden md sm xs>
                    <span>{badgeNum}</span>
                </Hidden>
            </div>

        </button>
    );
  }
 
export default Tab;