import axios from "axios";
import {
  GET_BUILDERS,
  GET_BUILDER_NAMES,
  GET_CITY_NAMES,
  GET_ENROLMENT_CITY_NAMES,
  UPDATE_SEARCH,
  CLEAR_SEARCH,
  CLEAR_SUGGESTIONS,
  BUILDERS_LOADING,
  ITEMS_LOADING,
  PROPERTIES_LOADING,
  ERROR_LOADING,
  GET_BUILDERINFO,
  GET_BUILDERCONDOS,
  GET_BUILDERPROPS,
  GET_BUILDERENROLMENTS,
  GET_PDOCONVICTIONS,
  CLEAR_BUILDERS,
  SET_SEARCHPAGE,
  ORDER_SEARCH_RESULTS,
  FILTER_SEARCH_RESULTS,
  CLEAR_FILTERS,
  GET_ENROLMENTS,
  ORDER_ENROLMENT_SEARCH_RESULTS,
  GET_CONVICTINFO,
} from "./types";

import {
  normalizeBuilderData,
  normalizePDOs,
  getUmbrellaMemberList,
} from "../utils/normalizesearchresults";
import { buildQueryString } from "../utils/queries";

// SEARCH BUILDERS
// query builders in VB table, and convictions by builders not otherwise listed in OBD
export const getBuilders =
  (searchTerms, pageination, orderBy, orderDir) => (dispatch) => {
    dispatch(setBuildersLoading());
    axios
      .get(
        `/api/builders?${buildQueryString(
          searchTerms,
          pageination,
          orderBy,
          orderDir
        )}`
      )
      .then((res) =>
        dispatch({
          type: GET_BUILDERS,
          payload: {
            searchTerms: searchTerms,
            builders: [...normalizeBuilderData(res.data)],
          },
        })
      )
      .catch((error) =>
        dispatch({
          type: ERROR_LOADING,
          payload: error.response,
        })
      );
  };

// GET BUILDER NAMES
// query builder names based on search terms, used for auto-complete function
export const getBuilderNames = (nameQuery) => (dispatch) => {
  axios
    .get(`api/builderNames?builderName=${nameQuery}`)
    .then((res) =>
      dispatch({
        type: GET_BUILDER_NAMES,
        payload: res.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: ERROR_LOADING,
        payload: error.response,
      })
    );
};

// GET CITY NAMES
// query builder names based on search terms, used for auto-complete function
export const getCityNames = (cityQuery) => (dispatch) => {
  axios
    .get(`api/cityNames?cityName=${cityQuery}`)
    .then((res) =>
      dispatch({
        type: GET_CITY_NAMES,
        payload: res.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: ERROR_LOADING,
        payload: error.response,
      })
    );
};

export const getEnrolCityNames = (cityQuery) => (dispatch) => {
  axios
    .get(`api/cityNames?cityName=${cityQuery}`)
    .then((res) =>
      dispatch({
        type: GET_ENROLMENT_CITY_NAMES,
        payload: res.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: ERROR_LOADING,
        payload: error.response,
      })
    );
};

export const clearSuggetions = () => {
  return {
    type: CLEAR_SUGGESTIONS,
  };
};

// BUILDER DETAILS
// query builder summary, contact, condo, and conviction information by builder id
export const getBuilderInfo = (builderId) => (dispatch) => {
  dispatch(setBuildersLoading());
  axios
    .all([
      axios.get(`/api/buildersummary?id=${builderId}`),
      axios.get(`/api/builderPDOs?id=${builderId}`),
      axios.get(`/api/builderConvictions?id=${builderId}`),
      axios.get(`/api/builderConditions?id=${builderId}`),
      axios.get(`/api/builderMembers?id=${builderId}`),
    ])
    .then((res) =>
      dispatch({
        type: GET_BUILDERINFO,
        payload: {
          profiledata: res[0].data[0],
          profilePDOs:
            res[1].data.length >= 1 ? normalizePDOs(res[1].data) : null,
          profileConvictions: res[2].data ? res[2].data : [],
          profileConditions: res[3].data ? res[3].data : [],
          profileMembers:
            res[4].data.length > 1 && res[4].data[0]["Umbrella ID"] !== "NULL"
              ? getUmbrellaMemberList(res[4].data)
              : [],
        },
      })
    )
    .catch((error) => {
      console.log("error loading...", error);
      dispatch({
        type: ERROR_LOADING,
        payload: error.response,
      });
    });
};

// UMBRELLA DETAILS
// query builder summary, contact, condo, and conviction information by builder id
export const getUmbrellaInfo = (builderId) => (dispatch) => {
  dispatch(setBuildersLoading());
  axios
    .all([
      axios.get(`/api/umbrellaSummary?id=${builderId}`),
      axios.get(`/api/umbrellamembers?id=${builderId}`),
      axios.get(`/api/umbrellaproperties?id=${builderId}`),
    ])
    .then((res) =>
      dispatch({
        type: GET_BUILDERINFO,
        payload: {
          profiledata: res[0].data[0],
          profileMembers: getUmbrellaMemberList(res[1].data),
          profileProperties: res[2].data ? res[2].data : [],
          profilePDOs: null,
          profileConvictions: [],
        },
      })
    );
};

// BUILDER PROPERTIES TABLE
export const getBuilderProps = (builderId) => (dispatch) => {
  dispatch(setPropertiesLoading());
  axios.get(`/api/builderProperties?id=${builderId}`).then((res) =>
    dispatch({
      type: GET_BUILDERPROPS,
      payload: res.data,
    })
  );
};

// UMBRELLA PROPERTIES TABLE
export const getUmbrellaProps = (builderId) => (dispatch) => {
  dispatch(setPropertiesLoading());
  axios.get(`/api/umbrellaproperties?id=${builderId}`).then((res) =>
    dispatch({
      type: GET_BUILDERPROPS,
      payload: res.data,
    })
  );
};

// BUILDER CONDO PROJECTS
// query condo projects by vb number
export const getBuilderCondos = (builderId, queryID) => (dispatch) => {
  const endpoint =
    queryID === "umbrella"
      ? `/api/umbrellacondoprojects?id=${builderId}`
      : `/api/builderCondoProjects?id=${builderId}`;

  dispatch(setItemsLoading());
  axios.get(endpoint).then((res) =>
    dispatch({
      type: GET_BUILDERCONDOS,
      payload: res.data,
    })
  );
};

// PDO CONVICTIONS
// query convictions by person id
export const getPDOconvictions = (pdoArr) => (dispatch) => {
  dispatch(setItemsLoading());
  var pdoString = pdoArr.join(",");
  axios.get(`/api/pdoConvictions?id=${pdoString}`).then((res) =>
    dispatch({
      type: GET_PDOCONVICTIONS,
      payload: res.data,
    })
  );
};

// BUILDER ENROLMENTS
// query enrolments by vb number
export const getBuilderEnrolments = (builderId) => (dispatch) => {
  dispatch(setItemsLoading());
  axios.get(`/api/builderEnrolments?id=${builderId}`).then((res) =>
    dispatch({
      type: GET_BUILDERENROLMENTS,
      payload: res.data,
    })
  );
};

// UMBRELLA ENROLMENTS
// query enrolments by umbrella number
export const getUmbrellaEnrolments = (umbrellaId) => (dispatch) => {
  dispatch(setItemsLoading());
  axios.get(`/api/umbrellaEnrolments?id=${umbrellaId}`).then((res) =>
    dispatch({
      type: GET_BUILDERENROLMENTS,
      payload: res.data,
    })
  );
};

// CONVICT DETAILS
// query illegal builder/convict entries (endpoint TBD)
// TODO
export const getConvictDetails = (convictid) => (dispatch) => {
  dispatch(setBuildersLoading());
  axios.get(`/api/unlicensedsummary?id=${convictid}`).then((res) => {
    // console.log(res.data)
    dispatch({
      type: GET_CONVICTINFO,
      payload: res.data,
    });
  });
};

// UPDATE SEARCH
// update search terms (obj, uses input id's as keys)
export const updateSearch = (searchTerm) => {
  return {
    type: UPDATE_SEARCH,
    payload: searchTerm,
  };
};

// CLEAR SEARCH
// clear builder search terms
export const clearSearch = () => {
  return {
    type: CLEAR_SEARCH,
  };
};

// SET SEARCH PAGE
// used for pagination; set search page
export const setSearchPage = (pageNum) => {
  return {
    type: SET_SEARCHPAGE,
    payload: pageNum,
  };
};

// ORDER SEARCH
// orders search results by key value; sort order (asc, desc)
export const orderSearch = (orderObj) => {
  return {
    type: ORDER_SEARCH_RESULTS,
    payload: orderObj,
  };
};

// ORDER ENROLMENT SEARCH
// orders search results by key value; sort order (asc, desc)
export const orderEnrolmentSearch = (orderObj) => {
  return {
    type: ORDER_ENROLMENT_SEARCH_RESULTS,
    payload: orderObj,
  };
};

// FILTER SEARCH
// updates state filter object; filter applied on render
export const filterSearch = (filterObj) => {
  return {
    type: FILTER_SEARCH_RESULTS,
    payload: filterObj,
  };
};

// CLEAR FILTER
// clear search result filters
export const clearFilters = () => {
  return {
    type: CLEAR_FILTERS,
  };
};

// LOADING
// set loading while awaiting promise resolve
export const setBuildersLoading = () => {
  return {
    type: BUILDERS_LOADING,
  };
};

// SET ITEMS LOADING
// set page elements as loading (enrolments, condo projects etc.)
export const setItemsLoading = () => {
  return {
    type: ITEMS_LOADING,
  };
};

// SET PROPERTIES LOADING
export const setPropertiesLoading = () => {
  return {
    type: PROPERTIES_LOADING,
  };
};

// CLEAR BUILDERS
// set builders arr (search results) to null
export const clearBuilders = () => {
  return {
    type: CLEAR_BUILDERS,
  };
};

// TODO separate enorlment actions into a different reducer

// ENROLMENT SEARCH
// query warranty table
export const getEnrolments =
  (searchTerms, pageination, orderBy, orderDir) => (dispatch) => {
    dispatch(setBuildersLoading());
    axios
      .get(
        `/api/enrolmentSearch?${buildQueryString(
          searchTerms,
          pageination,
          orderBy,
          orderDir
        )}`
      )
      .then((res) =>
        dispatch({
          type: GET_ENROLMENTS,
          payload: {
            searchTerms: searchTerms,
            enrolments: res.data,
          },
        })
      )
      .catch((error) =>
        dispatch({
          type: ERROR_LOADING,
          payload: error.response,
        })
      );
  };
