import React from "react";
import { useMediaQuery } from "react-responsive";
import Moment from "react-moment";

const ConditionsTable = ({ conditionsList, ...props }) => {
  const isMobile = useMediaQuery({ maxWidth: 990 });

  return (
    <div className="overflowContainer">
      {!isMobile ? (
        <table>
          <thead>
            <tr>
              <th>Condition</th>
              <th>Effective Date</th>
            </tr>
          </thead>

          <tbody>
            {conditionsList.map((row, i) => (
              <tr>
                <td>
                  {row["HCRA_DESCRIPTION"] && (
                    <span style={{ whiteSpace: `pre-wrap` }}>
                      {row["HCRA_DESCRIPTION"]}
                    </span>
                  )}
                </td>
                <td>
                  {row["HCRA_EFFECTIVEDATE"] ? (
                    <Moment format="MMMM D, YYYY">
                      {row["HCRA_EFFECTIVEDATE"]}
                    </Moment>
                  ) : (
                    <span>---</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <>
          {conditionsList.map((row) => (
            <div className="tile">
              <div>
                <span className="label">Condition: </span>
                <span>
                  {row["HCRA_DESCRIPTION"] && (
                    <span style={{ whiteSpace: `pre-wrap` }}>
                      {row["HCRA_DESCRIPTION"]}
                    </span>
                  )}
                </span>
              </div>

              <div>
                <span className="label">Effective Date: </span>
                <span>
                  {row["HCRA_EFFECTIVEDATE"] ? (
                    <Moment format="MMMM D, YYYY">
                      {row["HCRA_EFFECTIVEDATE"]}
                    </Moment>
                  ) : (
                    <span>---</span>
                  )}
                </span>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default ConditionsTable;
