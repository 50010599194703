import React from "react";
import { Container, Row, Col } from "react-grid-system";
import PageLayout from "../layouts/MainLayout";

const ErrorPage = () => {
  return (
    <PageLayout pageHeader="Error">
      <Container>
        <Row>
          <Col md={12}>
            <p>There was an error.</p>
          </Col>
        </Row>
      </Container>
    </PageLayout>
  );
};
export default ErrorPage;
