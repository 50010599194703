import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-grid-system';
import componentStyles from './PageFooter.module.scss';
import { 
    FaAngleUp,
    FaEnvelope, 
    FaPhone,
    FaClock, 
    FaMapMarkerAlt, 
    FaTwitter, 
    FaFacebookSquare, 
    FaLinkedin,
    FaYoutube} from 'react-icons/fa';

const PageFooter = () => {

    const scrollToTop = () => {
        // window.scrollTo(0,0);
        window.scroll({
            top: 0,
            behavior: "smooth"
        })
    }

    return(
        <footer className={componentStyles.footer}>
            <div className={componentStyles.footerTop}>
            <Container>
                    <Row>
                        <Col md={8} className="pageRow">
                            <h2 className={componentStyles.underline}>Contact</h2>
                            <div className={componentStyles.contact}>
                                <div>
                                    <div className={componentStyles.contactItem}>
                                        <FaEnvelope className={componentStyles.icon}/>
                                        <a href="mailto:info@hcraontario.ca">info@hcraontario.ca</a>
                                    </div>

                                    <div className={componentStyles.contactItem}>
                                        <FaPhone className={componentStyles.icon}/>
                                        <div>
                                            <a href="tel:416-487-4272">(416) 487-HCRA (4272)</a>
                                            <br/>
                                            <span>Toll Free:</span> <a href="tel:1-800-582-7994">1-800-582-7994</a>
                                        </div>
                                    </div>
                                </div>
                                
                                <div>
                                    <div className={componentStyles.contactItem}>
                                        <FaMapMarkerAlt className={componentStyles.icon}/>
                                        <span>
                                            40 Sheppard Ave. W, Fourth Floor, Suite 400 <br/>North York ON, M2N 6K9 <br/>
                                            <a href="https://goo.gl/maps/hs4uCokxVXZnmhKM8">View Map</a>
                                        </span>
                                    </div>

                                    <div className={componentStyles.contactItem}>
                                        <FaClock className={componentStyles.icon}/>
                                        <span>
                                            Mon to Fri 9:00-5:00
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col md={3} className="pageRow">
                            <h2 className={componentStyles.underline}>Follow Us</h2>
                            <ul>
                                <li>
                                    <a aria-label="HCRA Facebook page" href="https://www.facebook.com/hcraontario">
                                        <span className={componentStyles.icon}><FaFacebookSquare/></span>
                                    </a>
                                </li>
                                <li>
                                    <a aria-label="HCRA Twitter page" href="https://twitter.com/hcraontario">
                                        <span className={componentStyles.icon}><FaTwitter/></span>
                                    </a>
                                </li>
                                <li>
                                    <a aria-label="HCRA LinkedIn page" href="https://www.linkedin.com/company/home-construction-regulatory-authority-hcra">
                                        <span className={componentStyles.icon}><FaLinkedin/></span>
                                    </a>
                                </li>
                                <li>
                                    <a aria-label="HCRA Youtube page" href="https://www.youtube.com/channel/UCFkWbZv351PeJ9Z_DoeXngw?reload=9">
                                        <span className={componentStyles.icon}><FaYoutube/></span>
                                    </a>
                                </li>
                            </ul>
                        </Col>

                        <button aria-label="Scroll to the top of the page" type="button" className={componentStyles.scrollBtn} onClick={scrollToTop}>
                            <FaAngleUp />
                        </button>
                    </Row>
                </Container>
            </div>


            <nav className={componentStyles.footerBottom}>
                <Container>
                    <Row>
                        <Col>
                            <ul>
                                <li>
                                    <Link to="/terms-of-use">Terms of Use</Link>
                                </li>
                                <li>
                                    <Link to="/glossary">Glossary of Terms</Link>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </nav>

        </footer>
    )
}

export default PageFooter;