import React, {useState, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import Classnames from 'classnames';
import { updateSearch, 
    clearBuilders, 
    clearSearch, 
    getBuilderNames, 
    getCityNames,
    getEnrolCityNames, 
    clearSuggetions } from '../../actions/builderActions';
import { buildQueryString } from '../../utils/queries';

import componentStyles from './SearchForm.module.scss';
import TextInput from '../TextInput/TextInput';
import Button from '../Button/Button';
import { FaPlus, FaMinus } from "react-icons/fa";

const EnrolmentSearchForm = ({
    searchTerms,
    autoCompleteNames,
    autoCompleteCities,
    autoCompleteEnrolCities,

    updateSearch, 
    clearBuilders, 
    clearSearch,
    getBuilderNames,
    getCityNames,
    getEnrolCityNames,
    clearSuggetions, 
    ...props}) => {
        
    let history = useHistory();
    const [advancedOpen, advancedToggle] = useState(false)
    const [submitDisable, setSubmitDisable] = useState(true)
    const [errors, setErrors] = useState({
        enrolAddress: ''
    })

    useEffect(() => {
        if(!searchTerms.enrolAddress || searchTerms.enrolAddress.length<5) setSubmitDisable(true)
        else if(searchTerms.enrolAddress && searchTerms.enrolAddress.length>=5) setSubmitDisable(false)
    }, [searchTerms]);

    const onChange = (e) => {
        const value=e.target.value;
        const key=e.target.name;
        updateSearch({
            ...searchTerms,
            [key]: value
        });
    } 

    // TODO come back to this, set up autcomplete using different view/table?
    const cityAutoComplete = (e) => {
        const value=e.target.value;
        const key=e.target.name;
        updateSearch({
            ...searchTerms,
            [key]: value
        });
    }

    const suggestionClick = (key, newValue) => {
        clearSuggetions();
        updateSearch({
            ...searchTerms,
            [key]: newValue
        });
    }

    const handleValidation = () => {
        let formIsValid = true;
        let currentErrors = {}
        if(searchTerms.enrolAddress.length<5 || !searchTerms.enrolAddress){
            currentErrors.enrolAddress = "Please provide at least 5 characters of your address to help narrow your search."
            formIsValid = false;
        }
        setErrors(currentErrors)
        return formIsValid;
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if(handleValidation()){
            clearBuilders();
            history.push(`/enrolmentsearchresults?${buildQueryString(searchTerms)}`)
        }
    }

    return(
        <form autoComplete="off">
            <section className="fieldset" style={{padding: `1rem 2rem`}}>
                <TextInput 
                    style={{marginBottom: `0`}}
                    id="enrolAddress" 
                    name="enrolAddress"
                    placeholder="ex. 123 Main St."
                    label="Address"
                    instructions= "Please include at least 5 characters to help narrow your search."
                    value={searchTerms.enrolAddress}
                    changeHandler={onChange}
                    invalid={errors.enrolAddress!==''}
                    errorMsg={errors.enrolAddress!=='' ? errors.enrolAddress : null}
                    required/>

                <TextInput 
                    id="enrolCity" 
                    name="enrolCity"
                    placeholder="ex. Timmins"
                    label="City"
                    value={searchTerms.enrolCity}
                    changeHandler={cityAutoComplete}
                    suggestions={autoCompleteEnrolCities}
                    suggestionKey='CITY'
                    suggestionHandler={suggestionClick}/>

                <button 
                    type="button"
                    className={componentStyles.advancedBtn}
                    aria-expanded={advancedOpen}
                    onClick={() => advancedToggle(!advancedOpen)}>
                    { advancedOpen ? <FaMinus/> : <FaPlus /> } 
                    Advanced Search
                </button>

                <section
                    className={advancedOpen ? Classnames(componentStyles.advancedSearchField,componentStyles.active) : componentStyles.advancedSearchField}>
                    
                    <TextInput 
                        id="lot" 
                        name="lot"
                        placeholder="123456"
                        label="Lot #"
                        value={searchTerms.lot}
                        changeHandler={onChange}/>

                    <TextInput 
                        id="plan" 
                        name="plan"
                        placeholder="123456"
                        label="Plan #"
                        value={searchTerms.plan}
                        changeHandler={onChange}/>

                </section>
            </section>


            <button 
                type="button"
                className={componentStyles.advancedBtn}
                onClick={clearSearch}>
                Clear Search
            </button>

            <Button
                id="builderSearchSubmit" 
                type="submit"
                clickEvt={onSubmit} 
                text="Search" 
                disabled={submitDisable}/>

        </form>
    )
}

const mapStateToProps = state => ({
    searchTerms: state.builder.searchTerms,
    autoCompleteNames: state.builder.autoCompleteNames,
    autoCompleteCities: state.builder.autoCompleteCities,
    autoCompleteEnrolCities: state.builder.autoCompleteEnrolCities
})

export default connect(
    mapStateToProps, 
    { updateSearch, 
        clearBuilders, 
        clearSearch, 
        getBuilderNames, 
        getCityNames, 
        getEnrolCityNames,
        clearSuggetions })(EnrolmentSearchForm);