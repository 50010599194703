import React from "react";
import { ReactComponent as HomeLoader } from '../../imgs/homeLoader.svg'
import componentStyles from './Loader.module.scss';

const Loader = () => {
    return (
        <div className={componentStyles.loader}>
            <div className={componentStyles.spinner}>
                <HomeLoader />
            </div>  
        </div>
    );
  }
 
  
export default Loader;