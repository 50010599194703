import React, { useState } from "react";
import { Link } from "react-router-dom";
import componentStyles from "./ToolTip.module.scss";
import { FaQuestionCircle } from "react-icons/fa";

const ToolTip = ({ link, internal, clickEvt, definition, ...props }) => {
  const [isVisible, setVisibility] = useState(false);

  return (
    <span className={componentStyles.toolTip}>
      {link && internal && (
        <Link
          to={link}
          className={componentStyles.text}
          onMouseEnter={() => setVisibility(true)}
          onMouseLeave={() => setVisibility(false)}
        >
          {props.children}
          <span className={componentStyles.icon} aria-hidden="true">
            <FaQuestionCircle />
          </span>
        </Link>
      )}
      {link && !internal && (
        <a
          href={link}
          className={componentStyles.text}
          onMouseEnter={() => setVisibility(true)}
          onMouseLeave={() => setVisibility(false)}
        >
          {props.children}
          <span className={componentStyles.icon} aria-hidden="true">
            <FaQuestionCircle />
          </span>
        </a>
      )}
      {!link && !internal && !clickEvt && (
        <a
          href=""
          className={componentStyles.text}
          onMouseEnter={() => setVisibility(true)}
          onMouseLeave={() => setVisibility(false)}
        >
          {props.children}
          <span className={componentStyles.icon} aria-hidden="true">
            <FaQuestionCircle />
          </span>
        </a>
      )}
      {clickEvt && (
        <button
          className={componentStyles.text}
          onClick={clickEvt}
          onMouseEnter={() => setVisibility(true)}
          onMouseLeave={() => setVisibility(false)}
        >
          {props.children}
          <span className={componentStyles.icon} aria-hidden="true">
            <FaQuestionCircle />
          </span>
        </button>
      )}

      <div
        className={componentStyles.definition}
        aria-hidden={isVisible}
        role="tooltip"
      >
        <p>{definition}</p>
        {link && (
          <span className="footnote">
            Click the link to view our full Glossary.
          </span>
        )}
        {clickEvt && (
          <span className="footnote">
            Click the link to view detailed information.
          </span>
        )}
      </div>
    </span>
  );
};

export default ToolTip;
