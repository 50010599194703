import React from "react";
import { Link } from "react-router-dom";
import {
  determineLicenceClass,
  determineBoolClass,
  licenceBadge,
} from "../../utils/normalizesearchresults";
import { FiExternalLink } from "react-icons/fi";

const BuilderListRow = ({ builderData }) => {
  const { type, id, idtype, name, operatingName, city, licenseState } =
    builderData;

  // const isUmbrella = !licenceNumber || licenceNumber==="NULL";
  // console.log("type", type)
  const isUmbrella = type === "umbrella" ? true : false;
  const isConvict = type === "unlicensed" ? true : false;
  const regClass = isUmbrella ? null : determineLicenceClass(licenseState);
  const licenceBoolClass = isUmbrella ? null : determineBoolClass(licenseState);

  const profileLink = () => {
    if (isUmbrella) return `/umbrella/${id}`;
    else if (isConvict || licenseState === "CNV") {
      if (idtype === "PERSON_ID") return `/unlicensed/P${id}`;
      else return `/unlicensed/${id}`;
    } else return `/profile/${id}`;
  };

  return (
    <tr>
      <td>
        <Link className="title" key={id} to={profileLink}>
          <FiExternalLink
            style={{
              verticalAlign: "center",
              marginRight: ".5rem",
              fontSize: `1.5rem`,
            }}
          />
          {name}
        </Link>
      </td>

      <td className="title">{operatingName ? operatingName : name}</td>

      <td className="sentenceCase">{city}</td>

      {isUmbrella ? (
        <td colSpan="3">
          This is an umbrella company and therefore does not have a distinct
          registration number.
        </td>
      ) : (
        <>
          <td className={licenceBoolClass}>{licenceBadge(licenseState)}</td>

          <td className={regClass}>{licenseState}</td>
        </>
      )}
    </tr>
  );
};

export default BuilderListRow;
