import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import Moment from "react-moment";
import PaginationNav from "../PaginationNav/PaginationNav";
import { getPaginatedResults } from "../../utils/sortFunctions";
import { normalizeAmtStr } from "../../utils/numNormalizeFunctions";

const ConvictionsList = ({ convictionsList, ...props }) => {
  const isMobile = useMediaQuery({ maxWidth: 990 });
  const [currentPage, setPage] = useState(1);

  return (
    <>
      <div className="overflowContainer">
        {!isMobile ? (
          <table className="noWrap">
            <thead>
              <tr>
                <th>Charge Date</th>
                <th>Defendant</th>
                <th>Defendant Location</th>
                <th>Offence Location</th>
                <th>Charge</th>
                <th>Conviction Date</th>
                <th>Conviction Result</th>
                <th>Fine/Surcharge Amount</th>
                <th>Under Appeal</th>
              </tr>
            </thead>

            <tbody>
              {getPaginatedResults(convictionsList, currentPage).map((row) => {
                const rowCharge =
                  row["CONVICTION_DESCRIPTION"] &&
                  row["CONVICTION_DESCRIPTION"] !== ""
                    ? row["CONVICTION_DESCRIPTION"]
                    : row["CHARGE_TYPE"];
                return (
                  <tr>
                    <td>
                      {row["CHARGE_DATE"] ? (
                        <Moment format="MMMM D, YYYY">
                          {row["CHARGE_DATE"]}
                        </Moment>
                      ) : (
                        <span>---</span>
                      )}
                    </td>
                    <td>
                      {row["CHARGE_AGAINST"] ? row["CHARGE_AGAINST"] : "---"}
                    </td>
                    <td>{row["CITY_DEFENCE"] ? row["CITY_DEFENCE"] : "---"}</td>
                    <td>{row["CITY_OFFENCE"] ? row["CITY_OFFENCE"] : "---"}</td>
                    <td>{rowCharge !== "" ? rowCharge : "---"}</td>
                    <td>
                      {row["DISPOSITION_DATE"] ? (
                        <Moment format="MMMM D, YYYY">
                          {row["DISPOSITION_DATE"]}
                        </Moment>
                      ) : (
                        <span>---</span>
                      )}
                    </td>
                    <td>
                      {row["DISPOSITION_TYPE"]
                        ? row["DISPOSITION_TYPE"]
                        : "---"}
                    </td>
                    <td>
                      {row["TOTAL_FINE_AMOUNT"] &&
                      row["TOTAL_FINE_AMOUNT"] !== 0 &&
                      row["TOTAL_FINE_AMOUNT"] !== "0"
                        ? normalizeAmtStr(row["TOTAL_FINE_AMOUNT"], true)
                        : `---`}
                    </td>
                    <td>
                      {row["UNDER_APPEAL"] && row["UNDER_APPEAL"] !== "NULL"
                        ? row["UNDER_APPEAL"]
                        : `---`}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <>
            {getPaginatedResults(convictionsList, currentPage).map((row) => {
              const rowCharge =
                row["CONVICTION_DESCRIPTION"] &&
                row["CONVICTION_DESCRIPTION"] !== ""
                  ? row["CONVICTION_DESCRIPTION"]
                  : row["CHARGE_TYPE"];
              return (
                <div className="tile">
                  <div>
                    <span className="label">Charge Date: </span>
                    <span>
                      {row["CHARGE_DATE"] ? (
                        <Moment format="MMMM D, YYYY">
                          {row["CHARGE_DATE"]}
                        </Moment>
                      ) : (
                        <span>---</span>
                      )}
                    </span>
                  </div>

                  <div>
                    <span className="label">Defendant: </span>
                    <span>
                      {row["CHARGE_AGAINST"] ? row["CHARGE_AGAINST"] : "---"}
                    </span>
                  </div>

                  <div>
                    <span className="label">Defendant Location: </span>
                    <span>
                      {row["CITY_DEFENCE"] ? row["CITY_DEFENCE"] : "---"}
                    </span>
                  </div>

                  <div>
                    <span className="label">Offence Location: </span>
                    <span>
                      {row["CITY_OFFENCE"] ? row["CITY_OFFENCE"] : "---"}
                    </span>
                  </div>

                  <div>
                    <span className="label">Charge: </span>
                    <span>{rowCharge !== "" ? rowCharge : "---"}</span>
                  </div>

                  <div>
                    <span className="label">Conviction Date: </span>
                    <span>
                      {row["DISPOSITION_DATE"] ? (
                        <Moment format="MMMM D, YYYY">
                          {row["DISPOSITION_DATE"]}
                        </Moment>
                      ) : (
                        <span>---</span>
                      )}
                    </span>
                  </div>

                  <div>
                    <span className="label">Conviction Result: </span>
                    <span>
                      {row["DISPOSITION_TYPE"]
                        ? row["DISPOSITION_TYPE"]
                        : "---"}
                    </span>
                  </div>

                  <div>
                    <span className="label">Fine Amount: </span>
                    <span>
                      {row["TOTAL_FINE_AMOUNT"] &&
                      row["TOTAL_FINE_AMOUNT"] !== 0 &&
                      row["TOTAL_FINE_AMOUNT"] !== "0"
                        ? normalizeAmtStr(row["TOTAL_FINE_AMOUNT"], true)
                        : `---`}
                    </span>
                  </div>

                  <div>
                    <span className="label">Under Appeal: </span>
                    <span>
                      {row["UNDER_APPEAL"] && row["UNDER_APPEAL"] !== "NULL"
                        ? row["UNDER_APPEAL"]
                        : `---`}
                    </span>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>

      {convictionsList.length > 10 && (
        <PaginationNav
          currentPage={currentPage}
          clickEvt={setPage}
          resultsLength={convictionsList.length}
        />
      )}
    </>
  );
};

export default ConvictionsList;
