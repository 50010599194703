// buildQueryString
// Params: obj of search parameters
// Returns: query string
export const buildQueryString = (
  paramsObj, 
  page,
  orderBy, 
  orderDir) => {
    
    var queryString = Object.keys(paramsObj)
    .filter(k => paramsObj[k]!==null && paramsObj[k]!=="")
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(paramsObj[k].trim()))
    .join('&')
    .concat(`&page=${page ? page : 1}`)

    if(orderBy) queryString += `&orderBy=${orderBy}`
    if(orderDir) queryString += `&orderDir=${orderDir}`

    return queryString
}

// getQueryParams
// Params: new URLSearchParams(useLocation().search) obj
// Returns: array of query values as array [key, value]
export const getQueryParams = querySearchObj => {
    let queryParams = [];
    const nonQueryParams= ["page", "sort", "orderBy", "orderDir" ]
    const normalizedQueryKeys = {
      builderNum: {
        queryLabel: "Licence Number"
      },
      builderName: {
        queryLabel: "Builder Name"
      },
      builderLocation: {
        queryLabel: "Location/City"
      },
      officerDirector: {
        queryLabel: "Director/Officer Name"
      },
      licenceStatus: {
        queryLabel: "Builder Licence Status",
        trueTxt: "Licensed",
        falseTxt: "Not licensed"
      },
      convictions: {
        queryLabel: "Building Charges and Convictions",
        trueTxt: "Has Charges and/or Convictions",
        falseTxt: "No Charges or Convictions"
      },
      umbrellaCo: {
        queryLabel: "Umbrella Company"
      },
      yearsActive: {
        queryLabel: "Years Active"
      },
      enrolAddress: {
        queryLabel: "Home Address"
      },
      enrolCity: {
        queryLabel: "Home City"
      },
      lot: {
        queryLabel: "Home Lot #"
      },
      plan: {
        queryLabel: "Home Plan #"
      }
    }
    for(let pair of querySearchObj.entries()){
      if(nonQueryParams.indexOf(pair[0]) === -1){
        // get the normalized query label, if exists
        let normalizedKey = 
          normalizedQueryKeys[pair[0]].queryLabel 
          ? 
          normalizedQueryKeys[pair[0]].queryLabel 
          : 
          pair[0];

        let query;
        if((pair[1]==='true' || pair[1]==='false') && normalizedQueryKeys[pair[0]].trueTxt){
          query= pair[1]==='true' ? normalizedQueryKeys[pair[0]].trueTxt : normalizedQueryKeys[pair[0]].falseTxt
        }else{
          query=pair[1]
        }

        queryParams.push([
          normalizedKey,
          query
        ])
      }
    };
    return queryParams;
}