import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Classnames from "classnames";
import {
  updateSearch,
  clearBuilders,
  clearSearch,
  getBuilderNames,
  getCityNames,
  clearSuggetions,
} from "../../actions/builderActions";
import { buildQueryString } from "../../utils/queries";

import componentStyles from "./SearchForm.module.scss";
import TextInput from "../TextInput/TextInput";
import Button from "../Button/Button";
import PillInput from "../PillInput/PillInput";
import { FaPlus, FaMinus } from "react-icons/fa";

const SearchForm = ({
  searchTerms,
  autoCompleteNames,
  autoCompleteCities,

  updateSearch,
  clearBuilders,
  clearSearch,
  getBuilderNames,
  getCityNames,
  clearSuggetions,
  ...props
}) => {
  let history = useHistory();
  const [advancedOpen, advancedToggle] = useState(
    (searchTerms.builderNum && searchTerms.builderNum !== "") ||
      (searchTerms.officerDirector && searchTerms.officerDirector !== "") ||
      (searchTerms.umbrellaCo && searchTerms.umbrellaCo !== "") ||
      (searchTerms.licenceStatus && searchTerms.licenceStatus != "") ||
      (searchTerms.yearsActive && searchTerms.yearsActive != "")
  );

  const onChange = (e) => {
    const value = e.target.value;
    const key = e.target.name;
    updateSearch({
      ...searchTerms,
      [key]: value,
    });
  };

  const nameAutoComplete = (e) => {
    const value = e.target.value;
    const key = e.target.name;
    updateSearch({
      ...searchTerms,
      [key]: value,
    });
    var normalizedVal = value.replace(/&|!|#/gi, () => "");
  };

  const cityAutoComplete = (e) => {
    const value = e.target.value;
    const key = e.target.name;
    updateSearch({
      ...searchTerms,
      [key]: value,
    });
  };

  // const suggestionClick = (key, newValue) => {
  //   clearSuggetions();
  //   updateSearch({
  //     ...searchTerms,
  //     [key]: newValue,
  //   });
  // };

  const onSubmit = (e) => {
    e.preventDefault();
    clearBuilders();
    history.push(`/buildersearchresults?${buildQueryString(searchTerms)}`);
  };

  return (
    <form autoComplete="off">
      <section className="fieldset" style={{ padding: `1rem 2rem` }}>
        <div>
          <TextInput
            id="builderName"
            name="builderName"
            placeholder="ex. Bob the Builder Co."
            label="Builder Name"
            value={searchTerms.builderName}
            changeHandler={nameAutoComplete}
            // suggestions={autoCompleteNames}
            // suggestionKey="VB"
            // suggestionHandler={suggestionClick}
            // suggestionLinkPath="/profile/"
            // suggestionLinkKey="VB_NUM"
          />
        </div>

        <div>
          <TextInput
            id="builderLocation"
            name="builderLocation"
            placeholder="ex. Barrie"
            label="Builder Office Location (City)"
            value={searchTerms.builderLocation}
            changeHandler={cityAutoComplete}
            // suggestions={autoCompleteCities}
            // suggestionKey="CITY"
            // suggestionHandler={suggestionClick}
          />
        </div>

        <button
          type="button"
          className={componentStyles.advancedBtn}
          aria-expanded={advancedOpen}
          onClick={() => advancedToggle(!advancedOpen)}
        >
          {advancedOpen ? <FaMinus /> : <FaPlus />}
          Advanced Search
        </button>

        <section
          className={
            advancedOpen
              ? Classnames(
                  componentStyles.advancedSearchField,
                  componentStyles.active
                )
              : componentStyles.advancedSearchField
          }
        >
          <TextInput
            id="builderNum"
            name="builderNum"
            placeholder="123456"
            label="Builder Licence Number"
            value={searchTerms.builderNum}
            changeHandler={onChange}
          />

          <TextInput
            id="officerDirector"
            name="officerDirector"
            placeholder="ex. John Doe"
            label="Principal/Director/Officer Name"
            value={searchTerms.officerDirector}
            changeHandler={onChange}
          />

          <TextInput
            id="umbrellaCo"
            name="umbrellaCo"
            placeholder="ex. Parent Co."
            label={"Umbrella Company"}
            value={searchTerms.umbrellaCo}
            changeHandler={onChange}
          />

          <label className={componentStyles.pillLabel}>
            Builder Licence Status
          </label>
          <div>
            <PillInput
              name="licenceStatus"
              label="All"
              type="radio"
              value=""
              isSelected={
                !searchTerms.licenceStatus || searchTerms.licenceStatus === ""
                  ? true
                  : false
              }
              changeHandler={onChange}
            />
            <PillInput
              name="licenceStatus"
              label="Licensed"
              type="radio"
              value="true"
              isSelected={searchTerms.licenceStatus === "true" ? true : false}
              changeHandler={onChange}
            />
            <PillInput
              name="licenceStatus"
              label="Not Licensed"
              type="radio"
              value="false"
              isSelected={searchTerms.licenceStatus === "false" ? true : false}
              changeHandler={onChange}
            />
          </div>

          <label className={componentStyles.pillLabel}>Years Active</label>
          <div>
            <PillInput
              name="yearsActive"
              label="All"
              type="radio"
              value=""
              isSelected={
                !searchTerms.yearsActive || searchTerms.yearsActive === ""
                  ? true
                  : false
              }
              changeHandler={onChange}
            />
            <PillInput
              name="yearsActive"
              label="Last 5 Years"
              type="radio"
              value="5"
              isSelected={searchTerms.yearsActive === "5" ? true : false}
              changeHandler={onChange}
            />
            <PillInput
              name="yearsActive"
              label="Last 10 Years"
              type="radio"
              value="10"
              isSelected={searchTerms.yearsActive === "10" ? true : false}
              changeHandler={onChange}
            />
          </div>
        </section>
      </section>

      <button
        type="button"
        className={componentStyles.advancedBtn}
        onClick={clearSearch}
      >
        Clear Search
      </button>

      <Button
        id="builderSearchSubmit"
        type="submit"
        clickEvt={onSubmit}
        text="Search"
      />
    </form>
  );
};

const mapStateToProps = (state) => ({
  searchTerms: state.builder.searchTerms,
  autoCompleteNames: state.builder.autoCompleteNames,
  autoCompleteCities: state.builder.autoCompleteCities,
});

export default connect(mapStateToProps, {
  updateSearch,
  clearBuilders,
  clearSearch,
  getBuilderNames,
  getCityNames,
  clearSuggetions,
})(SearchForm);
