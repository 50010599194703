import React from "react";
import PageFooter from "../components/PageFooter/PageFooter";
import Nav from "../components/Nav/Nav";
import ProfileHeader from "../components/ProfileHeader/ProfileHeader";
import Loader from "../components/Loader/Loader";
import { Container, Row, Col } from "react-grid-system";

const ProfileLayout = ({
  loading,
  builderName,
  operatingName,
  umbrella,
  umbrellaId,
  licenceNumber,
  expiryDate,
  licencestatus,
  lastUpdate,
  initiallyLicensed,
  activeRenewal,
  removeLicenceDates,
  ...props
}) => {
  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <Nav />
          <ProfileHeader
            builderName={builderName}
            operatingName={operatingName}
            umbrella={umbrella}
            umbrellaId={umbrellaId}
            licenceNumber={licenceNumber}
            expiryDate={expiryDate}
            licencestatus={licencestatus}
            lastUpdate={lastUpdate}
            initiallyLicensed={initiallyLicensed}
            activeRenewal={activeRenewal}
            removeLicenceDates={removeLicenceDates}
          />
          <Container>
            <Row nogutter>
              <Col>
                <main>{props.children}</main>
              </Col>
            </Row>
          </Container>
          <PageFooter />
        </>
      )}
    </>
  );
};

export default ProfileLayout;
