// Orders Objs
// used to add orders issued by HCRA to VB profiles, and override licence status
// Profile page:
// - used to add alerts re: notices to overview tab
// - used to populate orders table under notices/orders tab

// Sample Data
// key value - corresponds to the VB's licence number
// title: name of the document, used to populate the link text
// date: date doc was issues
// doc: name of doc, used to populate link path, points to src > docs
// inCompliance: used for compliance orders, will add a note to the profile that the VB has come into compliance
// override: bool, used to override the VB's licence status on all applicable pages, depenedent on the type of order issued
// appeal period: bool, used to override the VB's licence status on all applicable pages, depenedent on the type of order issued

// notice of proposal to make a compliance order
export const complianceOrders = {
  B47446: [
    {
      title: "Notice of Proposal to Make a Compliance Order",
      date: "December 23, 2021",
      doc: "Notice of Proposal to Issue a Compliance Order - Luxstone Homes Inc - December 2021.pdf",
      inCompliance: true,
    },
  ],
  B43218: [
    {
      title: "Notice of Proposal to Make a Compliance Order",
      date: "March 23, 2022",
      doc: "Notice of Proposal to Issue a Compliance Order - Townsgate Homes Inc - 2022-03-23.pdf",
      inCompliance: true,
    },
  ],
  B47290: [
    {
      title: "Notice of Proposal to Make a Compliance Order",
      date: "March 8, 2023",
      doc: "Anix Developments Inc. - Compliance Order - March 8, 2023.pdf",
    },
  ],

  // TODO return to this data structure
  // Adding this now to allow for multiple orders of the same type
  B46762: {
    "04-20-2023": [
      {
        title: "Compliance Order",
        date: "May 5, 2023",
        doc: "HCRA Compliance Order - Pinetree Developments Inc. - May 5, 2023.pdf",
      },
      {
        title: "Notice of Proposal to Make a Compliance Order",
        date: "April 20, 2023",
        doc: "HCRA Notice of Proposal to Make a Compliance Order - Pinetree Developments Inc. - April 20, 2023.pdf",
        fullOrder: true,
      },
    ],
    "03-13-2023": [
      {
        title: "Immediate Compliance Order",
        date: "March 14, 2023",
        doc: "Pinetree Developments Inc. - Immediate Compliance Order - March 14, 2023.pdf",
        immediate: true,
      },
    ],
  },

  B12800: [
    {
      title: "Notice of Proposal to Make a Compliance Order",
      date: "March 10, 2023",
      doc: "Riverview Homes (Niagara) Ltd. - Compliance Order - March 10 2023.pdf",
      fullOrder: true,
    },
    {
      title: "Compliance Order",
      date: "April 3, 2023",
      doc: "Riverview Homes - Compliance Order - April 3, 2023.pdf",
    },
  ],
  B47130: [
    {
      title: "Notice of Proposal to Make a Compliance Order",
      date: "March 27, 2023",
      doc: "HCRA Notice of Proposal re Compliance Order - Midland Homes & Development - March 27, 2023.pdf",
      fullOrder: true,
      inCompliance: true,
    },
    {
      title: "Compliance Order",
      date: "April 12, 2023",
      doc: "HCRA Compliance Order - Midland Homes and Development - April 12, 2023_2.pdf",
    },
  ],
  B36401: [
    {
      title: "Notice of Proposal to Make a Compliance Order",
      date: "April 5, 2024",
      doc: "HCRA Notice of Proposal re Compliance Order - 2169289 Ontario Inc. - April 5, 2024.pdf",
      fullOrder: true,
    },
    {
      title: "Compliance Order",
      date: "April 22, 2024",
      doc: "2169289 Ontario Inc. - Compliance Order - April 22, 2024.pdf",
    },
  ],
  B60522: [
    {
      title: "Notice of Proposal to Make a Compliance Order",
      date: "March 6, 2024",
      doc: "HCRA Notice of Proposal re Compliance Order - Lynphyl Homes Ltd. - March 6, 2024.pdf",
      fullOrder: true,
    },
    {
      title: "Compliance Order",
      date: "March 21, 2024",
      doc: "Compliance Order - Lynphyl Homes Ltd. - March 21, 2024.pdf",
    },
    {
      title: "Freeze Order",
      date: "June 19, 2024",
      doc: "Scotiabank HCRA Freeze Orders - Lynphyl Homes et al. - June 18 2024.pdf",
    },
    {
      title: "Freeze Order",
      date: "June 20, 2024",
      doc: "HCRA Freeze Orders - Lynphyl Homes Ltd. - June 18, 2024.pdf",
    },
  ],
  // B60522: {
  //   "03-06-2024": [
  //     {
  //       title: "Notice of Proposal to Make a Compliance Order",
  //       date: "March 6, 2024",
  //       doc: "HCRA Notice of Proposal re Compliance Order - Lynphyl Homes Ltd. - March 6, 2024.pdf",
  //       fullOrder: true,
  //     },
  //     {
  //       title: "Compliance Order",
  //       date: "March 21, 2024",
  //       doc: "Compliance Order - Lynphyl Homes Ltd. - March 21, 2024.pdf",
  //     },
  //   ],
  //   "06-19-2024": [
  //     {
  //       title: "Freeze Order",
  //       date: "June 20, 2024",
  //       doc: "HCRA Freeze Orders - Lynphyl Homes Ltd. - June 18, 2024.pdf",
  //       fullOrder: true,
  //     },
  //     {
  //       title: "Asset Freeze Order",
  //       date: "June 19, 2024",
  //       doc: "Scotiabank HCRA Freeze Orders - Lynphyl Homes et al. - June 18 2024.pdf",
  //     },
  //   ],
  // },
  B43317: [
    {
      title: "Notice of Proposal to Make a Compliance Order",
      date: "April 11, 2024",
      doc: "Dream Maker Developments Inc.  - Notice of Proposal for Compliance Order - April 11, 2024.pdf",
      inCompliance: true,
    },
  ],
  B44861: [
    {
      title: "Notice of Proposal to Make a Compliance Order",
      date: "April 11, 2024",
      doc: "9315055 Canada Corp.  - Notice of Proposal for Compliance Order - April 11, 2024.pdf",
      inCompliance: true,
    },
  ],
  B47075: [
    {
      title: "Notice of Proposal to Make a Compliance Order",
      date: "April 12, 2024",
      doc: "Modern Day Builders Inc. -Notice of Proposal to Issue Compliance Order - April 12, 2024.pdf",
      fullOrder: true,
    },
    {
      title: "Compliance Order",
      date: "April 29, 2024",
      doc: "Modern Day Builders Inc. - Compliance Order - April 29 2024.pdf",
    },
  ],
  B37007: [
    {
      title: "Notice of Proposal to Make a Compliance Order",
      date: "July 19, 2024",
      doc: "HCRA Notice of Proposal re Compliance Order - Roca Developments Inc. - July 18, 2024.pdf",
    },
  ],
};

export const determineComplianceStatus = (id) => {
  if (complianceOrders[id]?.override || complianceOrders[id][0].override)
    return "Licensed – NOP Under Appeal";
  else return null;
};

export const determineComplianceHdrTxt = (id, orderKey) => {
  if (orderKey && complianceOrders[id][orderKey]) {
    if (complianceOrders[id][orderKey][0].immediate) {
      return "Immediate Compliance Order Issued";
    } else if (complianceOrders[id][orderKey][0].override) {
      return "Notice of Proposal to Make a Compliance Order Issued - Under Appeal";
    } else if (complianceOrders[id][orderKey][0]?.fullOrder) {
      return "Compliance Order Issued";
    } else {
      return "Notice of Proposal to Make a Compliance Order Issued";
    }
  } else if (complianceOrders[id][0]?.immediate)
    return "Immediate Compliance Order Issued";
  else if (complianceOrders[id]?.override || complianceOrders[id][0]?.override)
    return "Notice of Proposal to Make a Compliance Order Issued - Under Appeal";
  else if (complianceOrders[id][0]?.fullOrder) {
    return "Compliance Order Issued";
  } else return "Notice of Proposal to Make a Compliance Order Issued";
};

// notice of conditions on licence
export const conditionsOrders = {
  B40597: [
    {
      title: "Notice of Proposal to Apply Conditions (PDF)",
      date: "September 9, 2021",
      doc: "Notice of Proposal to Refuse Refuse Renewal and Apply Conditions.pdf",
      // override: true,
    },
  ],
  B47295: [
    {
      title: "Notice of Proposal to Apply Conditions (PDF)",
      date: "August 25, 2022",
      doc: "AMENDMENTS - Notice of Proposal to Revoke and Apply Conditions v3.pdf",
    },
    {
      title: "Fresh as Amended Notice of Proposal to Apply Conditions (PDF)",
      date: "September 27, 2022",
      doc: "DISCLAIMER - Fresh as Amended Notice of Proposal to Revoke and Apply Conditions.pdf",
    },
    {
      title: "LAT Order (PDF)",
      date: "November 28, 2022",
      doc: "AdiLAT.pdf",
    },
  ],
  B44865: [
    {
      title: "Notice of Proposal to Apply Conditions (PDF)",
      date: "August 25, 2022",
      doc: "AMENDMENTS - Notice of Proposal to Revoke and Apply Conditions v3.pdf",
    },
    {
      title: "Fresh as Amended Notice of Proposal to Apply Conditions (PDF)",
      date: "September 27, 2022",
      doc: "DISCLAIMER - Fresh as Amended Notice of Proposal to Revoke and Apply Conditions.pdf",
    },
    {
      title: "LAT Order (PDF)",
      date: "November 28, 2022",
      doc: "AdiLAT.pdf",
    },
  ],
  B60173: [
    {
      title: "Notice of Proposal to Apply Conditions (PDF)",
      date: "August 25, 2022",
      doc: "AMENDMENTS - Notice of Proposal to Revoke and Apply Conditions v3.pdf",
    },
    {
      title: "Fresh as Amended Notice of Proposal to Apply Conditions (PDF)",
      date: "September 27, 2022",
      doc: "DISCLAIMER - Fresh as Amended Notice of Proposal to Revoke and Apply Conditions.pdf",
    },
    {
      title: "LAT Order (PDF)",
      date: "November 28, 2022",
      doc: "AdiLAT.pdf",
    },
  ],
  B61269: [
    {
      title: "Notice of Proposal to Revoke a Licence (PDF)",
      date: "August 25, 2022",
      doc: "AMENDMENTS - Notice of Proposal to Revoke and Apply Conditions v3.pdf",
    },
    {
      title: "Fresh as Amended Notice of Proposal to Apply Conditions (PDF)",
      date: "September 27, 2022",
      doc: "DISCLAIMER - Fresh as Amended Notice of Proposal to Revoke and Apply Conditions.pdf",
    },
    {
      title: "LAT Order (PDF)",
      date: "November 28, 2022",
      doc: "AdiLAT.pdf",
    },
  ],
  B47966: [
    {
      title: "Notice of Proposal to Revoke a Licence (PDF)",
      date: "August 25, 2022",
      doc: "AMENDMENTS - Notice of Proposal to Revoke and Apply Conditions v3.pdf",
    },
    {
      title: "Fresh as Amended Notice of Proposal to Apply Conditions (PDF)",
      date: "September 27, 2022",
      doc: "DISCLAIMER - Fresh as Amended Notice of Proposal to Revoke and Apply Conditions.pdf",
    },
    {
      title: "LAT Order (PDF)",
      date: "November 28, 2022",
      doc: "AdiLAT.pdf",
    },
  ],
  B61303: [
    {
      title: "Notice of Proposal to Revoke a Licence (PDF)",
      date: "August 25, 2022",
      doc: "AMENDMENTS - Notice of Proposal to Revoke and Apply Conditions v3.pdf",
    },
    {
      title: "Fresh as Amended Notice of Proposal to Apply Conditions (PDF)",
      date: "September 27, 2022",
      doc: "DISCLAIMER - Fresh as Amended Notice of Proposal to Revoke and Apply Conditions.pdf",
    },
    {
      title: "LAT Order (PDF)",
      date: "November 28, 2022",
      doc: "AdiLAT.pdf",
    },
  ],
};

export const determineConditionsStatus = (id) => {
  if (conditionsOrders[id][0].override && conditionsOrders[id][0].appealPeriod)
    return "Licensed - NOP to Apply Conditions";
  else if (conditionsOrders[id][0].override)
    return "Licensed – NOP Under Appeal";
  else return null;
};

export const determineConditionsHdrTxt = (id) => {
  if (conditionsOrders[id][0].override && conditionsOrders[id][0].appealPeriod)
    return "Proposed Conditions on Licence";
  else if (conditionsOrders[id][0].override)
    return "Proposed Conditions on Licence - Under Appeal";
  else return "Conditions on Licence";
};

// notice to refuse
export const refuseOrders = {
  B45892: [
    {
      title: "Notice of Proposal to Refuse to Renew (PDF)",
      date: "June 11, 2020",
      doc: "B45892NoticeofProposal.pdf",
    },
    {
      title: "Amended Notice of Proposal to Refuse to Renew (PDF)",
      date: "August 17, 2020",
      doc: "B45892NoticeofProposal-Amended.pdf",
    },
    {
      title: "LAT Decision (PDF)",
      date: "May 31, 2022",
      doc: "B45892_LAT.pdf",
    },
  ],
  B47109: [
    {
      title: "Notice of Proposal to Refuse to Renew (PDF)",
      date: "March 15, 2022",
      doc: "Albion Building Consultant Inc. Notice of Proposal.pdf",
      appealed: true,
    },
    {
      title: "LAT Decision",
      date: "January 27, 2023",
      doc: "13954.NHCLA.DEC.pdf",
    },
    {
      title: "Freeze Order",
      date: "February 26, 2024",
      doc: "Albion Building Consultant Inc - Freeze Order - February 26, 2024_1.pdf",
    },
    {
      title: "Freeze Order",
      date: "June 27, 2024",
      doc: "TD Canada Trust HCRA Freeze Order - Albion Building Consultant Inc. - June 27 2024.pdf",
    },
  ],
  B46743: [
    {
      title: "Notice of Proposal to Refuse to Renew (PDF)",
      date: "January 28, 2022",
      doc: "Notice of Proposal to Refuse to Renew Yarco Developments - January 2022.pdf",
      override: true,
    },
    {
      title: "LAT Decision",
      date: "September 9, 2022",
      doc: "Yarco LAT Decision.pdf",
    },
    {
      title: "Reconsideration Decision",
      date: "December 14, 2022",
      doc: "18 - 13864.NHCLA Reconsideration Decision.pdf",
    },
    {
      title: "Divisional Court Decision",
      date: "January 8, 2024",
      doc: "Yarco Divisional Court Decision.pdf",
    },
  ],
  B35901: [
    {
      title: "Notice of Proposal to Refuse (PDF)",
      date: "October 1, 2020",
      doc: "B35901-Notice of Proposal to Refuse.pdf",
    },
    {
      title: "LAT Decision (PDF)",
      date: "May 20, 2021",
      doc: "B35901-LAT Decision .pdf",
    },
  ],
  B44902: [
    {
      title: "Notice of Proposal to Refuse to Renew (PDF)",
      date: "September 9, 2021",
      doc: "Notice of Proposal to Refuse Refuse Renewal and Apply Conditions.pdf",
      // override: true,
    },
  ],
  B45414: [
    {
      title: "Notice of Proposal to Refuse to Renew (PDF)",
      date: "September 9, 2021",
      doc: "Notice of Proposal to Refuse Refuse Renewal and Apply Conditions.pdf",
      // override: true,
    },
  ],
  B37545: [
    {
      title: "Notice of Proposal to Refuse to Renew (PDF)",
      date: "July 26, 2023",
      doc: "B37545 Notice of Proposal to Refuse to Renew.pdf",
      // override: true,
    },
  ],
  B62022: [
    {
      title: "Notice of Proposal to Refuse a Licence (PDF)",
      date: "December 11, 2023",
      doc: "B62022 - Notice of Proposal to Refuse an Application.pdf",
      removeLicenceDates: true,
    },
  ],
  B30875: [
    {
      title: "Notice of Proposal to Refuse to Renew (PDF)",
      date: "February 12, 2024",
      doc: "Hira - Notice of Proposal to Refuse to Renew Application Final.pdf",
    },
  ],
};

export const determineRefuseStatus = (id) => {
  if (refuseOrders[id][0].override && refuseOrders[id][0].appealPeriod)
    return "Licensed - NOP to Refuse a Licence";
  else if (refuseOrders[id][0].override) return "Licensed – NOP Under Appeal";
  else return "Refused";
};

export const determineRefusedHdrTxt = (id) => {
  if (refuseOrders[id][0].override && refuseOrders[id][0].appealPeriod)
    return "Proposed Licence Refusal";
  else if (refuseOrders[id][0].override)
    return "Proposed Licence Refusal – Under Appeal";
  else return "Licence Refused";
};

// notice to revoke
export const revokeOrders = {
  B35744: [
    {
      title: "Notice of Proposal to Revoke (PDF)",
      date: "September 26, 2022",
      doc: "Marcel Dewit Notice of Proposal to Revoke a Licence_1.pdf",
    },
  ],
  B41493: [
    {
      title: "Notice of Proposal to Revoke (PDF)",
      date: "September 23, 2020",
      doc: "41493 - 7853742 Canada Inc - NOP.pdf",
    },
    {
      title: "LAT Decision (PDF)",
      date: "February 18, 2021",
      doc: "08 - 13122.NHCLA.MOT.DEC.ORD.pdf",
    },
  ],
  B45614: [
    {
      title: "Notice of Proposal to Revoke a Licence (PDF)",
      date: "August 25, 2022",
      doc: "AMENDMENTS - Notice of Proposal to Revoke and Apply Conditions v3.pdf",
    },
    {
      title: "Fresh as Amended Notice of Proposal to Apply Conditions (PDF)",
      date: "September 27, 2022",
      doc: "DISCLAIMER - Fresh as Amended Notice of Proposal to Revoke and Apply Conditions.pdf",
    },
    {
      title: "LAT Order (PDF)",
      date: "November 28, 2022",
      doc: "AdiLAT.pdf",
    },
  ],
  B44938: [
    {
      title: "Notice of Proposal to Revoke a Licence (PDF)",
      date: "August 25, 2022",
      doc: "AMENDMENTS - Notice of Proposal to Revoke and Apply Conditions v3.pdf",
    },
    {
      title: "Fresh as Amended Notice of Proposal to Apply Conditions (PDF)",
      date: "September 27, 2022",
      doc: "DISCLAIMER - Fresh as Amended Notice of Proposal to Revoke and Apply Conditions.pdf",
    },
    {
      title: "LAT Order (PDF)",
      date: "November 28, 2022",
      doc: "AdiLAT.pdf",
    },
  ],
  B46333: [
    {
      title: "Notice of Proposal to Revoke a Licence (PDF)",
      date: "August 25, 2022",
      doc: "AMENDMENTS - Notice of Proposal to Revoke and Apply Conditions v3.pdf",
    },
    {
      title: "Fresh as Amended Notice of Proposal to Apply Conditions (PDF)",
      date: "September 27, 2022",
      doc: "DISCLAIMER - Fresh as Amended Notice of Proposal to Revoke and Apply Conditions.pdf",
    },
    {
      title: "LAT Order (PDF)",
      date: "November 28, 2022",
      doc: "AdiLAT.pdf",
    },
  ],
  B46762: [
    {
      title: "Notice of Proposal to Revoke a Licence (PDF)",
      date: "May 9, 2023",
      doc: "Pinetree - Notice of Proposal to Revoke a Licence.pdf",
    },
  ],
  B43256: [
    {
      title: "Notice of Proposal to Revoke a Licence (PDF)",
      date: "May 30, 2024",
      doc: "Notice of Proposal to Revoke a Licence - SHDI.pdf",
      override: true,
      appealPeriod: true,
    },
  ],
  B43313: [
    {
      title: "Notice of Proposal to Revoke a Licence (PDF)",
      date: "July 31, 2024",
      doc: "2024.07.31 - Notice of Proposal to Revoke- Mariman Homes.pdf",
    },
  ],
};

export const determineRevokeStatus = (id) => {
  if (revokeOrders[id][0].override && revokeOrders[id][0].appealPeriod)
    return "Licensed - NOP to Revoke a Licence";
  else if (revokeOrders[id][0].override) return "Licensed – NOP Under Appeal";
  else return "Revoked";
};

export const determineRevokedHdrTxt = (id) => {
  if (revokeOrders[id][0].override && revokeOrders[id][0].appealPeriod)
    return "Proposed Licence Revocation";
  else if (revokeOrders[id][0].override)
    return "Proposed Licence Revocation – Under Appeal";
  else return "Licence Revoked";
};

// advertising orders
export const advertisingOrders = {};

export const determineAdvertisingStatus = (id, builderStatus) => {
  if (advertisingOrders[id][0].override) return "Licensed – Order Under Appeal";
  else return builderStatus;
};

export const determineAdvertisingHdrTxt = (id) => {
  if (advertisingOrders[id][0].override || advertisingOrders[id][0].underAppeal)
    return "Advertising Order – Under Appeal";
  else return "Advertising Order Issued";
};

// suspension orders
export const suspensionOrders = {
  B44380: [
    {
      title: "Notice of Proposal to Revoke (PDF)",
      date: "February 16, 2023",
      doc: "Violette NOP to Revoke_Redacted.pdf",
      revoked: true,
    },
    {
      title: "Immediate Suspension Order (PDF)",
      date: "February 16, 2023",
      doc: "Violette Immediate Suspension Order_Redacted.pdf",
    },
    {
      title: "Asset Freeze Order (1) (PDF)",
      date: "February 16, 2023",
      doc: "Patrick Violette HCRA Freeze Order - February 16, 2023_Redacted.pdf",
    },
    {
      title: "Asset Freeze Order (2) (PDF)",
      date: "February 16, 2023",
      doc: "32836 TD Bank HCRA Freeze Order - Patrick Violette - February 16, 2023.pdf",
    },
    {
      title: "Asset Freeze Order (3) (PDF)",
      date: "February 16, 2023",
      doc: "33156 TD Bank HCRA Freeze Order - Patrick Violette - February 16, 2023.pdf",
    },
    {
      title: "Immediate Compliance Order (PDF)",
      date: "February 16, 2023",
      doc: "Patrick Violette HCRA Immediate Compliance Order - February 16, 2023_Redacted.pdf",
    },
  ],
  B48960: [
    {
      title: "Notice of Proposal to Revoke (PDF)",
      date: "February 16, 2023",
      doc: "Viloham NOP to Revoke_Redacted.pdf",
      revoked: true,
    },
    {
      title: "Immediate Suspension Order (PDF)",
      date: "February 16, 2023",
      doc: "Viloham Immediate Suspension Order_Redacted.pdf",
    },
    {
      title: "Asset Freeze Order (1) (PDF)",
      date: "February 16, 2023",
      doc: "Viloham Trades Inc. (Highbridge Construction) HCRA Freeze Orders - February 16, 2023_Redacted.pdf",
    },
    {
      title: "Asset Freeze Order (2) (PDF)",
      date: "February 16, 2023",
      doc: "01006 - CIBC HCRA Freeze Order - Viloham Trades Inc. (Highbridge Construction) - February 16, 2023.pdf",
    },
    {
      title: "Immediate Compliance Order (PDF)",
      date: "February 16, 2023",
      doc: "Viloham Trades Inc. (Highbridge Construction) HCRA Immediate Compliance Order - February 16, 2023_Redacted.pdf",
    },
  ],
  B60578: [
    {
      title: "Notice of Proposal to Suspend a Licence (PDF)",
      date: "June 19, 2023",
      doc: "Stateview - NOP to Suspend.pdf",
      expired: true,
    },
  ],
  B48820: [
    {
      title: "Notice of Proposal to Suspend a Licence (PDF)",
      date: "June 19, 2023",
      doc: "Stateview - NOP to Suspend.pdf",
      expired: true,
    },
  ],
  B47480: [
    {
      title: "Notice of Proposal to Suspend a Licence (PDF)",
      date: "June 19, 2023",
      doc: "Stateview - NOP to Suspend.pdf",
      expired: true,
    },
  ],
  B60186: [
    {
      title: "Notice of Proposal to Suspend a Licence (PDF)",
      date: "June 19, 2023",
      doc: "Stateview - NOP to Suspend.pdf",
      expired: true,
    },
  ],
  B61469: [
    {
      title: "Notice of Proposal to Suspend a Licence (PDF)",
      date: "June 19, 2023",
      doc: "Stateview - NOP to Suspend.pdf",
      expired: true,
    },
  ],
  B61632: [
    {
      title: "Notice of Proposal to Suspend a Licence (PDF)",
      date: "June 19, 2023",
      doc: "Stateview - NOP to Suspend.pdf",
      expired: true,
    },
  ],
  B61656: [
    {
      title: "Notice of Proposal to Suspend a Licence (PDF)",
      date: "June 19, 2023",
      doc: "Stateview - NOP to Suspend.pdf",
      expired: true,
    },
  ],
  B61468: [
    {
      title: "Notice of Proposal to Suspend a Licence (PDF)",
      date: "June 19, 2023",
      doc: "Stateview - NOP to Suspend.pdf",
      expired: true,
    },
  ],
  B61334: [
    {
      title: "Notice of Proposal to Suspend a Licence (PDF)",
      date: "June 19, 2023",
      doc: "Stateview - NOP to Suspend.pdf",
      expired: true,
    },
  ],
  B41428: [
    {
      title: "Notice of Proposal to Suspend a Licence (PDF)",
      date: "June 19, 2023",
      doc: "Stateview - NOP to Suspend.pdf",
      expired: true,
    },
  ],
  B60363: [
    {
      title: "Notice of Proposal to Suspend a Licence (PDF)",
      date: "June 19, 2023",
      doc: "Stateview - NOP to Suspend.pdf",
      expired: true,
    },
  ],
  B48110: [
    {
      title: "Notice of Proposal to Suspend a Licence (PDF)",
      date: "June 19, 2023",
      doc: "Stateview - NOP to Suspend.pdf",
      expired: true,
    },
  ],
  B47823: [
    {
      title: "Notice of Proposal to Suspend a Licence (PDF)",
      date: "June 19, 2023",
      doc: "Stateview - NOP to Suspend.pdf",
      expired: true,
    },
  ],
  B47192: [
    {
      title: "Notice of Proposal to Suspend a Licence (PDF)",
      date: "June 19, 2023",
      doc: "Stateview - NOP to Suspend.pdf",
      expired: true,
    },
  ],
  B43313: [
    {
      title: "Immediate Suspension Order  (PDF)",
      date: "December 5, 2023",
      doc: "B43313 - Notice of Proposal to Suspend a Licence.pdf",
      hideBanner: true,
    },
  ],
};

export const determineSuspendedHdrTxt = (id) => {
  if (suspensionOrders[id][0].override && suspensionOrders[id][0].appealPeriod)
    return "Proposed Licence Suspension";
  else if (suspensionOrders[id][0].expired)
    return "Licence Expired during Suspension";
  else if (suspensionOrders[id][0].revoked) return "Licence Revoked";
  else return "Licence Suspended";
};

export const determineSuspensionStatus = (id, builderStatus) => {
  if (suspensionOrders[id][0].override && suspensionOrders[id][0].appealPeriod)
    return "Proposed Licence Suspension";
  else if (suspensionOrders[id][0].override) return "Suspended";
  else return builderStatus;
};

// suspension orders
export const adminPenalties = {
  B44865: [
    {
      title: "Adminstrative Penalty (PDF)",
      date: "March 1, 2023",
      doc: "B44865 Administrative Penalty Order.pdf",
    },
  ],
  B63019: [
    {
      title: "Adminstrative Penalty (PDF)",
      date: "March 13, 2024",
      doc: "Administrative Penalty Order - Butt.pdf",
      appealed: true,
    },
  ],
  B63018: [
    {
      title: "Adminstrative Penalty (PDF)",
      date: "March 13, 2024",
      doc: "Administrative Penalty Order - Ali.pdf",
      appealed: true,
    },
  ],
  B48213: [
    {
      title: "Adminstrative Penalty (PDF)",
      date: "June 4, 2024",
      doc: "ISP-0059 - Administrative Penalty Order - GC King Bond - Amended.pdf",
      appealed: true,
    },
  ],
  B63436: [
    {
      title: "Adminstrative Penalty (PDF)",
      date: "July 11, 2024",
      doc: "INV-0287 - Administrative Penalty Order - Harjot Khehra.pdf",
    },
  ],
  B62335: [
    {
      title: "Adminstrative Penalty (PDF)",
      date: "July 11, 2024",
      doc: "INV-0224 - Administrative Penalty Order - Samalie Nsubuga.pdf",
      appealed: true,
    },
  ],
  B62334: [
    {
      title: "Adminstrative Penalty (PDF)",
      date: "July 11, 2024",
      doc: "INV-0224- Administrative Penalty Order - Joy Nneji.pdf",
      appealed: true,
    },
  ],
  B63437: [
    {
      title: "Adminstrative Penalty (PDF)",
      date: "July 11, 2024",
      doc: "INV-0287 - Administrative Penalty Order - Jasjit Sandhu.pdf",
    },
  ],
  B43313: [
    {
      title: "Adminstrative Penalty (PDF)",
      date: "July 31, 2024",
      doc: "2024.07.31 - Administrative Penalty Order- Mariman Homes.pdf",
    },
  ],
};

export const determineAdminPenaltiesHdrTxt = (id) => {
  if (adminPenalties[id][0].appealed)
    return "Administrative Penalty - Under Appeal";
  else return "Administrative Penalty";
};

// determineStatusOverride
// props: builder ID number, builder licence status
export const determineStatusOverride = (builderID, builderStatus) => {
  if (builderStatus === "Charged and/or Convicted") {
    return builderStatus;
  } else if (refuseOrders[builderID] && refuseOrders[builderID][0].override) {
    return determineRefuseStatus(builderID);
  } else if (revokeOrders?.[builderID]?.[0].override) {
    return determineRevokeStatus(builderID);
  } else if (
    complianceOrders?.[builderID]?.override ||
    complianceOrders?.[builderID]?.[0]?.override
  ) {
    return determineComplianceStatus(builderID);
  } else if (
    conditionsOrders[builderID] &&
    conditionsOrders[builderID][0].override
  ) {
    return determineConditionsStatus(builderID);
  } else if (
    advertisingOrders[builderID] &&
    advertisingOrders[builderID][0].override
  ) {
    return "Licensed – NOP Under Appeal";
  } else if (suspensionOrders[builderID]) {
    return determineSuspensionStatus(builderID, builderStatus);
  } else if (advertisingOrders[builderID]) {
    return determineAdvertisingStatus(builderID, builderStatus);
  } else return builderStatus;
};

// disciplineAllegations
export const disciplineAllegations = {
  B46303: [
    {
      referralDate: "November 10, 2023",
      licensee: "Mapleview Developments Ltd.",
      doc: "Mapleview Developments Ltd - Statement of Allegations Summary - 042024.pdf",
      nextSteps:
        "Proceedings stayed due to Receivership Order dated March 21, 2024",
    },
  ],
  B46112: [
    {
      referralDate: "January 30, 2024",
      licensee: "LJM Developments (Stoney Creek) Inc.",
      doc: "LJM_Developments_Stoney_Creek-Statement-of-Allegations-Summary.pdf",
      nextSteps: "Hearing: October 2024",
    },
  ],
  B48253: [
    {
      referralDate: "April 11, 2024",
      licensee: "Briarwood (Angus) Ltd.",
      doc: "Briarwood Statement of Allegations Summary.pdf",
      nextSteps: "Licensee Motion: November 2024",
    },
  ],
  B43292: [
    {
      referralDate: "April 11, 2024",
      licensee: "Briarwood Estates (Sutton) Ltd.",
      doc: "Briarwood Statement of Allegations Summary.pdf",
      nextSteps: "Licensee Motion: November 2024",
    },
  ],
  B47464: [
    {
      referralDate: "April 11, 2024",
      licensee: "863195 Ontario Limited",
      doc: "Briarwood Statement of Allegations Summary.pdf",
      nextSteps: "Licensee Motion: November 2024",
    },
  ],
  B45312: [
    {
      referralDate: "April 11, 2024",
      licensee: "2401886 Ontario Limited",
      doc: "Briarwood Statement of Allegations Summary.pdf",
      nextSteps: "Licensee Motion: November 2024",
    },
  ],
};

// disciplineDecisions
export const disciplineDecisions = {
  B28375: [
    {
      decisionDate: "November 20, 2022",
      licensee: "1332741 Ontario Inc./Patten Homes 2000",
      decisionTxt:
        "NHCLA - Code of Ethics - s. 2 Licencee to ensure compliance; s.9 Intimidation coercion etc.; and s. 16 Obstruction of complaints",
      outcomesTxt:
        "1332741 Ontario Inc. o/a Patten Homes 2000 is ordered to pay a fine of $10,000 and to arrange for its employees to complete the following education courses: Customer Service and Tarion Requirements, Legal Issues in Housing",
      doc: "Decision and Order - Patten Homes.pdf",
    },
  ],
};

export const regActionCalc = (id, status) => {
  const regActionArr = [];
  if (status === "Charged and/or Convicted")
    regActionArr.push("Charged and/or Convicted");
  if (disciplineDecisions[id] || disciplineAllegations[id])
    regActionArr.push("Disciplinary Activity");
  if (advertisingOrders[id]) regActionArr.push(determineAdvertisingHdrTxt(id));
  if (revokeOrders[id] && !revokeOrders[id][0].appealed)
    regActionArr.push(determineRevokedHdrTxt(id));
  if (refuseOrders[id] && !refuseOrders[id][0].appealed)
    regActionArr.push(determineRefusedHdrTxt(id));
  if (suspensionOrders[id] && !suspensionOrders[id][0].appealed) {
    regActionArr.push(determineSuspendedHdrTxt(id));
  }

  // TODO: remove excpetion for B48282, add this as new status
  if (id === "B48282")
    regActionArr.push("Proposed Licence Refusal - Under Appeal");

  if (
    conditionsOrders[id] &&
    !conditionsOrders[id][0].appealed &&
    !conditionsOrders[id][0].hidden
  )
    regActionArr.push(determineConditionsHdrTxt(id));
  if (complianceOrders[id])
    regActionArr.push("Notice of Proposal to Make a Complaince Order");

  return regActionArr.sort();
};

export const insolvencyProceedings = [
  "B45585",
  "B47614",
  "B46732",
  "B46186",
  "B47821",
  "B61531",
  "B61431",
  "B60843",
  "B48869",
  "B46727",
  "B48736",
  "B46593",
  "B62185",
  "B36070",
  "B46303",
  "B12265",
  "B43256",
  "B31329",
  "B60689",
  "B46579",
];
