import React from "react";
import TagManager from "react-gtm-module";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";
import store from "./store";
import history from "./history";

import SearchPage from "./pages/Search";
import BuilderSearchResultsPage from "./pages/BuilderSearchResults";
import EnrolmentSearchResultsPage from "./pages/EnrolmentSearchResults";
import ProfilePage from "./pages/Profile";
import UmbrellaProfilePage from "./pages/UmbrellaProfile";
import UnlicensedProfilePage from "./pages/UnlicensedProfile";
import GlossaryPage from "./pages/OBDGlossary";
import TermsOfUsePage from "./pages/TermsOfUse";
import ErrorPage from "./pages/ErrorPage";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

const tagManagerArgs = {
  gtmId: "G-VDYZTHM9ZE",
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <Provider store={store}>
      <Helmet>
        <meta
          name="description"
          content="The Home Construction Regulatory Authority (HCRA) regulates new home builders and vendors in the province. We protect the public interest by fostering a fair, safe and informed marketplace that supports the goal of a continuously improved home building industry in Ontario. The HCRA holds licensed builders to professional standards and works to enhance consumer confidence in the home building industry in Ontario. In addition, the HCRA strives to educate consumers to ensure that they can make informed decisions and understand the benefits of a regulated home building sector. The HCRA is a not-for-profit corporation that is designated by the provincial government to administer and enforce the New Home Construction Licensing Act, 2017 and associated regulations."
        />
        <meta
          name="keywords"
          content="home building, home construction, home builders, consumer protection, homebuyer, homeowner, home buying, home construction regulatory authority, hcra, building industry, ontario builder directory, regulatory authority, ontario regulations, tarion, tarion warranty corporation, builder licence, new home ontario, home owner, builder licence, ontario home builders association, ohba, tim hadwen, cpbh, canadians for properly built homes, Barbara Captijn, Karen Somerville, Virginia West, bereavement authority of ontario, condominium authority of ontario, cao, condominium management regulatory authority of ontario, cmrao, electrical safety authority, ontario one call, ontario motor vehicle industry council, omvic, real estate council of ontario, reco, retirement homes regulatory authority, rhra, technical standards and safety authority, tssa, travel industry council of ontario, tico, vintners quality alliance ontario, vqao"
        />
        <meta name="author" content="@hcraontario" />
        <meta name="siteUrl" content="https://obd.hcraontario.ca" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>

      <Router history={history}>
        <Switch>
          <Route path="/buildersearchresults">
            <BuilderSearchResultsPage />
          </Route>

          <Route path="/enrolmentsearchresults">
            <EnrolmentSearchResultsPage />
          </Route>

          <Route path="/profile/:id" render={(props) => <ProfilePage />} />

          <Route
            path="/umbrella/:id"
            render={(props) => <UmbrellaProfilePage />}
          />

          <Route
            path="/unlicensed/:id"
            render={(props) => <UnlicensedProfilePage />}
          />

          <Route path="/glossary">
            <GlossaryPage />
          </Route>

          <Route path="/terms-of-use">
            <TermsOfUsePage />
          </Route>

          <Route exact path="/">
            <SearchPage />
          </Route>

          <Route path="/error">
            <ErrorPage />
          </Route>

          <Route path="/">
            <ErrorPage />
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
