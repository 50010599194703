import React from 'react';
import ClassNames from 'classnames';
import componentStyles from './PillInput.module.scss';
import { FaCheck } from 'react-icons/fa';

const PillInput = ({
    name, 
    label, 
    type, 
    id,
    value,
    isSelected,
    changeHandler, 
    ...props}) => {

    return(
        <label className={isSelected ? ClassNames(componentStyles.pillLabel, componentStyles.checked) : componentStyles.pillLabel}>
            <input 
                id={id}
                name={name}
                value={value ? value : id} 
                type={type}
                checked={isSelected}
                onChange={changeHandler}/>
            <div className={componentStyles.pseudoCheck}>
                <FaCheck />
            </div>
            <span>{label}</span>
        </label>
    )
}

export default PillInput;