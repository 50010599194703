import React from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory } from "react-router-dom";
import componentStyles from "./Nav.module.scss";
import { ReactComponent as Logo } from "../../svgs/hcraLogoColor.svg";
import { FaArrowLeft, FaSearch } from "react-icons/fa";
import Button from "../Button/Button";

const Nav = ({ home, ...props }) => {
  const history = useHistory();
  return (
    <nav aria-label="HCRA Main Navigation" className={componentStyles.nav}>
      <Container>
        <Row className={componentStyles.row}>
          <Col md={9}>
            <div className={componentStyles.backLinkCont}>
              <a href="https://www.hcraontario.ca">
                <Logo
                  className={componentStyles.logo}
                  alt="Home Construction Regulatory Authority Logotype"
                />
              </a>

              {!home && (
                <div className={componentStyles.buttonGroup}>
                  {/* <Button
                    clickEvt={() => history.go(-1)}
                    size="sm"
                    icon={<FaArrowLeft />}
                    text="Back"
                  /> */}

                  <Button
                    linkPath="/"
                    size="sm"
                    icon={<FaSearch />}
                    text="New Search"
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </nav>
  );
};

export default Nav;
