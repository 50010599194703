import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import{ FiExternalLink } from 'react-icons/fi';

const EnrolmentListTile = ({enrolmentData}) => {

    return(
        <div className="tile">

            <div>
                <span className="label">Enrolment Date: </span>
                <span>
                    <Moment parse="YYYY-MM-DD'T'HH:mm:ss*SSSZZZZ" format="MMMM D, YYYY">
                        {enrolmentData["Enrolment Date"]}
                    </Moment>
                </span>
            </div>

            <div>
                <span className="label">Address: </span>
                <span>{enrolmentData["Address"]}</span>
            </div>

            <div>
                <span className="label">City: </span>
                <span className="sentenceCase">{enrolmentData["City"].toLowerCase()}</span>
            </div>

            <div>
                <span className="label">Lot: </span>
                <span>{enrolmentData["Lot"] ? enrolmentData["Lot"] : '---' }</span>
            </div>

            <div>
                <span className="label">Plan: </span>
                <span>{enrolmentData["Plan"] ? enrolmentData["Plan"] : '---' }</span>
            </div>

            <div>
                <span className="label">Warranty Start: </span>
                <span>
                    {enrolmentData[" Warranty Start"]
                    ?
                    <Moment parse="YYYY-MM-DD'T'HH:mm:ss*SSSZZZZ" format="MMMM D, YYYY">
                        {enrolmentData[" Warranty Start"]}
                    </Moment>
                    :
                    <span>---</span>
                    }
                </span>
            </div>

            <div>
                <span className="label">Warranty End: </span>
                <span>
                    {enrolmentData["Warranty End"]
                    ?
                    <Moment parse="YYYY-MM-DD'T'HH:mm:ss*SSSZZZZ" format="MMMM D, YYYY">
                        {enrolmentData["Warranty End"]}
                    </Moment>
                    :
                    <span>---</span>
                    }
                </span>
            </div>

            <div>
                <span className="label">Home Type: </span>
                <span>
                    {enrolmentData["Home Type"]
                    ?
                    <span>{enrolmentData["Home Type"]}</span>
                    :
                    <span>---</span>
                    }
                </span>
            </div>

            <div>
                <Link 
                    key={enrolmentData["Account Number"]} 
                    to={`/profile/${enrolmentData["Account Number"]}`}
                    style={{fontSize:`1.2rem`, fontWeight:`bold`}}>
                    <FiExternalLink style={{verticalAlign: 'center', marginRight: '.5rem', fontSize: `1.5rem`}}/> 
                    {enrolmentData["Builder/Vendor Name"]}
                </Link>
            </div>
            
        </div>
    )
}

export default EnrolmentListTile;