import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import BuilderListRow from "./BuilderListRow";
import BuilderListTile from "./BuilderListTile";
import ToolTip from "../ToolTip/ToolTip";

const BuilderList = ({ builders, ...props }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      {!isMobile ? (
        <table>
          <thead>
            <tr>
              <th>
                <ToolTip
                  link="/glossary/"
                  internal
                  definition="Legal name is the registered company name that appears on legal documents."
                >
                  Vendor/Builder Legal Name
                </ToolTip>
              </th>
              <th>
                <ToolTip
                  link="/glossary/"
                  internal
                  definition="Doing business as is the name of the business known to the public."
                >
                  Doing Business As Name
                </ToolTip>
              </th>
              <th>Location</th>
              <th>
                <ToolTip
                  link="/glossary/#licensed"
                  internal
                  definition="The builder or vendor is currently licensed by the HCRA to build and/or sell new homes."
                >
                  Licensed
                </ToolTip>
              </th>
              <th>
                <ToolTip link="/glossary/#licenceStatus" internal>
                  Licence Status
                </ToolTip>
              </th>
            </tr>
          </thead>
          <tbody>
            {builders.map((builder, i) => (
              <BuilderListRow
                key={`${builder.id}_${i}`}
                builderData={builder}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <>
          {builders.map((builder, i) => (
            <BuilderListTile key={`${builder.id}_${i}`} builderData={builder} />
          ))}
        </>
      )}
    </>
  );
};

BuilderList.propTypes = {
  builders: PropTypes.array.isRequired,
};

export default BuilderList;
