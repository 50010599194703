import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import EnrolmentListRow from './EnrolmentListRow';
import EnrolmentListTile from './EnrolmentListTile';
import ToolTip from '../ToolTip/ToolTip'

const EnrolmentList = ({enrolments,...props}) => {
    const isMobile = useMediaQuery({ maxWidth: 990 });

    return(
        <div className="overflowContainer">
            {!isMobile 
            ?
            <table>
                <thead>
                    <tr>
                        <th>
                            Enrolment Date
                        </th>
                        <th>Address</th>
                        <th>City</th>
                        <th>
                            <ToolTip 
                                link="/glossary/#lot" 
                                internal 
                                definition="A reference number assigned to a piece of land owned by an individual or entity.">
                                    Lot
                            </ToolTip>
                        </th>
                        <th>
                            <ToolTip 
                                link="/glossary/#plan" 
                                internal 
                                definition="A reference number assigned to each home by the municipality in which the home is located.">
                                    Plan
                            </ToolTip>
                        </th>
                        <th>
                            <ToolTip 
                                link="/glossary/#warrnatyStart" internal 
                                definition="The date in which warranty coverage for the property begun.">
                                    Warranty Start
                            </ToolTip>
                        </th>
                        <th>
                            <ToolTip 
                                link="/glossary/#warrnatyEnd" internal 
                                definition="The date in which warranty coverage for the property ends.">
                                    Warranty End
                            </ToolTip>
                        </th>
                        <th>Home Type</th>
                        <th>
                            <ToolTip 
                                link="/glossary/" 
                                internal 
                                definition="Legal name is the registered company name that appears on legal documents.">
                                Vendor/Builder Legal Name
                            </ToolTip>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {enrolments.map( (enrolment, i) => <EnrolmentListRow key={i} enrolmentData={enrolment}/>)}
                </tbody>
            </table>
            :
            <>
                {enrolments.map( (enrolments, i) => <EnrolmentListTile key={i} enrolmentData={enrolments}/>)}
            </>
            }
        </div>
    )
}

EnrolmentList.propTypes = {
    enrolments: PropTypes.array.isRequired
}

export default EnrolmentList;