import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ClassNames from "classnames";
import componentStyles from "./TextInput.module.scss";
import { IoIosWarning } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa";

const TextInput = ({
  name,
  label,
  instructions,
  type,
  size,
  id,
  value,
  invalid,
  errorMsg,
  changeHandler,
  placeholder,
  required,

  suggestions,
  suggestionKey,
  suggestionHandler,
  suggestionLinkPath,
  suggestionLinkKey,
  suggestionFocus,
  ...props
}) => {
  // const [suggestionsOpen, toggleSuggestions] = useState(
  //   suggestions && suggestions.length >= 1
  // );

  // useEffect(() => {
  //   toggleSuggestions(suggestions && suggestions.length >= 1);
  // }, [suggestions]);

  return (
    <div
      className={
        size === "sm"
          ? ClassNames(componentStyles.inputContainer, componentStyles.sm)
          : componentStyles.inputContainer
      }
    >
      <label
        className={
          invalid
            ? ClassNames(componentStyles.textLabel, componentStyles.error)
            : componentStyles.textLabel
        }
        htmlFor={name}
      >
        {label}{" "}
        {required && <span className={componentStyles.requiredLabel}>*</span>}
        {instructions && (
          <span className={componentStyles.instructions}>{instructions}</span>
        )}
      </label>
      <input
        className={
          invalid
            ? ClassNames(
                componentStyles.textInput,
                componentStyles.error,
                "sentenceCase"
              )
            : ClassNames(componentStyles.textInput, "sentenceCase")
        }
        id={id}
        type={type ? type : "text"}
        name={name}
        valid={invalid ? "false" : "true"}
        onChange={changeHandler ? changeHandler : ""}
        // onFocus={() => toggleSuggestions(true)}
        // onBlur={() => toggleSuggestions(false)}
        placeholder={placeholder ? placeholder : ""}
        value={value ? value : ""}
        required={required ? true : false}
      />

      {/* {suggestionsOpen && (
        <ul className={componentStyles.suggestionsList}>
          {suggestions.map((suggestion, i) => (
            <li
              key={`${suggestion[suggestionLinkKey]}_${i}`}
              onClick={() => {
                toggleSuggestions(false);
                suggestionHandler(
                  name,
                  suggestion[suggestionKey].toLowerCase()
                );
              }}
            >
              <span>{suggestion[suggestionKey].toLowerCase()}</span>

              {suggestionLinkPath && (
                <Link
                  to={`${suggestionLinkPath}${suggestion[suggestionLinkKey]}`}
                >
                  Visit Profile <FaArrowRight />
                </Link>
              )}
            </li>
          ))}
        </ul>
      )} */}

      {errorMsg && (
        <div className={componentStyles.errorMsg}>
          <IoIosWarning />{" "}
          <span>{errorMsg ? errorMsg : "This is a required field."}</span>
        </div>
      )}
    </div>
  );
};
export default TextInput;
