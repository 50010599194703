import React, { useState } from "react";
import { Row, Col } from "react-grid-system";
import componentStyles from "./TabSidebar.module.scss";
import Tab from "./Tab";

const TabSidebar = ({ clickEvt, activeTab, ...props }) => {
  const tabs = props.children;
  // const [activeTab, setActiveTab] = useState(0);

  return (
    <Row align="stretch">
      <Col xl={4} md={12}>
        {/* tab list */}
        <div
          aria-label="Builder Profile Tabs"
          role="tablist"
          className={componentStyles.tablist}
        >
          {tabs.map((tab, i) => {
            return (
              <Tab
                key={`${tab.props.id}_${i}`}
                id={tab.props.id}
                // clickEvt={() => setActiveTab(i)}
                clickEvt={() => clickEvt(i, tab.props.disabled)}
                isSelected={i === activeTab}
                icon={tab.props.icon}
                text={tab.props.text}
                badgeNum={tab.props.badgeNum}
                disabled={tab.props.disabled}
              />
            );
          })}
        </div>
      </Col>

      <Col xl={8} className={componentStyles.tabPanel}>
        {/* tab content */}
        <div
          tabIndex="0"
          role="tabpanel"
          key={`${tabs[activeTab].props.id}-tab`}
          id={`${tabs[activeTab].props.id}-tab`}
        >
          {tabs[activeTab].props.children}
        </div>
      </Col>
    </Row>
  );
};

export default TabSidebar;
