import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  checkNullFilter,
  getFilters,
  getPaginatedResults,
} from "../utils/sortFunctions";
import {
  getBuilders,
  orderSearch,
  setSearchPage,
} from "../actions/builderActions";
import { getQueryParams } from "../utils/queries";
import { normalizeAmtStr } from "../utils/numNormalizeFunctions";
import { getAggregatedList } from "../utils/sortFunctions";

import Alert from "../components/Alert/Alert";
import BuilderList from "../components/BuilderList/BuilderList";
import Button from "../components/Button/Button";
import FilterControls from "../components/FilterControls/FilterControls";
import FilterList from "../components/FilterList/FilterList";
import Modal from "../components/Modal/Modal";
import PageLayout from "../layouts/MainLayout";
import PillSortButton from "../components/PillInput/PillSortButton";
import PaginationNav from "../components/PaginationNav/PaginationNav";
import SortControls from "../components/SortControls/SortControls";
import { FaFilter } from "react-icons/fa";

const BuilderSearchResultsPage = ({
  getBuilders,
  orderSearch,
  setSearchPage,

  loading,
  errorMessage,
  builders,
  searchPagination,
  searchFilters,

  searchOrderBy,
  searchOrderDirection,
  ...props
}) => {
  // store modal state in component state
  const [modalOpen, toggleModal] = useState(false);

  // display search query parameters
  const query = new URLSearchParams(useLocation().search);
  const queryParams = getQueryParams(query);
  const filtersList = getFilters(searchFilters);
  const searchTerms = {
    builderNum: query.get("builderNum"),
    builderName: query.get("builderName"),
    builderLocation: query.get("builderLocation"),
    officerDirector: query.get("officerDirector"),
    licenceStatus: query.get("licenceStatus"),
    umbrellaCo: query.get("umbrellaCo"),
    yearsActive: query.get("yearsActive"),
    convictions: query.get("convictions"),
  };

  // filter by searchFilters[licensed, licenseState, city ]
  const filteredBuilderList = builders.filter(
    (builder) =>
      (builder.licensed === searchFilters.licensed ||
        checkNullFilter(searchFilters.licensed)) &&
      ((builder.licenseState &&
        builder.licenseState.toLowerCase() === searchFilters.licenceState) ||
        checkNullFilter(searchFilters.licenceState)) &&
      (searchFilters.city.indexOf(builder.city.toLowerCase()) > -1 ||
        checkNullFilter(searchFilters.city))
  );

  const cities = getAggregatedList(builders, "city");
  const licensedBool = getAggregatedList(builders, "licensed");
  const licenseStates = getAggregatedList(builders, "licenseState");

  const setListOrder = (filterId) => {
    if (filterId === searchOrderBy && searchOrderDirection === "asc")
      orderSearch({ filter: filterId, order: "desc" });
    else if (filterId === searchOrderDirection)
      orderSearch({ filter: filterId, order: "asc" });
    else orderSearch({ filter: filterId, order: "asc" });
  };

  const setPagination = (pageNum) => setSearchPage(pageNum);
  useEffect(() => {
    getBuilders(searchTerms);
  }, [getBuilders]);

  return (
    <PageLayout
      pageHeader="Search Results"
      loading={loading}
      error={errorMessage}
      backLinkUrl="/"
      backLinkText="Back to Search"
    >
      <p>
        Thank you for your search. To view a builder’s full profile - including
        details related to their regulatory compliance and warranty information
        – please click on the name of the vendor/builder in the search results
        below. HCRA updates licensing and compliance information immediately if
        there is any change.
        <br />
        All warranty information is updated quarterly with Tarion data that is
        shared with HCRA for posting to a builder’s profile. If you cannot find
        the builder you are searching for, please use the directory’s advanced
        search.
      </p>

      <span className="bold">Results:</span>
      <span>
        {" "}
        {`${
          filteredBuilderList.length
            ? normalizeAmtStr(filteredBuilderList.length)
            : 0
        } builders found`}
      </span>
      <br />

      <span className="bold">Search Parameters:</span>
      <br />
      {queryParams.length > 0 ? (
        <ul>
          {queryParams.map((query, i) => (
            <li
              key={`${query[0].replace(/\s/g, "")}_${i}`}
            >{`${query[0]}: ${query[1]}`}</li>
          ))}
        </ul>
      ) : (
        <p>No search parameters provided.</p>
      )}

      {Object.keys(searchFilters).filter(
        (filter) => !checkNullFilter(searchFilters[filter])
      ).length > 0 && <FilterList filtersList={filtersList} />}

      {modalOpen && (
        <Modal closeEvt={() => toggleModal(!modalOpen)}>
          <FilterControls />
        </Modal>
      )}

      <div className="flexContainer mobileCol justifyBetween alignEnd">
        <SortControls>
          <PillSortButton
            id="name"
            label="Legal Name"
            active={searchOrderBy === "name"}
            order={searchOrderDirection}
            clickEvt={() => setListOrder("name")}
          />

          <PillSortButton
            id="operatingName"
            label="Doing Business As Name"
            active={searchOrderBy === "operatingName"}
            order={searchOrderDirection}
            clickEvt={() => setListOrder("operatingName")}
          />

          <PillSortButton
            id="city"
            label="Builder Location"
            active={searchOrderBy === "city"}
            order={searchOrderDirection}
            clickEvt={() => setListOrder("city")}
          />

          <PillSortButton
            id="licenceStatus"
            label="Licence Status"
            active={searchOrderBy === "licenseState"}
            order={searchOrderDirection}
            clickEvt={() => setListOrder("licenseState")}
          />
        </SortControls>

        <div style={{ marginBottom: `.5rem` }}>
          <Button
            id="filterBtn"
            icon={<FaFilter />}
            size="sm"
            clickEvt={() => toggleModal(!modalOpen)}
            text="Filter Results"
            disabled={
              cities.length < 2 &&
              licensedBool.length < 2 &&
              licenseStates.length < 2
            }
          />
        </div>
      </div>

      {filteredBuilderList.length <= 0 ? (
        <Alert>
          <p style={{ fontStyle: `italic` }}>
            No builders matching those search terms can be found.
          </p>
        </Alert>
      ) : (
        <BuilderList
          builders={getPaginatedResults(filteredBuilderList, searchPagination)}
        />
      )}

      {filteredBuilderList.length > 10 && (
        <PaginationNav
          currentPage={searchPagination}
          clickEvt={setPagination}
          resultsLength={filteredBuilderList.length}
        />
      )}
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  loading: state.builder.loading,
  errorMessage: state.builder.errorMessage,
  builders: state.builder.builders,
  searchPagination: state.builder.searchPagination,
  searchFilters: state.builder.searchFilters,
  searchOrderBy: state.builder.searchOrderBy,
  searchOrderDirection: state.builder.searchOrderDirection,
});

export default connect(mapStateToProps, {
  getBuilders,
  orderSearch,
  setSearchPage,
})(BuilderSearchResultsPage);
