import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getConvictDetails } from "../actions/builderActions";
import { Container } from "react-grid-system";

import ProfileLayout from "../layouts/ProfileLayout";
import Alert from "../components/Alert/Alert";
import TabSidebar from "../components/TabSidebar/TabSidebar";
import AtAGlance from "../components/AtAGlance/AtAGlance";
import {
  FaUserCircle,
  FaFileAlt,
  FaBalanceScale,
  FaGavel,
  FaHome,
  FaBuilding,
  FaFilePdf,
} from "react-icons/fa";

import ConvictionsList from "../components/ConvictionsList/ConvictionsList";

const UnlicensedProfilePage = ({
  getConvictDetails,
  loading,
  builderDetails,
  profilePDOs,
  profileProperties,
  profileEnrolments,
  profileCondos,
  profileConvictions,
  profileMembers,
  ...props
}) => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const trySetActiveTab = (tabInd) => {
    if (tabInd === 1 || tabInd === 2) return;
    else setActiveTab(tabInd);
  };

  useEffect(() => {
    getConvictDetails(id);
  }, [id, getConvictDetails]);

  let ordersNumber;
  if (builderDetails && builderDetails.NoticeOfProposalsIssued)
    ordersNumber = builderDetails.NoticeOfProposalsIssued;
  else if (id === "B48282") ordersNumber = 1;
  else ordersNumber = 0;

  return (
    <ProfileLayout
      loading={loading}
      builderName={builderDetails ? builderDetails["CHARGE_AGAINST"] : null}
      licenceNumber="N/A"
      licencestatus={builderDetails ? "Charged and/or Convicted" : null}
      lastUpdate={null}
      initiallyLicensed={null}
      umbrella={
        builderDetails &&
        builderDetails["UMBRELLA"] &&
        builderDetails["UMBRELLA"] !== "NULL"
          ? builderDetails["UMBRELLA"]
          : null
      }
      umbrellaId={
        builderDetails &&
        builderDetails["UMBRELLA_ID"] &&
        builderDetails["UMBRELLA_ID"] !== "NULL"
          ? builderDetails["UMBRELLA_ID"]
          : null
      }
    >
      <Container>
        {!builderDetails && (
          <Alert>
            <p>Sorry, no builder matching that ID can be found.</p>
          </Alert>
        )}

        {builderDetails && (
          <TabSidebar activeTab={activeTab} clickEvt={trySetActiveTab}>
            {/* OVERVIEW */}
            <div id="overview" text="Overview" icon={<FaUserCircle />}>
              <Alert type="error" header="Builder Not Licensed">
                <p>
                  This builder is not licensed with the HCRA and has been
                  charged and/or convicted for operating without a licence.
                  Click on the <b>Charges and/or Convictions Tab</b> for more
                  information.
                </p>
              </Alert>

              {id === "B48282" && (
                <Alert type="warning" header="Licence Refused">
                  For more information, see the Notices and Orders tab.
                </Alert>
              )}

              <AtAGlance
                clickEvt={trySetActiveTab}
                profileType="unlicensed"
                totalunits="N/A"
                lowrise="N/A"
                condoProjects="N/A"
                notices={ordersNumber}
                orders={null}
                convictions={profileConvictions.length}
              />
            </div>

            <div
              id="properties"
              text="Properties"
              icon={<FaHome />}
              disabled={true}
            ></div>

            <div
              id="condoProjects"
              text="Condo Projects"
              icon={<FaBuilding />}
              disabled={true}
            ></div>

            <div
              id="notices"
              text="Notices &amp; Orders"
              // text="Registrar Directives &amp; Advisories"
              icon={<FaFileAlt />}
              badgeNum={ordersNumber}
            >
              <h3>Notices &amp; Orders</h3>
              <p>
                Under Section 43 of the New Home Construction Licensing Act,
                2017 (NHCLA) the HCRA may issue a Notice of Proposal to an
                applicant or licensee with the intent to refuse to grant or
                renew a licence, suspend or revoke a licence or apply conditions
                to a licence.
              </p>

              <p>
                The HCRA may also issue an order, which is a requirement for the
                licensee to take an action or refrain from taking one. Orders
                can relate to false advertising, freezing assets or trust funds
                or compliance with the NHCLA.
              </p>
              {(builderDetails.NoticeOfProposalsIssued == 0 ||
                !builderDetails.NoticeOfProposalsIssued) &&
                id !== "005" &&
                id !== "B48282" && (
                  <Alert type="success">
                    This builder has not been issued any registrar notices or
                    orders.
                  </Alert>
                )}

              {id === "B48282" && (
                <>
                  <h4>Notice to Refuse</h4>
                  <table style={{ marginBottom: `2rem` }}>
                    <thead>
                      <tr>
                        <th>Details</th>
                        <th>Date Issued</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href={require("../docs/Notice of Proposal to Refuse Refuse Renewal and Apply Conditions.pdf")}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaFilePdf
                              style={{
                                fontSize: `1.5rem`,
                                marginRight: `.5rem`,
                              }}
                            />
                            <span>
                              Notice of Proposal to Refuse to Grant (PDF)
                            </span>
                          </a>
                        </td>
                        <td>September 9, 2021</td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}
            </div>

            <div
              id="discipline"
              text="Discipline &amp; Appeals"
              icon={<FaBalanceScale />}
              badgeNum={
                builderDetails.DisciplineandAppeals
                  ? builderDetails.DisciplineandAppeals
                  : 0
              }
            >
              <h2>Discipline &amp; Appeals</h2>

              {(!builderDetails.DisciplineandAppeals ||
                builderDetails.DisciplineandAppeals === 0) && (
                <Alert type="success">
                  This builder has no history of disciplinary activities with
                  the HCRA.
                </Alert>
              )}
            </div>

            <div
              id="charges"
              text="Court Charges and/or Convictions"
              icon={<FaGavel />}
              badgeNum={profileConvictions.length}
            >
              <div>
                <h2>Court Charges and/or Convictions</h2>
                <p>
                  The HCRA can lay charges under the New Home Construction
                  Licensing Act (NHCLA), the Ontario New Home Warranties Plan
                  Act (ONHWPA) or the Provincial Offences Act (POA). The Charge
                  column shows the specific offence (section) under which a
                  defendant was charged and convicted.
                </p>

                {profileConvictions.length === 0 ? (
                  <Alert type="success">
                    This builder has no charges or convictions.
                  </Alert>
                ) : (
                  <ConvictionsList convictionsList={profileConvictions} />
                )}
              </div>
            </div>
          </TabSidebar>
        )}
      </Container>
    </ProfileLayout>
  );
};

const mapStateToProps = (state) => ({
  builderDetails: state.builder.profiledata,
  contactDetails: state.builder.profileContact,
  profilePDOs: state.builder.profilePDOs,
  profileProperties: state.builder.profileproperties,
  profileEnrolments: state.builder.profileenrolments,
  profileMembers: state.builder.profileMembers,
  profileCondos: state.builder.profilecondos,
  profileConvictions: state.builder.profileconvictions,
  loading: state.builder.loading,
});

export default connect(mapStateToProps, { getConvictDetails })(
  UnlicensedProfilePage
);
