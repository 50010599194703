import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import Moment from "react-moment";

import ToolTip from "../ToolTip/ToolTip";
import { normalizeAmtStr } from "../../utils/numNormalizeFunctions";
import { updateDate } from "../../utils/tarionUpdates";

import { ReactComponent as Crane } from "../../imgs/crane.svg";
import { ReactComponent as Home } from "../../svgs/houseBlue.svg";
import { ReactComponent as Condo } from "../../imgs/condoBuilding.svg";

const AtAGlance = ({
  clickEvt,

  profileType,
  totalunits,
  lowrise,
  condoProjects,
  notices,
  discipline,
  convictions,

  chargeableConciliations,
  totalClaims,
  outstanding,
  breach,
  tab,
  isUmbrella,
  ...props
}) => {
  const tabDict = {
    umbrella: {
      possessions: 2,
      condos: 3,
    },
    builder: {
      possessions: 2,
      condos: 3,
      notices: 4,
      discipline: 5,
      charges: 6,
    },
    unlicensed: {
      notices: 3,
      discipline: 4,
      charges: 5,
    },
  };
  let tabObj = profileType ? tabDict[profileType] : tabDict.builder;

  return (
    <>
      <h2>
        {isUmbrella ? "Summary At A Glance" : "At A Glance"}{" "}
        <span
          style={{ display: `inline`, fontWeight: `400`, fontSize: `1rem` }}
        >
          Last 10 Years, Updated Daily
          {isUmbrella && <>; totals for all related companies</>}
        </span>
      </h2>
      <div className="fieldset">
        <Container>
          <Row>
            <Col xl={12}>
              <div className="flexContainer">
                <div className="glanceSection">
                  <Crane className="icon" />
                  <span className="number">
                    {totalunits ? normalizeAmtStr(totalunits) : `0`}
                  </span>
                  <ToolTip
                    definition="The total number of freehold and condominium properties occupied by a homeowner."
                    clickEvt={() => clickEvt(tabObj.possessions)}
                  >
                    Total <br />
                    Possessions
                  </ToolTip>
                </div>

                <div className="glanceSection">
                  <Home className="icon" />
                  <span className="number">
                    {lowrise ? normalizeAmtStr(lowrise) : `0`}
                  </span>
                  <ToolTip
                    definition="The number of freehold properties that have been enrolled with Tarion and are under warranty."
                    clickEvt={() => clickEvt(tabObj.possessions)}
                  >
                    Total <br />
                    Freehold Properties
                  </ToolTip>
                </div>

                <div className="glanceSection">
                  <Condo className="icon" />
                  <span className="number">
                    {condoProjects ? normalizeAmtStr(condoProjects) : `0`}
                  </span>

                  <ToolTip
                    definition="The number of condominium properties that have been enrolled with Tarion and are under warranty."
                    clickEvt={() => clickEvt(tabObj.condos)}
                  >
                    Condominium <br />
                    Units
                  </ToolTip>
                </div>
              </div>
            </Col>

            {!isUmbrella && (
              <Col>
                <h3 style={{ fontSize: `1rem`, marginTop: `2rem` }}>
                  Regulatory Actions
                </h3>
                <div className="flexContainer">
                  <div className="glanceSection">
                    <span className="number">{notices ? notices : `0`}</span>
                    <ToolTip
                      definition="Under the New Home Construction Licensing Act, 2017, the HCRA may issue a Notice of Proposal to an applicant or licensee with the intent to refuse to grant or renew a licence, suspend or revoke a licence or apply conditions to a licence."
                      clickEvt={() => clickEvt(tabObj.notices)}
                    >
                      Notices and/or <br />
                      Orders Issued
                    </ToolTip>
                  </div>

                  <div className="glanceSection">
                    <span className="number">
                      {discipline ? discipline : `0`}
                    </span>
                    <ToolTip clickEvt={() => clickEvt(tabObj.discipline)}>
                      Disciplinary
                      <br />
                      Activities
                    </ToolTip>
                  </div>

                  {profileType !== "umbrella" && (
                    <div className="glanceSection">
                      <span className="number">
                        {convictions ? convictions : `0`}
                      </span>
                      <ToolTip clickEvt={() => clickEvt(tabObj.charges)}>
                        Court Charges
                        <br />
                        and/or Convictions
                      </ToolTip>
                    </div>
                  )}
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>

      {profileType !== "unlicensed" && (
        <div style={{ marginTop: `2rem` }}>
          <h2>
            Tarion Warranty Overview{" "}
            <span
              style={{ display: `inline`, fontWeight: `400`, fontSize: `1rem` }}
            >
              Last 10 Years
              <br />
              Chargeable Conciliations updated daily, other data updated as of{" "}
              <Moment
                format="MMM D, YYYY"
                style={{
                  display: `inline`,
                  fontWeight: `400`,
                  fontSize: `1rem`,
                }}
              >
                {updateDate}
              </Moment>
            </span>
          </h2>
          <div className="fieldset">
            <Container>
              <Row>
                <Col xl={12}>
                  <div className="flexContainer">
                    <div className="glanceSection">
                      <span className="number">
                        {chargeableConciliations
                          ? chargeableConciliations
                          : `0`}
                      </span>
                      <ToolTip
                        link="/glossary/"
                        internal
                        definition="Occurs when Tarion determines that one or more items reported by the homeowner is warranted under the Ontario New Home Warranties Plan Act and the vendor/builder failed to repair or resolve the item(s) during the applicable repair report and no exception to chargeability applies."
                      >
                        Chargeable <br />
                        Conciliations
                      </ToolTip>
                    </div>

                    <div className="glanceSection">
                      <span
                        className="number"
                        style={{ letterSpacing: `0`, marginRight: `.5rem` }}
                      >
                        {totalClaims
                          ? normalizeAmtStr(totalClaims, true)
                          : `$0.00`}
                      </span>
                      <ToolTip
                        link="/glossary/"
                        internal
                        definition="Shows the total amount of money a vendor/builder has paid in claims of warranty violations."
                      >
                        Total Dollars <br />
                        Paid in Claims
                      </ToolTip>
                    </div>

                    <div className="glanceSection">
                      <span
                        className="number"
                        style={{ letterSpacing: `0`, marginRight: `.5rem` }}
                      >
                        {outstanding
                          ? normalizeAmtStr(outstanding, true)
                          : `$0.00`}
                      </span>
                      <ToolTip
                        link="/glossary/"
                        internal
                        definition="The amount owing by the builder or vendor to Tarion as a result of warranty claim(s) submitted by a homeowner and investigated and/or resolved by Tarion that has been outstanding for greater than 30 days."
                      >
                        Outstanding Claims <br />
                        Paid Owing
                      </ToolTip>
                    </div>
                  </div>
                </Col>

                <Col style={{ marginTop: `2rem` }}>
                  <div className="flexContainer">
                    <div className="glanceSection">
                      <span
                        className="number"
                        style={{ letterSpacing: `0`, marginRight: `.5rem` }}
                      >
                        {breach ? normalizeAmtStr(breach, true) : `$0.00`}
                      </span>
                      <ToolTip
                        link="/glossary/"
                        internal
                        definition="Outstanding claims on cases where there was a warranty breach by the builder or vendor."
                      >
                        Breach
                      </ToolTip>
                    </div>

                    <div className="glanceSection">
                      <span
                        className="number"
                        style={{ letterSpacing: `0`, marginRight: `.5rem` }}
                      >
                        {tab ? normalizeAmtStr(tab, true) : `$0.00`}
                      </span>
                      <ToolTip
                        link="/glossary/"
                        internal
                        definition="Outstanding amount owing for claim case(s) where Tarion is fulfilling the warranty obligation on behalf of the builder or vendor. This may also include amounts owing as a result of chargeable conciliation fees charged to the builder or vendor."
                      >
                        Tarion Action on Behalf <br />
                        (TAB) of Vendor/Builder
                      </ToolTip>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <span className="footnote">
            For more information, refer to our{" "}
            <Link to="/glossary/">Glossary of Terms</Link>.
          </span>
        </div>
      )}
    </>
  );
};

export default AtAGlance;
