import React from 'react';
import ClassNames from 'classnames';
import componentStyles from './Alert.module.scss';
import { FaExclamationTriangle, FaFlag, FaCheckCircle } from "react-icons/fa";

const Alert = ({type, header, ...props}) => {
    // types: neutral (default), warning, error, success
    let icon;
    if(type==="success") icon = <FaCheckCircle />
    else if(type==="warning" || type==="error") icon = <FaExclamationTriangle />
    else icon=<FaFlag />

    return(
        <div className={ header ? ClassNames(componentStyles.alert, componentStyles[type]) : ClassNames(componentStyles.alert, componentStyles.flex, componentStyles[type])}>
            <div className={header ? componentStyles.header : ''}>
                <span className={componentStyles.icon}>{icon}</span>
                {header && <h2>{header}</h2>}
            </div>
            
            <div>
                {props.children}
            </div>
            
        </div>
    )
}

export default Alert;