import React from 'react';
import ClassNames from 'classnames';
import componentStyles from './Button.module.scss';
import { FaArrowRight } from 'react-icons/fa';

const Button = ({
    id,
    type,
    icon, 
    size,
    outline, 
    altColor,
    linkPath, 
    clickEvt,
    disabled, 
    text,
    ...props}) => {

    const getButtonClasses = () => {
        let classList = [];
        if(outline) classList.push(componentStyles.buttonOutline);
        else classList.push(componentStyles.buttonSolid)

        if(altColor) classList.push(componentStyles.buttonAltColor);

        if(size==="sm") classList.push(componentStyles.sm);

        return classList;
    }

    return(
        <>
            {linkPath 
            ?
            <a href={linkPath} className={ClassNames(getButtonClasses())}>
                {icon && <span className={componentStyles.icon}>{icon}</span>}
                <span>{text}</span>
                {!icon &&
                    <FaArrowRight aria-hidden="true" className={componentStyles.arrowIcon}/>
                }
            </a>

            :
            <button
            id={id}
            type={type ? type : "button"}
            className={ClassNames(getButtonClasses())}
            disabled={disabled ? true : false}
            onClick={clickEvt}>
                {icon && <span className={componentStyles.icon}>{icon}</span>}
                <span>{text}</span>
                {!icon &&
                    <FaArrowRight aria-hidden="true" className={componentStyles.arrowIcon}/>
                }
            </button>
            }
        </>

    )
}

export default Button;