import React from "react";
import { connect } from "react-redux";
import { filterSearch, clearFilters } from "../../actions/builderActions";
import { getAggregatedList } from "../../utils/sortFunctions";
import componentStyles from "../FilterControls/FilterControls.module.scss";
import PillInput from "../PillInput/PillInput";

import { FaFilter } from "react-icons/fa";

const EnrolmentFilterControls = ({
  filterSearch,
  clearFilters,
  searchFilters,
  builders,
  enrolments,
  ...props
}) => {
  const setFilters = (e) => {
    const value = e.target.value;
    const key = e.target.name;
    filterSearch({
      ...searchFilters,
      [key]: value,
    });
  };

  const setCityFilter = (e) => {
    if (e.target.value === "null") {
      filterSearch({
        ...searchFilters,
        city: [],
      });
    } else {
      const newCityArr =
        searchFilters.city.indexOf(e.target.value) > -1
          ? searchFilters.city.splice(
              searchFilters.city.indexOf(e.target.value),
              1
            )
          : searchFilters.city.concat(e.target.value);

      filterSearch({
        ...searchFilters,
        city: newCityArr,
      });
    }
  };

  const cities = getAggregatedList(enrolments, "City");
  const homeTypes = getAggregatedList(enrolments, "Home Type");

  return (
    <div>
      <div className="flexContainer justifyBetween">
        <h2>
          <FaFilter style={{ display: `inline`, marginRight: `1rem` }} />
          Filter Results
        </h2>

        <button
          type="button"
          className={componentStyles.clearBtn}
          onClick={clearFilters}
        >
          Clear Filters
        </button>
      </div>

      {homeTypes.length >= 2 && (
        <div style={{ marginBottom: `2rem` }}>
          <div style={{ marginBottom: `.5rem` }}>
            <span className="bold">Home Type</span>
          </div>
          <div>
            <PillInput
              name="hometype"
              label="All"
              type="radio"
              id="hometype_all"
              value={"null"}
              isSelected={
                searchFilters.hometype === "null" || !searchFilters.hometype
              }
              changeHandler={setFilters}
            />

            {homeTypes.map((type, i) => (
              <PillInput
                id={`type_${type}_${i}`}
                key={`type_${type}_${i}`}
                name="hometype"
                label={type}
                type="radio"
                value={type}
                isSelected={searchFilters.hometype === type}
                changeHandler={setFilters}
              />
            ))}
          </div>
        </div>
      )}

      {cities.length >= 2 && (
        <div style={{ marginBottom: `2rem` }}>
          <div style={{ marginBottom: `.5rem` }}>
            <span className="bold">Home Location</span>
          </div>
          <div>
            <PillInput
              name="city"
              label="All"
              type="radio"
              id="city_all"
              value="null"
              isSelected={
                searchFilters.city === "null" ||
                searchFilters.city.length === 0 ||
                !searchFilters.city
              }
              changeHandler={setCityFilter}
            />

            {cities.map((city, i) => (
              <PillInput
                id={`city_${city}_${i}`}
                key={`city_${city}_${i}`}
                name="city"
                label={city}
                type="radio"
                value={city}
                isSelected={searchFilters.city.indexOf(city) > -1}
                changeHandler={setCityFilter}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  builders: state.builder.builders,
  enrolments: state.builder.enrolments,
  searchFilters: state.builder.searchFilters,
});

export default connect(mapStateToProps, { filterSearch, clearFilters })(
  EnrolmentFilterControls
);
