import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { connect } from "react-redux";
import { getBuilderInfo } from "../actions/builderActions";
import { Container, Row, Col } from "react-grid-system";

import {
  FaUserCircle,
  FaFileAlt,
  FaBalanceScale,
  FaGavel,
  FaHome,
  FaBuilding,
  FaBriefcase,
} from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";
import {
  complianceOrders,
  conditionsOrders,
  determineStatusOverride,
  disciplineAllegations,
  disciplineDecisions,
  refuseOrders,
  revokeOrders,
  suspensionOrders,
  advertisingOrders,
  adminPenalties,
} from "../utils/nopOverrides";

import ProfileLayout from "../layouts/ProfileLayout";
import TabSidebar from "../components/TabSidebar/TabSidebar";
import AtAGlance from "../components/AtAGlance/AtAGlance";
import NOPheaders from "../components/NOPheaders/NOPheaders";
import NOPtable from "../components/NOPtable/NOPtable";

import Loader from "../components/Loader/Loader";
import Alert from "../components/Alert/Alert";
import ConditionsTable from "../components/ConditionsTable/ConditionsTable";
import PDOlist from "../components/PDOlist/PDOlist";
import DisciplineAllegationsTable from "../components/DisciplineTables/DisciplineAllegationsTable";
import DisciplineDecisionsTable from "../components/DisciplineTables/DisciplineDecisionsTable";

const MemberList = React.lazy(() =>
  import("../components/MemberList/MemberList")
);
const PropertiesList = React.lazy(() =>
  import("../components/PropertiesList/PropertiesList")
);
const WarrantiesList = React.lazy(() =>
  import("../components/WarrantiesList/WarrantiesList")
);
const CondoProjectsList = React.lazy(() =>
  import("../components/CondoProjectsList/CondoProjectsList")
);
const ConvictionsList = React.lazy(() =>
  import("../components/ConvictionsList/ConvictionsList")
);
const PDOconvictionList = React.lazy(() =>
  import("../components/ConvictionsList/PDOconvictionList")
);

const ProfilePage = React.memo(
  ({
    getBuilderInfo,
    loading,
    errorMessage,
    builderDetails,
    profilePDOs,
    profileConditions,
    profileProperties,
    profileEnrolments,
    profileCondos,
    profileConvictions,
    profileMembers,
    ...props
  }) => {
    let history = useHistory();
    const [activeTab, setActiveTab] = useState(0);

    const { id } = useParams();
    useEffect(() => {
      const fetchData = async () => {
        await getBuilderInfo(id);
      };
      fetchData().then(() => {
        if (errorMessage !== "") history.push(`/error`);
        setActiveTab(0);
      });
    }, [id, getBuilderInfo, errorMessage, history]);

    let ordersNumber, disciplineNumber;
    let removeLicenceDates = builderDetails["LICENCE_STATUS"] == "Unlicensed";
    if (
      revokeOrders[id] ||
      complianceOrders[id] ||
      conditionsOrders[id] ||
      refuseOrders[id] ||
      suspensionOrders[id] ||
      advertisingOrders[id] ||
      adminPenalties[id]
    ) {
      ordersNumber =
        (revokeOrders?.[id] ? 1 : 0) +
        (Array.isArray(complianceOrders?.[id]) ? 1 : 0) +
        (complianceOrders?.[id] &&
        !Array.isArray(complianceOrders?.[id]) &&
        Object.keys(complianceOrders?.[id])
          ? Object.keys(complianceOrders?.[id]).length
          : 0) +
        (conditionsOrders?.[id] && !conditionsOrders[id][0]?.hidden ? 1 : 0) +
        (refuseOrders?.[id] ? 1 : 0) +
        (suspensionOrders?.[id] ? 1 : 0) +
        (advertisingOrders?.[id] && !advertisingOrders?.[id][0]?.hidden
          ? 1
          : 0) +
        (adminPenalties?.[id] ? 1 : 0);

      removeLicenceDates =
        refuseOrders?.[id] && refuseOrders?.[id][0]?.removeLicenceDates;
    } else ordersNumber = 0;

    if (disciplineDecisions[id] || disciplineAllegations[id]) {
      const numAllegations = disciplineAllegations[id]?.length || 0;
      const numDecisions = disciplineDecisions[id]?.length || 0;
      disciplineNumber = numAllegations + numDecisions;
    } else disciplineNumber = 0;

    return (
      <React.Suspense fallback={<Loader />}>
        <ProfileLayout
          loading={loading}
          builderName={
            builderDetails && builderDetails["VB_NAME"]
              ? builderDetails["VB_NAME"]
              : null
          }
          operatingName={
            builderDetails && builderDetails["OPERATINGNAME"]
              ? builderDetails["OPERATINGNAME"]
              : null
          }
          umbrella={
            builderDetails &&
            builderDetails["Umbrella"] &&
            builderDetails["Umbrella"] !== "NULL"
              ? builderDetails["Umbrella"]
              : null
          }
          umbrellaId={
            builderDetails &&
            builderDetails["Umbrella ID"] &&
            builderDetails["Umbrella ID"] !== "NULL"
              ? builderDetails["Umbrella ID"]
              : null
          }
          licenceNumber={id.slice(1)}
          licencestatus={determineStatusOverride(
            id,
            builderDetails && builderDetails["LICENCE_STATUS"]
              ? builderDetails["LICENCE_STATUS"]
              : null
          )}
          expiryDate={
            builderDetails && builderDetails["Expiry Date"]
              ? builderDetails["Expiry Date"]
              : null
          }
          lastUpdate={
            builderDetails && builderDetails["HCRA_LICENSERENEWEDON"]
              ? builderDetails["HCRA_LICENSERENEWEDON"]
              : null
          }
          initiallyLicensed={
            builderDetails && builderDetails["HCRA_INITIALLICENSEDATE"]
              ? builderDetails["HCRA_INITIALLICENSEDATE"]
              : null
          }
          activeRenewal={
            builderDetails && builderDetails["RENL_IN_PROCESS_FLAG"]
              ? builderDetails["RENL_IN_PROCESS_FLAG"]
              : null
          }
          removeLicenceDates={removeLicenceDates}
        >
          <Container>
            {!builderDetails && (
              <Alert>
                <p>Sorry, no builder matching that ID can be found.</p>
              </Alert>
            )}

            {builderDetails && (
              <TabSidebar activeTab={activeTab} clickEvt={setActiveTab}>
                {/* OVERVIEW */}
                <div id="overview" text="Overview" icon={<FaUserCircle />}>
                  <NOPheaders
                    id={id}
                    status={builderDetails["LICENCE_STATUS"]}
                    convictions={profileConvictions}
                  />
                  <AtAGlance
                    clickEvt={setActiveTab}
                    totalunits={
                      builderDetails["SUMM_TOTAL"]
                        ? builderDetails["SUMM_TOTAL"]
                        : 0
                    }
                    lowrise={
                      builderDetails["SUMM_FREEHOLD"]
                        ? builderDetails["SUMM_FREEHOLD"]
                        : 0
                    }
                    condoProjects={
                      builderDetails["SUMM_CONDO"]
                        ? builderDetails["SUMM_CONDO"]
                        : 0
                    }
                    notices={ordersNumber}
                    discipline={disciplineNumber}
                    orders={0}
                    convictions={profileConvictions.length}
                    chargeableConciliations={
                      builderDetails["SUMM_CC"] ? builderDetails["SUMM_CC"] : 0
                    }
                    totalClaims={
                      builderDetails["SUMM_TOTAL_CLAIMS"]
                        ? builderDetails["SUMM_TOTAL_CLAIMS"]
                        : 0
                    }
                    outstanding={builderDetails["Total Outstanding Amount"]}
                    breach={builderDetails.BREACH}
                    tab={builderDetails.TAB}
                  />

                  <div style={{ marginTop: `2rem` }}>
                    <h2>Overview</h2>
                    <div className="fieldset">
                      <Container>
                        <Row>
                          <Col md={6}>
                            <span className="bold">Address</span>
                            <p>
                              {builderDetails["ADDRESS"] ? (
                                <>
                                  {builderDetails["ADDRESS"]}
                                  <br />
                                  {builderDetails["CITY"]}
                                </>
                              ) : (
                                "---"
                              )}
                            </p>
                          </Col>

                          {builderDetails["WEBSITEURL"] &&
                            builderDetails["WEBSITEURL"] !== "NULL" &&
                            builderDetails["WEBSITEURL"] !== " " && (
                              <Col md={6}>
                                <span className="bold">Website</span>
                                <p>{builderDetails["WEBSITEURL"]}</p>
                              </Col>
                            )}

                          {builderDetails["EMAIL"] &&
                            builderDetails["EMAIL"] !== "NULL" && (
                              <Col md={6}>
                                <span className="bold">Email</span>
                                <p>{builderDetails["EMAIL"]}</p>
                              </Col>
                            )}

                          {builderDetails["TELEPHONE"] &&
                            builderDetails["TELEPHONE"] !== "NULL" && (
                              <Col md={6}>
                                <span className="bold">Phone Number</span>
                                <p>{builderDetails["TELEPHONE"]}</p>
                              </Col>
                            )}

                          {builderDetails["FAX"] &&
                            builderDetails["FAX"] !== "NULL" && (
                              <Col md={6}>
                                <span className="bold">Fax Number</span>
                                <p>{builderDetails["FAX"]}</p>
                              </Col>
                            )}
                        </Row>
                      </Container>
                    </div>
                  </div>

                  {profilePDOs && (
                    <div style={{ marginTop: `2rem` }}>
                      <h2>Principals, Directors &amp; Officers</h2>
                      <PDOlist pdoArr={profilePDOs.persons} />
                    </div>
                  )}

                  {id !== "B39871" && (
                    <div style={{ marginTop: `2rem` }}>
                      <h2>Licence Conditions</h2>
                      {profileConditions && profileConditions.length > 0 ? (
                        <ConditionsTable conditionsList={profileConditions} />
                      ) : (
                        <Alert>
                          There are no conditions on this vendor/builder's
                          licence.
                        </Alert>
                      )}
                    </div>
                  )}
                </div>

                <div
                  id="members"
                  text="Related Companies"
                  icon={<FaBriefcase />}
                  disabled={profileMembers.length === 0 ? true : false}
                >
                  <div>
                    {profileMembers.length > 0 && (
                      <Col md={12}>
                        {builderDetails["Umbrella"] &&
                          builderDetails["Umbrella"] !== "NULL" && (
                            <div>
                              <h2>Related Companies</h2>
                              <p>
                                Related companies are vendors or builders that
                                share common leadership (Principals, Directors
                                or Officers).
                                <br />
                                Information on related companies may be helpful
                                for consumers researching builders and vendors,
                                to give a full picture of the company’s
                                operations and history – including other
                                projects the leadership has been involved with,
                                their experience and track record of conduct.
                              </p>
                              {builderDetails["Umbrella"] && (
                                <Row>
                                  <Col md={12} style={{ marginBottom: `1rem` }}>
                                    <Link
                                      to={`/umbrella/${builderDetails["Umbrella ID"]}`}
                                      style={{
                                        fontSize: `1.2rem`,
                                        fontWeight: `bold`,
                                      }}
                                    >
                                      <FiExternalLink
                                        style={{
                                          verticalAlign: "center",
                                          marginRight: ".5rem",
                                          fontSize: `1.5rem`,
                                        }}
                                      />
                                      {builderDetails["Umbrella"]}
                                    </Link>
                                  </Col>

                                  {builderDetails.UmbrellaCity && (
                                    <Col md={6}>
                                      <span className="bold">Location</span>
                                      <p>{builderDetails.UmbrellaCity}</p>
                                    </Col>
                                  )}
                                </Row>
                              )}
                            </div>
                          )}
                        <MemberList members={profileMembers} />
                      </Col>
                    )}
                  </div>
                </div>

                <div id="properties" text="Properties" icon={<FaHome />}>
                  <h2>Properties</h2>
                  <PropertiesList />
                  <WarrantiesList />
                </div>

                <div
                  id="condoProjects"
                  text="Condo Projects"
                  icon={<FaBuilding />}
                  disabled={!profileCondos}
                >
                  <h2>Condo Projects</h2>
                  <p>
                    Information received from Tarion is updated daily and
                    available on the Ontario Builder Directly within 24 hours of
                    receipt. As of January 1st, 2023, details on the number of
                    condominium purchase agreements that a builder or vendor has
                    terminated, along with the reasons for the termination are
                    disclosed under the “# of Purchase Agreements Terminated by
                    Vendor” heading.
                  </p>
                  <p>
                    If you have any questions about the information shown below,
                    or can't find the project you are searching for, please
                    contact us.
                  </p>
                  <CondoProjectsList />
                </div>

                <div
                  id="notices"
                  text="Notices &amp; Orders"
                  icon={<FaFileAlt />}
                  badgeNum={ordersNumber}
                >
                  <h3>Notices &amp; Orders</h3>
                  <p>
                    Under the New Home Construction Licensing Act, 2017, the
                    HCRA may issue a Notice of Proposal to an applicant or
                    licensee with the intent to refuse to grant or renew a
                    licence, suspend or revoke a licence or apply conditions to
                    a licence.
                  </p>

                  <p>
                    The HCRA may also issue orders to licensees and
                    non-licensees requiring the named party to take or refrain
                    taking specified actions. Orders can include compliance
                    orders, freeze orders, advertising orders, administrative
                    penalty orders and others.
                  </p>

                  <NOPtable id={id} />
                </div>

                <div
                  id="discipline"
                  text="Disciplinary Activities"
                  icon={<FaBalanceScale />}
                  badgeNum={disciplineNumber}
                >
                  <h2>Discipline &amp; Appeals</h2>
                  {disciplineAllegations[id]?.length > 0 ||
                  disciplineDecisions[id]?.length > 0 ? (
                    <>
                      {disciplineAllegations[id]?.length > 0 && (
                        <>
                          <h3 className="thin sm">Allegations</h3>
                          <p>
                            If you are interested in attending any upcoming
                            virtual hearings, please contact{" "}
                            <a href="/">hearings@hcraontario.ca</a>.
                          </p>
                          <DisciplineAllegationsTable
                            disciplineAllegations={disciplineAllegations[id]}
                          />
                        </>
                      )}

                      <h3 style={{ marginTop: `2rem` }} className="thin sm">
                        Decisions
                      </h3>
                      {disciplineDecisions[id]?.length > 0 ? (
                        <DisciplineDecisionsTable
                          disiplineDecisions={disciplineDecisions[id]}
                        />
                      ) : (
                        <Alert>
                          This builder is currently facing allegations of
                          professional misconduct. Once the hearing is final and
                          a decision has been made it will appear below.
                        </Alert>
                      )}
                    </>
                  ) : (
                    <Alert type="success">
                      This builder has no discipline history with the HCRA.
                    </Alert>
                  )}
                </div>

                <div
                  id="charges"
                  text="Court Charges and/or Convictions"
                  icon={<FaGavel />}
                  badgeNum={profileConvictions.length}
                >
                  <div>
                    <h2>Court Charges and/or Convictions</h2>
                    <p>
                      The HCRA can lay charges under the New Home Construction
                      Licensing Act (NHCLA), the Ontario New Home Warranties
                      Plan Act (ONHWPA) or the Provincial Offences Act (POA).
                      The Charge column shows the specific offence (section)
                      under which a defendant was charged and convicted.
                    </p>

                    {profileConvictions.length === 0 ? (
                      <Alert type="success">
                        This builder has no charges or convictions.
                      </Alert>
                    ) : (
                      <ConvictionsList convictionsList={profileConvictions} />
                    )}
                  </div>
                  {profilePDOs && <PDOconvictionList />}
                </div>
              </TabSidebar>
            )}
          </Container>
        </ProfileLayout>
      </React.Suspense>
    );
  }
);

const mapStateToProps = (state) => ({
  errorMessage: state.builder.errorMessage,
  builderDetails: state.builder.profiledata,
  profilePDOs: state.builder.profilePDOs,
  profileConditions: state.builder.profileConditions,
  profileProperties: state.builder.profileproperties,
  profileEnrolments: state.builder.profileenrolments,
  profileMembers: state.builder.profileMembers,
  profileCondos: state.builder.profilecondos,
  profileConvictions: state.builder.profileconvictions,
  loading: state.builder.loading,
});

export default connect(mapStateToProps, { getBuilderInfo })(ProfilePage);
