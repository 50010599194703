import React from "react";
import { Container, Row, Col } from "react-grid-system";

// import Layout from "../components/Layouts/layout"
import PageLayout from "../layouts/MainLayout";
// import SEO from "../components/seo"

const OBDGlossaryPage = () => {
  var glossaryArr = [
    {
      id: "atFaultClaims",
      term: "At Fault Claims",
      definition:
        "Occurs when the builder or vendor has failed to resolve a homeowner claim that is covered under the warranties and Tarion has stepped in to make repairs or provide a cash settlement to resolve the claim with the homeowner. A single home may have more than one claim, and claims on the same home may occur in different years.",
    },
    {
      id: "breach",
      term: "Breach",
      definition:
        "Outstanding claims on cases where there was a warranty breach by the builder or vendor. ",
    },
    {
      id: "breachUncollect",
      term: "Breach Uncollectable",
      definition:
        "Outstanding claims on cases where there was a warranty breach by the builder or vendor.",
    },
    {
      id: "chargeableConciliations",
      term: "Chargeable Conciliations",
      definition:
        "Occurs when Tarion determines that one or more items reported by the homeowner is warranted under the Ontario New Home Warranties Plan Act and the vendor/builder failed to repair or resolve the item(s) during the applicable repair report and no exception to chargeability applies.",
    },
    {
      id: "condominium",
      term: "Condominium",
      definition:
        "A property in which the purchaser buys a residential dwelling unit within a condominium project containing multiple units and common elements (shared areas and/or amenities). Examples include a unit in high-rise, mid-rise or low-rise building, a unit in a row house or a fully detached home.",
    },
    {
      id: "condoProps",
      term: "Condo Properties",
      definition:
        "The number of condominium properties that have been enrolled with Tarion and are under warranty.",
    },
    {
      id: "doingBusinessName",
      term: "Doing Business As Name",
      definition:
        "Doing business as is the name of the business known to the public.",
    },
    {
      id: "enrolmentDate",
      term: "Enrolment Date",
      definition:
        "The date that the home was enrolled in the warranty program with Tarion.",
    },
    {
      id: "freehold",
      term: "Freehold",
      definition:
        "A property in which the purchaser agrees to buy both a parcel of land and a residential dwelling unit on that land. Examples include a single-family detached home, a semi-detached home, a unit in a row house or a unit in a duplex.",
    },
    {
      id: "freeholdProps",
      term: "Freehold Properties",
      definition:
        "The number of freehold properties that have been enrolled with Tarion and are under warranty.",
    },
    {
      id: "illegalBuildingConvictions",
      term: "Illegal Building Convictions",
      definition:
        "Convictions for building contrary to the provisions set out in the New Home Construction Licensing Act, 2017 and the Ontario New Home Warranties Plan Act.",
    },
    {
      id: "legalName",
      term: "Legal Name",
      definition:
        "Legal name is the registered company name that appears on legal documents.",
    },
    {
      id: "lot",
      term: "Lot #",
      definition:
        "A reference number assigned to a piece of land owned by an individual or entity. A home’s lot number can be found in the legal description of the home and is assigned by the municipality. ",
    },
    {
      id: "majorStructuralDefect",
      term: "Major Structural Defect",
      definition:
        "Any defect in work or materials, including a crack, distortion or displacement of a structural load-bearing element of the building. ",
    },
    {
      id: "noticeOfProposal",
      term: "Notice of Proposal",
      definition:
        "A procedure under the New Home Construction Licensing Act, 2017 (NHCLA) in which the HCRA notifies an applicant or licensee in writing of the intent to refuse to grant or renew a licence, suspend or revoke a licence, apply conditions to a licence to which the licensee has or has not provided their consent.",
    },
    {
      id: "ordersIssued",
      term: "Orders Issued",
      definition:
        "A direction requiring a licensee to do something or refrain from doing something. Under the New Home Construction Licensing Act, 2017, orders can relate to false advertising, freezing assets or trust funds or compliance with the Act.",
    },
    {
      id: "outstandingClaimsPaidOwing",
      term: "Outstanding Claims Paid Owing",
      definition:
        "The amount owing by the builder or vendor to Tarion as a result of warranty claim(s) submitted by a homeowner and investigated and/or resolved by Tarion that has been outstanding for greater than 30 days. This number includes Tarion Action on Behalf Uncollectable plus Breach Uncollectable. ",
    },
    {
      id: "ownerBuilder",
      term: "Owner-Builder",
      definition:
        "An individual who is building a new home for their own personal use on land they own. An owner-built home is not eligible for warranty coverage under the Ontario New Home Warranties Plan Act and will not have statutory warranty coverage under that Act.",
    },
    {
      id: "plan",
      term: "Plan #",
      definition:
        "A reference number assigned to each home by the municipality in which the home is located.",
    },
    {
      id: "registrarNoticesIssued",
      term: "Notices Issued",
      definition:
        "Under the New Home Construction Licensing Act, 2017, the HCRA may issue a Notice of Proposal to an applicant or licensee with the intent to refuse to grant or renew a licence, suspend or revoke a licence or apply conditions to a licence to which the licensee has or has not provided their consent. This metric signals the number of Notice of Proposals that have been issued and been sustained after appeal (if an appeal was made). ",
    },
    {
      id: "officerDirector",
      term: "Principal/Officer/Director",
      definition:
        "A member of the company’s operational team, named in the Initial Return / Notice of Change filed with the Ministry of Government and Consumer Services.",
    },
    {
      id: "renewalDueDate",
      term: "Renewal due date",
      definition:
        "The date that is 30 days before the expiry date of a licence. If a renewal application is submitted after the renewal due date, a late fee will be applied to the renewal application. All licences must be renewed by their renewal due date.",
    },
    {
      id: "statusUpdated",
      term: "Status Last Updated",
      definition: "The last date when the vendor/builder’s licence was valid.",
    },
    {
      id: "tab",
      term: "Tarion Action on Behalf (TAB) of Vendor/Builder",
      definition:
        "Outstanding amount owing for claim case(s) where Tarion is fulfilling the warranty obligation on behalf of the builder or vendor. This may also include amounts owing as a result of chargeable conciliation fees charged to the builder or vendor. ",
    },
    {
      id: "tabUncollectable",
      term: "Tarion Action on Behalf (TAB) Uncollectable",
      definition:
        "Outstanding amount owing for claim case(s) where Tarion is fulfilling the warranty obligation on behalf of the builder or vendor. This may also include amounts owing as a result of chargeable conciliation fees charged to the builder or vendor. ",
    },
    {
      id: "totalDollars",
      term: "Total Dollars Paid in Claims",
      definition:
        "Shows the total amount of money a vendor/builder has paid in claims of warranty violations. ",
    },
    {
      id: "totalPossessions",
      term: "Total Possessions",
      definition:
        "The total number of freehold and condominium properties occupied by a homeowner.",
    },
    {
      id: "umbrella",
      term: "Umbrella group",
      definition:
        "A group of builders/vendors sharing at least one common officer, director, principal, partner or franchise. The umbrella group itself is not a legal entity and does not have its own licence number, but each member company of the umbrella group is a separate legal entity and has its own licence number.",
    },
    {
      id: "warrantyStart",
      term: "Warranty Start",
      definition: "The date in which warranty coverage for the property begun.",
    },
    {
      id: "warrantyEnd",
      term: "Warranty End",
      definition: "The date in which warranty coverage for the property ends.",
    },
  ];

  const statusArr = [
    {
      id: "chg",
      term: "Charged",
      definition:
        "When a builder or vendor has been charged of a violation under the New Home Construction Licensing Act, 2017, and/or the Ontario New Home Warranties Plan Act.",
    },
    {
      id: "cnv",
      term: "Convicted",
      definition:
        "When a builder or vendor has been charged and convicted of a violation under the New Home Construction Licensing Act, 2017, and/or the Ontario New Home Warranties Plan Act.",
    },
    {
      id: "exp",
      term: "Expired",
      definition:
        "The builder or vendor is no longer licensed by the HCRA because it either cancelled its licence or did not renew its licence. An expired vendor/builder cannot legally build or sell new homes.",
    },
    {
      id: "conAppeal",
      term: "Licence Conditions Under Appeal",
      definition:
        "The HCRA Registrar’s proposal to apply conditions on a licence is under appeal by the applicant or licensee to the Licence Appeal Tribunal.",
    },
    {
      id: "licenced",
      term: "Licensed",
      definition:
        "The builder or vendor is currently licensed by the HCRA to build and/or sell new homes.",
    },
    {
      id: "licencedCon",
      term: "Licensed with Conditions",
      definition:
        "The builder or vendor is currently licensed by the HCRA to build and/or sell new homes, subject to conditions or restrictions. ",
    },
    {
      id: "nopCon",
      term: "Notice of Conditions Issued",
      definition:
        "The HCRA Registrar has proposed to apply conditions on a licence.",
    },
    {
      id: "nopRefuse",
      term: "Notice of Refusal Issued",
      definition:
        "The HCRA Registrar has proposed to refuse to renew a licence.",
    },
    {
      id: "nopRevoke",
      term: "Notice to Revoke Issued",
      definition: "The HCRA Registrar has proposed to revoke a licence.",
    },
    {
      id: "nopSuspend",
      term: "Notice to Suspend Issued",
      definition: "The HCRA Registrar has proposed to suspend a licence.",
    },
    {
      id: "refuseApp",
      term: "Refusal Under Appeal",
      definition:
        "The HCRA Registrar’s proposal to refuse the renewal of a licence is under appeal with the Licence Appeal Tribunal. The application is refused until the appeal is resolved.",
    },
    {
      id: "refused",
      term: "Refused",
      definition:
        "The most recent application for a licence has been rejected.",
    },
    {
      id: "revApp",
      term: "Revocation Under Appeal",
      definition:
        "The HCRA Registrar’s proposal to revoke the licence is under appeal by the licensee. The licence remains revoked until the appeal is resolved.",
    },
    {
      id: "revoked",
      term: "Revoked",
      definition:
        "The licence has been terminated because the builder or vendor no longer meets licensing requirements. The builder or vendor is prohibited from building or selling new homes.",
    },
    {
      id: "suspend",
      term: "Suspended",
      definition:
        "The licence has been suspended and the builder or vendor is temporarily prohibited from building or selling new homes.",
    },
    {
      id: "suspendApp",
      term: "Suspension Under Appeal",
      definition:
        "The HCRA Registrar’s proposal to suspend a licence is under appeal by the Licence Appeal Tribunal. The licence remains suspended until the appeal is resolved.",
    },
    {
      id: "unlicensed",
      term: "Unlicensed",
      definition:
        "A person or entity who is not currently licensed under the New Homes Construction Licensing Act, 2017.",
    },
    {
      id: "withdraw",
      term: "Withdrawn",
      definition:
        "The licensee has voluntarily withdrawn their application for a licence.",
    },
  ];

  return (
    <PageLayout
      pageHeader={
        <>
          <span className="bold">Glossary</span> of Terms
        </>
      }
    >
      <Container>
        <Row>
          <Col md={12}>
            <ul className="sitemapList">
              {glossaryArr.map((link, i) => (
                <li id={link.id} key={link.id} className="definition">
                  <span>{link.term}</span>
                  <span>{link.definition}</span>
                </li>
              ))}
            </ul>
          </Col>

          <Col style={{ marginTop: `2rem` }}>
            <h2 id="licenceStatus">Licence Status</h2>
            <ul className="sitemapList">
              {statusArr.map((link, i) => (
                <li id={link.id} key={link.id} className="definition">
                  <span>{link.term}</span>
                  <span>{link.definition}</span>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Container>
    </PageLayout>
  );
};
export default OBDGlossaryPage;
