import React from "react";
import Alert from "../Alert/Alert";
import {
  complianceOrders,
  determineComplianceHdrTxt,
  conditionsOrders,
  refuseOrders,
  determineAdminPenaltiesHdrTxt,
  determineConditionsHdrTxt,
  determineRefusedHdrTxt,
  determineRevokedHdrTxt,
  // determineAdvertisingHdrTxt,
  revokeOrders,
  suspensionOrders,
  determineSuspendedHdrTxt,
  // advertisingOrders,
  adminPenalties,
  disciplineAllegations,
  disciplineDecisions,
  insolvencyProceedings,
} from "../../utils/nopOverrides";

const NOPheaders = ({ id, status, convictions, ...props }) => {
  const convictionsUnderAppeal = convictions.find(
    (conviction) =>
      conviction["UNDER_APPEAL"] && conviction["UNDER_APPEAL"] !== "NULL"
  );

  return (
    <>
      {convictionsUnderAppeal && (
        <Alert type="warning" header="Proposed Convictions - Under Appeal">
          For more information, see the Court Charges and/or Convictions tab.
        </Alert>
      )}
      {adminPenalties[id] && (
        <Alert type="warning" header={determineAdminPenaltiesHdrTxt(id)}>
          For more information, see the Notices and Orders tab.
        </Alert>
      )}
      {suspensionOrders[id] && !suspensionOrders[id][0]?.hideBanner && (
        <Alert type="warning" header={determineSuspendedHdrTxt(id)}>
          For more information, see the Notices and Orders tab.
        </Alert>
      )}
      {revokeOrders[id] && (
        <Alert type="warning" header={determineRevokedHdrTxt(id)}>
          For more information, see the Notices and Orders tab.
        </Alert>
      )}
      {refuseOrders[id] && !refuseOrders[id][0].appealed && (
        <Alert type="warning" header={determineRefusedHdrTxt(id)}>
          For more information, see the Notices and Orders tab.
        </Alert>
      )}
      {conditionsOrders[id] && conditionsOrders[id][0]?.title && (
        <Alert type="warning" header={determineConditionsHdrTxt(id)}>
          For more information, see the Notices and Orders tab.
        </Alert>
      )}
      {/* TODO: re-enable once final signoff from licensing team */}
      {/* {status == "Licensed with Conditions" && !conditionsOrders[id] && ( */}

      {status === "Cancelled" && (
        <Alert type="warning" header="Licence has been Cancelled"></Alert>
      )}

      {complianceOrders[id] &&
        Array.isArray(complianceOrders[id]) &&
        !complianceOrders[id][0]?.inCompliance && (
          <Alert type="warning" header={determineComplianceHdrTxt(id)}>
            For more information, see the Notices and Orders tab.
          </Alert>
        )}
      {complianceOrders[id] &&
        !Array.isArray(complianceOrders[id]) &&
        Object.keys(complianceOrders[id]).length &&
        Object.keys(complianceOrders[id]).map((orderKey) => (
          <Alert
            type="warning"
            header={determineComplianceHdrTxt(id, orderKey)}
          >
            For more information, see the Notices and Orders tab.
          </Alert>
        ))}

      {disciplineAllegations[id] && (
        <Alert
          type="warning"
          header="Licensee has been referred to the Discipline Committee for allegedly breaching the Code of Ethics"
        >
          For more information, see the Disciplinary Activities tab.
        </Alert>
      )}

      {disciplineDecisions[id] && (
        <Alert
          type="warning"
          header="Licensee has Discipline Committee history"
        >
          For more information, see the Disciplinary Activities tab.
        </Alert>
      )}

      {insolvencyProceedings.includes(id) && (
        <Alert
          type="warning"
          header="Licensee is subject to insolvency proceedings"
        ></Alert>
      )}

      {/* {advertisingOrders[id] && (
        <Alert type="warning" header={determineAdvertisingHdrTxt(id)}>
          For more information, see the Notices and Orders tab.
        </Alert>
      )} */}
    </>
  );
};

export default NOPheaders;
