import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import componentStyles from './PageHeader.module.scss';

const PageHeader = ({
    subheader,
    header, 
    breadcrumb,
    ...props}) => {

    return(
        <header className={componentStyles.header}>
            <Container>
                <Row>
                    <Col>
                        <h1>
                            {subheader &&
                                <span className={componentStyles.subheader}>{subheader}</span>
                            }
                            {header}
                        </h1>
                    </Col>
                </Row>
            </Container>
        </header>
    )
}

export default PageHeader;