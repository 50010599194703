// SORT ASC
// params: arr, str(key used to sort)
export const sortAsc = (arr, key) =>
  arr.sort((a, b) => {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    else if (a[key] === null) return 1;
    else if (b[key] === null) return -1;
    else return 0;
  });

// SORT DESC
// params: arr, str(key used to sort)
export const sortDesc = (arr, key) =>
  arr.sort((a, b) => {
    if (a[key] < b[key]) return 1;
    else if (a[key] > b[key]) return -1;
    else if (a[key] === null) return 1;
    else if (b[key] === null) return -1;
    else return 0;
  });

// getPaginatedResults
// params: arr, active page #
// return: section of original array
export const getPaginatedResults = (arr, page) => {
  const numResults = 10; //TODO have this set in state?
  const first = (page - 1) * numResults;
  const last = first + numResults;
  return arr.slice(first, last);
};

// getAggregatedList
// param: arr of objs, key of value to be aggregated
// return: arr
export const getAggregatedList = (arr, key) => {
  return arr.reduce((acc, item) => {
    if (
      item[key] &&
      item[key] !== "" &&
      acc.indexOf(item[key].toLowerCase().trim()) === -1
    ) {
      const nextIndex = acc.findIndex((i) => item[key].toLowerCase() < i);
      const index = nextIndex > -1 ? nextIndex : acc.length;
      acc.splice(index, 0, item[key].toLowerCase());
    }
    return acc;
  }, []);
};

// checkNullFilter
// param: filter value (str)
// return: bool
export const checkNullFilter = (filter) => {
  if (
    filter === "null" ||
    filter === "" ||
    (Array.isArray(filter) && filter.length === 0)
  )
    return true;
  else return false;
};

// getFilters
// Params: search filter obj
// Returns: array of query values as array [key, value]
export const getFilters = (filterObj) => {
  let filters = [];
  const normalizedFilterKeys = {
    hometype: {
      filterLabel: "Home Type",
    },
    licensed: {
      filterLabel: "Licensed",
    },
    licenceState: {
      filterLabel: "Licence Status",
    },
    city: {
      filterLabel: "Builder Location",
    },
  };
  Object.keys(filterObj).forEach((filter) => {
    if (
      !filterObj[filter] ||
      filterObj[filter] === "" ||
      filterObj[filter] === "null" ||
      filterObj[filter] === []
    ) {
      return null;
    } else {
      const normalizedKey = normalizedFilterKeys[filter]
        ? normalizedFilterKeys[filter].filterLabel
        : filter;
      filters.push([normalizedKey, filterObj[filter]]);
    }
  });
  return filters;
};
