import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import{ FiExternalLink } from 'react-icons/fi';

const EnrolmentListRow = ({enrolmentData}) => {
    return(
        <tr>
            <td>
                <Moment parse="YYYY-MM-DD'T'HH:mm:ss*SSSZZZZ" format="MMMM D, YYYY">
                    {enrolmentData["Enrolment Date"]}
                </Moment>
            </td>
            <td>{enrolmentData["Address"]}</td>
            <td className="sentenceCase">{enrolmentData["City"].toLowerCase()}</td>
            <td>{enrolmentData["Lot"] ? enrolmentData["Lot"] : '---' }</td>
            <td>{enrolmentData["Plan"] ? enrolmentData["Plan"] : '---' }</td>
            <td>
                {enrolmentData[" Warranty Start"]
                ?
                <Moment parse="YYYY-MM-DD'T'HH:mm:ss*SSSZZZZ" format="MMMM D, YYYY">
                    {enrolmentData[" Warranty Start"]}
                </Moment>
                :
                <span>---</span>
                }
            </td>
            <td>
                {enrolmentData["Warranty End"]
                ?
                <Moment parse="YYYY-MM-DD'T'HH:mm:ss*SSSZZZZ" format="MMMM D, YYYY">
                    {enrolmentData["Warranty End"]}
                </Moment>
                :
                <span>---</span>
                }
            </td>
            <td>
                {enrolmentData["Home Type"]
                ?
                <span>{enrolmentData["Home Type"]}</span>
                :
                <span>---</span>
                }
            </td>
            <td>
                <Link 
                    key={enrolmentData["Account Number"]} 
                    to={`/profile/${enrolmentData["Account Number"]}`}
                    style={{fontWeight:`bold`}}>
                    <FiExternalLink style={{verticalAlign: 'center', marginRight: '.5rem', fontSize: `1.5rem`}}/> 
                    {enrolmentData["Builder/Vendor Name"]}
                </Link>
            </td>
        </tr>
    )
}

export default EnrolmentListRow;