import React from 'react';
import { Link } from 'react-router-dom';
import { determineLicenceClass, determineBoolClass, licenceBadge } from '../../utils/normalizesearchresults';
import{ FiExternalLink } from 'react-icons/fi';

const BuilderListTile = ({builderData}) => {
    const {
        type,
        id,
        idtype,

        name,
        operatingName, 
        city, 
        licenseState} = builderData;

    const isUmbrella = type==='umbrella' ? true : false;
    const regClass = isUmbrella ? null : determineLicenceClass(licenseState);
    const licenceBoolClass = isUmbrella ? null : determineBoolClass(licenseState);

    const profileLink = () => {
        if(isUmbrella) return `/umbrella/${id}`
        else if(licenseState==="Charged and/or Convicted" || licenseState==='CNV') {
            if(idtype==="PERSON_ID") return `/unlicensed/P${id}`
            else return `/unlicensed/${id}`
        }
        else return `/profile/${id}`
    }


    return(
        <div className="tile">
            <div>
                <Link 
                    key={id} 
                    to={ profileLink }
                    style={{fontSize:`1.2rem`, fontWeight:`bold`}}>
                    <FiExternalLink style={{verticalAlign: 'center', marginRight: '.5rem', fontSize: `1.5rem`}}/> 
                    {name}
                </Link>
            </div>

            <div>
                <span className="label">Doing Business As Name: </span>
                <span className="title">{operatingName ? operatingName : name}</span>
            </div>

            <div>
                <span className="label">Location: </span>
                <span className="sentenceCase">{city}</span>
            </div>

            {isUmbrella
            ?
            <div>
                <span>This is an umbrella company and therefore does not have a distinct registration number.</span>
            </div>
            :
            <>
                <div>
                    <span className="label">Licensed: </span>
                    <span className={licenceBoolClass}>
                        { licenceBadge(licenseState) }
                    </span>
                </div>
                
                <div>
                    <span className="label">Licence Status: </span>
                    <span className={regClass}>{ licenseState }</span>
                </div>
            </>}
        </div>
    )
}

export default BuilderListTile;