import React from 'react';
import componentStyles from './Modal.module.scss';
import { FaRegWindowClose } from 'react-icons/fa';

const Modal = ({closeEvt,...props}) => {
    return(
        <div className={componentStyles.modalVignette}>
            <div className={componentStyles.modal}>
                <button 
                    className={componentStyles.closeBtn}
                    onClick={closeEvt} 
                    aria-label="Close Modal">
                    <FaRegWindowClose />
                </button>
                <div className={componentStyles.container}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Modal;