import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import ClassNames from "classnames";
import componentStyles from "./ProfileHeader.module.scss";
import PageBackLink from "../PageBackLink/PageBackLink";
import ToolTip from "../ToolTip/ToolTip";
import { Container, Row, Col } from "react-grid-system";
import { FiExternalLink, FiAlertCircle } from "react-icons/fi";
import {
  determineLicenceClass,
  determineBoolClass,
  licenceBadge,
} from "../../utils/normalizesearchresults";

const ProfileHeader = ({
  builderName,
  operatingName,
  umbrella,
  umbrellaId,
  licenceNumber,
  expiryDate,
  licencestatus,
  lastUpdate,
  initiallyLicensed,
  activeRenewal,
  removeLicenceDates,
  ...props
}) => {
  const isUmbrellaGroup =
    licencestatus && licencestatus !== null ? false : true;
  const isConvict = false;
  const licenceStatusStr = licencestatus;
  const licenceStatusClass = determineLicenceClass(licenceStatusStr);
  const licenceBoolClass = determineBoolClass(licenceStatusStr);

  return (
    <div className={componentStyles.headerContainer}>
      <header style={{ width: `100%` }}>
        {/* <PageBackLink /> */}
        <Container>
          <Row>
            <Col lg={8} md={6}>
              <div className={ClassNames(componentStyles.nameContainer)}>
                {!isUmbrellaGroup && (
                  <span style={{ fontWeight: `bold` }}>
                    <ToolTip
                      link="/glossary/"
                      internal
                      definition="Legal name is the registered company name that appears on legal documents."
                    >
                      Vendor/Builder Legal Name
                    </ToolTip>
                  </span>
                )}
                <h1 className={componentStyles.name}>{builderName}</h1>

                {!isUmbrellaGroup && (
                  <>
                    <span style={{ fontWeight: `bold` }}>
                      <ToolTip
                        link="/glossary/"
                        internal
                        definition="Doing business as is the name of the business known to the public."
                      >
                        Doing Business As Name
                      </ToolTip>
                    </span>
                    <h2 className={componentStyles.name}>
                      {operatingName ? operatingName : builderName}
                    </h2>
                  </>
                )}
              </div>
            </Col>

            {!isUmbrellaGroup && (
              <Col lg={4} md={6}>
                <div style={{ padding: `1rem 0` }}>
                  <div className={componentStyles.summaryRow}>
                    <div className="bold">
                      <ToolTip
                        link="/glossary/#licensed"
                        internal
                        definition="The builder or vendor is currently licensed by the HCRA to build and/or sell new homes."
                      >
                        Licensed
                      </ToolTip>
                    </div>
                    <div className={licenceBoolClass}>
                      {licenceBadge(licenceStatusStr)}
                    </div>
                  </div>

                  <div className={componentStyles.summaryRow}>
                    <div className="bold">Licence Number</div>
                    <div>
                      {isConvict ||
                      !licenceNumber ||
                      licenceStatusStr == "Unlicensed"
                        ? "---"
                        : licenceNumber}
                    </div>
                  </div>

                  <div className={componentStyles.summaryRow}>
                    <div className="bold">
                      <ToolTip
                        link="/glossary/#licenceStatus"
                        internal
                        definition=""
                      >
                        Licence Status
                      </ToolTip>
                    </div>
                    <div className={licenceStatusClass}>{licenceStatusStr}</div>
                  </div>

                  {licenceBoolClass === "licensed bold" && (
                    <div
                      className={ClassNames(
                        componentStyles.summaryRow,
                        componentStyles.inset
                      )}
                    >
                      <div className="bold">Licence Expiry Date</div>
                      <div>
                        <Moment
                          parse="YYYY-MM-DD'T'HH:mm:ss*SSSZZZZ"
                          format="MMMM D, YYYY"
                        >
                          {expiryDate}
                        </Moment>
                      </div>
                    </div>
                  )}

                  {licenceBoolClass !== "licensed bold" && (
                    <div
                      className={ClassNames(
                        componentStyles.summaryRow,
                        componentStyles.inset
                      )}
                    >
                      <div className="bold">
                        <ToolTip
                          link="/glossary/#statusUpdate"
                          internal
                          definition="The last date when the vendor/builder’s licence was valid."
                        >
                          Status Last Updated
                        </ToolTip>
                      </div>
                      <div>
                        {lastUpdate &&
                        lastUpdate !== "N/A" &&
                        lastUpdate !== "" &&
                        !removeLicenceDates &&
                        new Date(lastUpdate).getFullYear() < 3000 ? (
                          <Moment
                            parse="YYYY-MM-DD'T'HH:mm:ss*SSSZZZZ"
                            format="MMMM D, YYYY"
                          >
                            {lastUpdate}
                          </Moment>
                        ) : (
                          "---"
                        )}
                      </div>
                    </div>
                  )}

                  <div
                    className={ClassNames(
                      componentStyles.summaryRow,
                      componentStyles.inset
                    )}
                  >
                    <div className="bold">Initially Licensed</div>
                    <div>
                      {initiallyLicensed &&
                      initiallyLicensed !== "N/A" &&
                      initiallyLicensed !== "" &&
                      !removeLicenceDates &&
                      new Date(initiallyLicensed).getFullYear() < 3000 ? (
                        <Moment format="MMMM D, YYYY">
                          {initiallyLicensed}
                        </Moment>
                      ) : (
                        "---"
                      )}
                    </div>
                  </div>

                  {activeRenewal === "Y" &&
                    licenceStatusStr !== "Revoked" &&
                    licenceStatusStr !== "Suspended" && (
                      <div
                        className={ClassNames(
                          componentStyles.summaryRow,
                          componentStyles.inset
                        )}
                        style={{ justifyContent: `flex-start` }}
                      >
                        <FiAlertCircle
                          style={{ fontSize: `1.25rem`, marginRight: `.25rem` }}
                        />
                        <span
                          style={{ fontStyle: `italic`, marginTop: `.25rem` }}
                        >
                          Renewal application under review. Licence remains
                          valid.
                        </span>
                      </div>
                    )}
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </header>
    </div>
  );
};

export default ProfileHeader;
