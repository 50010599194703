import React from "react";
import { useMediaQuery } from "react-responsive";
import { FaFilePdf } from "react-icons/fa";

const DisciplineTable = ({
  disciplineAllegations,
  disiplineDecisions,
  ...props
}) => {
  const isMobile = useMediaQuery({ maxWidth: 990 });

  return (
    <div className="overflowContainer">
      {!isMobile ? (
        <>
          <table>
            <thead>
              <tr>
                <th>Referral Date</th>
                <th>Licensee</th>
                <th>Notice of Hearing &amp; Allegations</th>
                <th>Next Steps</th>
              </tr>
            </thead>

            <tbody>
              {disciplineAllegations.map((row, i) => (
                <tr>
                  <td>{row.referralDate}</td>
                  <td>{row.licensee}</td>
                  <td>
                    <a
                      href={require(`../../docs/${row.doc}`)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFilePdf
                        style={{
                          fontSize: `1.5rem`,
                          marginRight: `.5rem`,
                        }}
                      />
                      <span>Allegations Doc</span>
                    </a>
                  </td>

                  <td>
                    {row.nextSteps ? (
                      <span>{row.nextSteps}</span>
                    ) : (
                      <span>---</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <>
          {disciplineAllegations.map((row) => (
            <div className="tile">
              <div>
                <span className="label">Referral Date: </span>
                <span>{row.referralDate}</span>
              </div>

              <div>
                <span className="label">Licensee: </span>
                <span>{row.licensee}</span>
              </div>

              <div>
                <span className="label">
                  Notice of Hearing &amp; Allegations:{" "}
                </span>
                <span>
                  <a
                    href={require(`../../docs/${row.doc}`)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFilePdf
                      style={{
                        fontSize: `1.5rem`,
                        marginRight: `.5rem`,
                      }}
                    />
                    <span>Allegations Doc</span>
                  </a>
                </span>
              </div>

              <div>
                <span className="label">Next Steps: </span>
                <span>
                  {row.nextSteps ? (
                    <span>{row.nextSteps}</span>
                  ) : (
                    <span>---</span>
                  )}
                </span>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default DisciplineTable;
