import React from "react";
import PageLayout from "../layouts/MainLayout";
import { Container, Row, Col } from "react-grid-system";
import Alert from "../components/Alert/Alert";
import BuilderSearchForm from "../components/SearchForms/BuilderSearchForm";
import EnrolmentSearchForm from "../components/SearchForms/EnrolmentSearchForm";

const SearchPage = () => {
  return (
    <PageLayout
      home={true}
      pageHeader={
        <>
          <span className="bold">Ontario</span> Builder Directory
        </>
      }
    >
      <p>
        In Ontario, a new home builder or vendor must be licensed by the{" "}
        <a href="http://www.hcraontario.ca">
          {" "}
          Home Construction Regulatory Authority (HCRA)
        </a>
        .
        <br />
        Hosted by the HCRA, the Ontario Builder Directory provides information
        such as a builder’s licensing status, the number of years a builder has
        been active, the number of homes they have built, and conduct concerns
        including any charges and/or convictions against the builder.
        <br />
        If you do not see your builder listed or they appear not to be licensed,
        please contact the HCRA.
        <br />
        Home buyers who are interested in buying a new home that is already
        built may also search the Builder Directory to confirm the home’s
        warranty.
        <br />
        You can begin your search right away if you know the name of the builder
        you are searching, or you can search by location.
      </p>

      <Container>
        <Row align="stretch">
          <Col md={12} lg={6} style={{ marginBottom: `2rem` }}>
            <h2>Search for a Builder</h2>
            <BuilderSearchForm />
          </Col>

          <Col md={12} lg={6}>
            <h2>Confirm Your Home's Warranty</h2>
            <EnrolmentSearchForm />
            <span className="footnote">
              *All warranty information is updated with Tarion data that is
              shared with the HCRA for posting to a builder’s profile.
            </span>
          </Col>
        </Row>
      </Container>
    </PageLayout>
  );
};

export default SearchPage;
