import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getEnrolments,
  orderEnrolmentSearch,
  setSearchPage,
} from "../actions/builderActions";
import { getQueryParams } from "../utils/queries";
import {
  checkNullFilter,
  getAggregatedList,
  getFilters,
  getPaginatedResults,
} from "../utils/sortFunctions";
import { normalizeAmtStr } from "../utils/numNormalizeFunctions";

import Button from "../components/Button/Button";
import Modal from "../components/Modal/Modal";
import PageLayout from "../layouts/MainLayout";
import Alert from "../components/Alert/Alert";
import PillSortButton from "../components/PillInput/PillSortButton";
import EnrolmentFilterControls from "../components/EnrolmentFilterControls/EnrolmentFilterControls";
import EnrolmentList from "../components/EnrolmentList/EnrolmentList";
import FilterList from "../components/FilterList/FilterList";
import PaginationNav from "../components/PaginationNav/PaginationNav";
import SortControls from "../components/SortControls/SortControls";
import { FaFilter } from "react-icons/fa";

const EnrolmentSearchResultsPage = ({
  getEnrolments,
  orderEnrolmentSearch,
  setSearchPage,

  loading,
  errorMessage,
  enrolments,
  searchPagination,
  searchFilters,

  searchOrderBy,
  searchOrderDirection,
  ...props
}) => {
  // store modal state in component state
  const [modalOpen, toggleModal] = useState(false);

  // display search query parameters
  const query = new URLSearchParams(useLocation().search);
  const queryParams = getQueryParams(query);
  const filtersList = getFilters(searchFilters);
  const searchTerms = {
    enrolAddress: query.get("enrolAddress"),
    enrolCity: query.get("enrolCity"),
    lot: query.get("lot"),
    plan: query.get("plan"),
  };

  // filter by searchFilters[licensed, licenseState, city ]
  const filteredEnrolmentList = enrolments.filter(
    (enrolment) =>
      (searchFilters.city.indexOf(enrolment["City"].toLowerCase()) > -1 ||
        checkNullFilter(searchFilters.city)) &&
      (enrolment["Home Type"].toLowerCase() === searchFilters.hometype ||
        checkNullFilter(searchFilters.hometype))
  );

  const setListOrder = (filterId) => {
    if (filterId === searchOrderBy && searchOrderDirection === "asc")
      orderEnrolmentSearch({ filter: filterId, order: "desc" });
    else if (filterId === searchOrderDirection)
      orderEnrolmentSearch({ filter: filterId, order: "asc" });
    else orderEnrolmentSearch({ filter: filterId, order: "asc" });
  };

  const setPagination = (pageNum) => setSearchPage(pageNum);

  const cities = getAggregatedList(enrolments, "City");
  const homeTypes = getAggregatedList(enrolments, "Home Type");

  useEffect(() => {
    getEnrolments(searchTerms);
  }, [getEnrolments]);

  return (
    <PageLayout
      pageHeader="Warranty Search Results"
      loading={loading}
      error={errorMessage}
      backLinkUrl="/"
      backLinkText="Back to Search"
    >
      <p>
        Thank you for your search. To view a builder’s full profile - including
        details related to their regulatory compliance and warranty information
        – please click on the name of the vendor/builder in the search results
        below. HCRA updates licensing and compliance information immediately if
        there is any change.
        <br />
        All warranty information is updated quarterly with Tarion data that is
        shared with HCRA for posting to a builder’s profile. If you cannot find
        the builder you are searching for, please use the directory’s advanced
        search.
      </p>
      <span className="bold">Results:</span>{" "}
      <span>{`${
        enrolments[0] ? normalizeAmtStr(filteredEnrolmentList.length) : 0
      } warranties found`}</span>
      <br />
      <span className="bold">Search Parameters:</span>
      <br />
      {queryParams.length > 0 ? (
        <ul>
          {queryParams.map((query, i) => (
            <li
              key={`${query[0].replace(/\s/g, "")}_${i}`}
            >{`${query[0]}: ${query[1]}`}</li>
          ))}
        </ul>
      ) : (
        <p>No search parameters provided.</p>
      )}
      {Object.keys(searchFilters).filter(
        (filter) => !checkNullFilter(searchFilters[filter])
      ).length > 0 && <FilterList filtersList={filtersList} />}
      {modalOpen && (
        <Modal closeEvt={() => toggleModal(!modalOpen)}>
          <EnrolmentFilterControls />
        </Modal>
      )}
      <div className="flexContainer mobileCol justifyBetween alignEnd">
        <SortControls>
          <PillSortButton
            id="date"
            label="Enrolment Date"
            active={searchOrderBy === "Enrolment Date"}
            order={searchOrderDirection}
            clickEvt={() => setListOrder("Enrolment Date")}
          />

          <PillSortButton
            id="city"
            label="City"
            active={searchOrderBy === "City"}
            order={searchOrderDirection}
            clickEvt={() => setListOrder("City")}
          />
        </SortControls>

        <Button
          id="filterBtn"
          icon={<FaFilter />}
          size="sm"
          disabled={cities.length < 2 && homeTypes.length < 2}
          clickEvt={() => toggleModal(!modalOpen)}
          text="Filter Results"
        />
      </div>
      {filteredEnrolmentList.length <= 0 ? (
        <Alert>
          <p style={{ fontStyle: `italic` }}>
            No warranties matching those search terms can be found.
          </p>
        </Alert>
      ) : (
        <EnrolmentList
          enrolments={getPaginatedResults(
            filteredEnrolmentList,
            searchPagination
          )}
        />
      )}
      {filteredEnrolmentList.length > 10 && (
        <PaginationNav
          currentPage={searchPagination}
          clickEvt={setPagination}
          resultsLength={filteredEnrolmentList.length}
        />
      )}
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  loading: state.builder.loading,
  errorMessage: state.builder.errorMessage,

  enrolments: state.builder.enrolments,
  searchPagination: state.builder.searchPagination,
  searchFilters: state.builder.searchFilters,
  searchOrderBy: state.builder.searchOrderBy,
  searchOrderDirection: state.builder.searchOrderDirection,
});

export default connect(mapStateToProps, {
  getEnrolments,
  orderEnrolmentSearch,
  setSearchPage,
})(EnrolmentSearchResultsPage);
