import React from "react"
import { Container, Row, Col } from "react-grid-system";
import PageLayout from '../layouts/MainLayout';

const TermsOfUsePage = () => {

  return(
    <PageLayout pageHeader={<>Terms of Use for the HCRA Website</>}>
        <Container>
            <Row>
                <Col md={12}>
                    <p>
                    <b>1. Access and Use</b>
                    <br/>
                    1.1 By accessing and using the Home Construction Regulatory Authority (“HCRA”) website, you agree to be bound by the terms and conditions outlined in this Terms of Use for the HCRA Website (the “Terms”). If you do not want to be bound by the Terms, you should not access or use the website. 
                    <br/><br/>
                    1.2 If you are dissatisfied with the Terms or the website, your only remedy is to immediately discontinue use of the website.
                    <br/><br/>
                    1.3 By accessing and using the HCRA website, you agree to use it in a lawful manner, consistent with all relevant provincial, federal, and international laws and regulations.
                    <br/><br/>
                    1.4 HCRA may change the Terms at any time. Changes to the Terms become effective when they are posted on the website. If any change is unacceptable to you, you should not access or use the website. By continuing to access and use the website, you accept and agree to be bound by the changes.
                    </p>

                    <p>
                    <b>2. Accuracy and Security</b>
                    <br/>
                    2.1 HCRA website is provided on an “as is” basis. Although HCRA makes every effort to ensure that all information posted on the website is accurate and complete, HCRA does not make any representations or warranties that the information is free from error.
                    <br/><br/>
                    2.2 HCRA shall not be liable, whether in negligence, defamation or otherwise, for the accuracy or completeness of the information on the website including without limitation, for any losses arising directly or indirectly from any inaccuracy or lack of completeness of the information on the website.
                    <br/><br/>
                    2.3 HCRA may change any part of the website at any time. This includes, but is not limited to, changes to the website’s content, available features, and restrictions. HCRA reserves the right to correct any errors or omissions on the website, in its sole discretion.
                    <br/><br/>
                    2.4 HCRA also makes every effort to ensure that the website is secure and free from viruses and other harmful agents. However, it does not represent or warrant that the website is secure. By accessing and using the website, you acknowledge and agree that you are doing so at your own risk.
                    <br/><br/>
                    2.5 HCRA strives to protect your information when you use HCRA’s Online Application Portal. However, HCRA makes no representations or warranties regarding the security or confidentiality of information sent through the Online Application Portal. By accessing and using the Online Application Portal, you acknowledge and agree that you are doing so at your own risk.
                    </p>

                    <p>
                    <b>3. Linking</b>
                    <br/>
                    3.1 Certain links on the HCRA website may take you to other external (that is, non-HCRA) websites or sources. HCRA provides these links only as a convenience. It does not endorse and is not responsible for the contents of any linked website or source. HCRA does not endorse and is not responsible for the contents of any website or source that links to the HCRA website.
                    </p>

                    <p>
                    <b>4. Use of Cookies</b> 
                    <br/>
                    4.1 Cookies are small files sent from the website to a user’s device. The HCRA website uses cookies to authenticate users for the purpose of the online application portal and to analyse aggregate data regarding the use of HCRA’s website. By accessing and using the website, you acknowledge that you are aware of and agree to the website’s use of cookies.
                    </p>

                    <p>
                    <b>5. Usernames and Passwords</b>
                    <br/>
                    5.1 Some features of the HCRA website may be accessed by use of a username and password. You are solely responsible and liable for any use and misuse of your username and password and for all activities that occur under your username and password. You must keep your username and password confidential other than from the HCRA.
                    <br/><br/>
                    5.2 All usernames and passwords are the property of HCRA, and may be cancelled or suspended at any time by HCRA without any notice or liability to you or any other person.
                    <br/><br/>
                    5.3 You must immediately notify HCRA of any unauthorized use of your username or password, or if you know or suspect that your username or password has been lost or stolen, has become known to any other person, or has been otherwise compromised.
                    </p>

                    <p>
                    <b>6. Online Application Portal</b>
                    <br/>
                    6.1 HCRA’s Online Application Portal allows users to complete and submit online applications for licensing as new home builders and vendors in accordance with the New Home Construction Licensing Act, 2017. By accessing and using the Online Application Portal, you agree that:
                    <ol>
                        <li>You are the applicant, or you are authorized by the applicant to bind the applicant and to provide information about the applicant to the HCRA according to all the terms below.</li>
                        <li>You understand that it is a serious offence to provide false information to the HCRA.</li>
                        <li>You further understand that providing false, incomplete, or misleading information, or omitting information in this application for licence or the documents submitted with it, may result in the refusal, suspension or revocation of a licence.</li>
                        <li>You confirm that you have not misrepresented or omitted any material facts in any document or statement made in support of this application for licence. You understand that each statement is subject to verification.</li>
                        <li>You acknowledge that you must notify HCRA in writing within five days of any changes to the information provided with an application for licence that occur after the application is submitted.</li>
                        <li>
                        You acknowledge that it is a serious offence to do the following without a licence:
                        <ul>
                            <li>Act or hold oneself out as a vendor, offer to sell or transfer a new home, including as prescribed, or sell or transfer a new home, including as prescribed.</li>
                            <li>Act or hold oneself out as a builder, offer to construct a new home or construct a new home.</li>
                        </ul>
                        </li>
                    </ol>
                    </p>

                    <p>
                    <b>7. Notice and Consent to Use, Collection and Disclosure of Information</b>
                    <br/>
                    7.1 By accessing and using the Online Application Portal, you consent to HCRA making inquiries and collecting, using and disclosing information about me as determined by HCRA to be necessary in its sole discretion for the  purpose of  determining the Applicant's entitlement to renewal of a license, including obtaining credit reports and police record checks and other information with respect to the individuals listed on this application form, the Applicant, and the Applicant’s interested persons.
                    <br/><br/>
                    7.2 HCRA is collecting and using the information in an application pursuant to the New Home Construction Licensing Act, 2017, including its regulations. HCRA is gathering the information to determine the Applicant’s entitlement to a licence and to assist HCRA in administering the Act.
                    <br/><br/>
                    7.3 To complete or verify the information, it may be necessary for HCRA to request and receive additional information from other sources, including Tarion Warranty Corporation, provincial or municipal government departments and agencies, licensing and regulatory bodies, and law enforcement agencies. Information is shared between HCRA and Tarion in accordance with the New Home Construction Licensing Act, 2017. Any information collected as part of an application for licence may be shared with Tarion to support both HCRA and Tarion in carrying out their respective statutory mandates.
                    <br/><br/>
                    7.4 HCRA will collect, use and disclose the information in accordance with its Access to Information and Privacy Policy and the New Home Construction Licensing Act, 2017, which requires HCRA to keep confidential information it obtains in administering the New Home Construction Licensing Act, 2017, unless a specific exception in the Act applies.
                    </p>

                    <p>
                    <b>8. Ontario Builder Directory</b>
                    <br/>
                    8.1 The Ontario Builder Directory (the “OBD”) contains important information about new home builders and vendors in Ontario. HCRA provides public access to the OBD on its website for the purpose of public protection.
                    <br/><br/> 
                    8.2 Although HCRA makes every effort to ensure that all information in the OBD is up-to-date, accurate and complete, HCRA does not make any representations or warranties that the information is free from error. HCRA shall not be liable for the accuracy or completeness of the information on the OBD including without limitation, for any losses arising directly or indirectly from any inaccuracy or lack of completeness of the information on the OBD.
                    <br/><br/>
                    "8.3 Because HCRA relies on licensees and others to provide HCRA with some of the information contained in the OBD, there may be some delay between when an event occurs and when the information is available in the OBD. If you have a question about the contents of the OBD, please contact info@hcraonatio.ca.
                    <br/><br/>
                    8.4 The OBD is intended for private, non-commercial use. Any commercial use of the information in whole or in part, directly or indirectly, is specifically forbidden.
                    </p>

                    <p>
                    <b>9. Privacy and Information Sharing</b>
                    <br/>
                    9.1 HCRA is the regulatory authority for new home builders and vendors and Tarion Warranty Corporation is the warranty authority. If you share information with HCRA, you acknowledge that HCRA may need to disclose your information to Tarion or to the Minister of Government and Consumer Services or to any other person or organization as authorized by the New Home Construction Licensing Act, 2017 and in accordance with HCRA’s Access to Information and Privacy Policy. If you have any questions relating to the collection and disclosure of information, please contact info@hcraontario.ca. HCRA’s Access to Information and Privacy Policy is Schedule I to the Administrative Agreement made between the Government of Ontario and HCRA, viewable here:
                    <br/>
                    <a href="https://www.hcraontario.ca/Administrative%20Agreement%20Jan%2029%202021.pdf">
                        Administrative Agreement
                    </a>
                    </p>
                    
                    <p>
                    <b>10. Copyright and Reproduction</b>
                    <br/>
                    10.1 Copyright © 2020 Home Construction Regulatory Authority. The contents of the HCRA website may only be reproduced if the following conditions are met:
                    <ol>
                        <li>It is used for personal or educational purposes;</li>
                        <li>The reproduction is not modified;</li>
                        <li>The reproduction is not represented as being made in affiliation with or endorsed by HCRA;</li>
                        <li>The reproduction is not represented as an official version; and </li>
                        <li>The HCRA website is clearly identified as the source of the information.</li>
                    </ol>

                    <br/>

                    10.2 Without limitation to Paragraph 10.1 above, you may not:
                    <ol>
                        <li>Sell material from this site or reproduce it for sale.</li>
                        <li>Use this site in a way that could damage it or linked sites, or change the material displayed on it, or linked sites, including attempting to interfere with site security or gain unauthorized access to the restricted areas of the site.</li>
                        <li>Use or attempt to use another persons’ username, password or account. without prior written authorization from HCRA.</li>
                    </ol>
                    <br/>
                    10.3 Commercial or for-profit reproduction of the content of the website, in whole or in part, is not permitted except with the written consent of HCRA. For permission to reproduce the content of the website, please contact info@hcraonatio.ca.
                    <br/><br/>
                    10.4 The HCRA logo is the exclusive property of HCRA and may not be copied, imitated or used in whole or in part without prior written consent from HCRA.
                    </p>

                    <p>
                    <b>11. Applicable Law</b>
                    <br/>
                    11.1 The Terms are governed by the applicable laws of Ontario and Canada. By accessing and using the HCRA website, you agree to submit to the jurisdiction of the courts of Ontario with respect to all matters relating to your access to and use of the website.
                    <br/><br/>
                    11.2 If any portion of the Terms is deemed to be invalid or unenforceable, that portion shall be deemed severed. The remaining portions of the Terms shall remain in full force and effect.
                    </p> 

                    <p>
                    <b>12. Limitation of Liability</b>
                    <br/>
                    12.1 In no circumstances shall HCRA, its directors, employees or agents be liable for any loss or damages whatsoever arising from the use of the website, inability to access or use the website, reliance on the content of the website or reliance on any information linked to or from the website. This includes, but is not limited to, any direct, indirect, incidental, special, punitive, or consequential damages.
                    </p>

                </Col>
            </Row>
        </Container>
    </PageLayout>
  )

}
export default TermsOfUsePage