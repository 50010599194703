import React from "react";

const FilterList = ({ filtersList, ...props }) => {
  return (
    <>
      <span className="bold">Filters applied:</span>
      <br />
      <ul>
        {filtersList.map((filter, i) => {
          if (Array.isArray(filter[1]) && filter[1].length > 0) {
            return (
              <li
                className="sentenceCase"
                key={`${filter[0].replace(/\s/g, "")}_${i}`}
              >{`${filter[0]}: ${filter[1].join(", ")}`}</li>
            );
          } else if (
            !Array.isArray(filter[1]) &&
            filter[1] &&
            filter[1] !== ""
          ) {
            return (
              <li
                className="sentenceCase"
                key={`${filter[0].replace(/\s/g, "")}_${i}`}
              >{`${filter[0]}: ${filter[1]}`}</li>
            );
          }
        })}
      </ul>
    </>
  );
};

export default FilterList;
