import React from "react";
import Alert from "../Alert/Alert";
import {
  complianceOrders,
  conditionsOrders,
  refuseOrders,
  revokeOrders,
  suspensionOrders,
  advertisingOrders,
  adminPenalties,
} from "../../utils/nopOverrides";
import { FaFilePdf } from "react-icons/fa";

const NOPtable = ({ id, ...props }) => {
  return (
    <>
      {!complianceOrders[id] &&
        !conditionsOrders[id] &&
        !revokeOrders[id] &&
        !refuseOrders[id] &&
        !suspensionOrders[id] &&
        !advertisingOrders[id] &&
        !adminPenalties[id] && (
          <Alert type="success">
            This builder has not been issued any registrar notices or orders.
          </Alert>
        )}

      {adminPenalties[id] && (
        <>
          <h4>Adminstrative Penalty</h4>
          {adminPenalties[id][0].title && (
            <table style={{ marginBottom: `2rem` }}>
              <thead>
                <tr>
                  <th>Details</th>
                  <th>Date Issued</th>
                </tr>
              </thead>
              <tbody>
                {adminPenalties[id].map((order, i) => (
                  <tr key={`${id}_${i}`}>
                    <td>
                      <a
                        href={require(`../../docs/${order.doc}`)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFilePdf
                          style={{
                            fontSize: `1.5rem`,
                            marginRight: `.5rem`,
                          }}
                        />
                        <span>{order.title}</span>
                      </a>
                    </td>
                    <td>{order.date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}

      {revokeOrders[id] && (
        <>
          <h4>Notice to Revoke</h4>
          <table style={{ marginBottom: `2rem` }}>
            <thead>
              <tr>
                <th>Details</th>
                <th>Date Issued</th>
              </tr>
            </thead>
            <tbody>
              {revokeOrders[id].map((order, i) => (
                <tr key={`${id}_${i}`}>
                  <td>
                    <a
                      href={require(`../../docs/${order.doc}`)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFilePdf
                        style={{
                          fontSize: `1.5rem`,
                          marginRight: `.5rem`,
                        }}
                      />
                      <span>{order.title}</span>
                    </a>
                  </td>
                  <td>{order.date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      {refuseOrders[id] && (
        <>
          <h4>Notice to Refuse</h4>
          <table style={{ marginBottom: `2rem` }}>
            <thead>
              <tr>
                <th>Details</th>
                <th>Date Issued</th>
              </tr>
            </thead>
            <tbody>
              {refuseOrders[id].map((order, i) => (
                <tr key={`${id}_${i}`}>
                  <td>
                    <a
                      href={require(`../../docs/${order.doc}`)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFilePdf
                        style={{
                          fontSize: `1.5rem`,
                          marginRight: `.5rem`,
                        }}
                      />
                      <span>{order.title}</span>
                    </a>
                  </td>
                  <td>{order.date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      {conditionsOrders[id] && (
        <>
          <h4>Notice of Conditions</h4>
          {conditionsOrders[id][0].title && (
            <table style={{ marginBottom: `2rem` }}>
              <thead>
                <tr>
                  <th>Details</th>
                  <th>Date Issued</th>
                </tr>
              </thead>
              <tbody>
                {conditionsOrders[id].map((order, i) => (
                  <tr key={`${id}_${i}`}>
                    <td>
                      <a
                        href={require(`../../docs/${order.doc}`)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFilePdf
                          style={{
                            fontSize: `1.5rem`,
                            marginRight: `.5rem`,
                          }}
                        />
                        <span>{order.title}</span>
                      </a>
                    </td>
                    <td>{order.date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {conditionsOrders[id][0].complianceNote && (
            <Alert type="success">
              {conditionsOrders[id][0].complianceNote}
            </Alert>
          )}
        </>
      )}

      {complianceOrders[id] && Array.isArray(complianceOrders[id]) && (
        <>
          <h4>Compliance Order</h4>
          <table style={{ marginBottom: `2rem` }}>
            <thead>
              <tr>
                <th>Details</th>
                <th>Date Issued</th>
              </tr>
            </thead>
            <tbody>
              {complianceOrders[id].map((order, i) => (
                <tr key={`${id}_${i}`}>
                  <td>
                    <a
                      href={require(`../../docs/${order.doc}`)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFilePdf
                        style={{
                          fontSize: `1.5rem`,
                          marginRight: `.5rem`,
                        }}
                      />
                      <span>{order.title}</span>
                    </a>
                  </td>
                  <td>{order.date}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {complianceOrders[id][0].inCompliance && (
            <Alert type="success">
              NOTE: This vendor and/or builder has since come into compliance.
            </Alert>
          )}
        </>
      )}

      {complianceOrders[id] &&
        !Array.isArray(complianceOrders[id]) &&
        Object.keys(complianceOrders[id])?.length && (
          <>
            <h4>Compliance Order</h4>
            <table style={{ marginBottom: `2rem` }}>
              <thead>
                <tr>
                  <th>Details</th>
                  <th>Date Issued</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(complianceOrders[id]).map((orderKey) =>
                  complianceOrders[id][orderKey].map((order, i) => (
                    <tr key={`${id}_${i}`}>
                      <td>
                        <a
                          href={require(`../../docs/${order.doc}`)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaFilePdf
                            style={{
                              fontSize: `1.5rem`,
                              marginRight: `.5rem`,
                            }}
                          />
                          <span>{order.title}</span>
                        </a>
                      </td>
                      <td>{order.date}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>

            {/* {complianceOrders[id][orderKey].inCompliance && (
              <Alert type="success">
                NOTE: This vendor and/or builder has since come into compliance.
              </Alert>
            )} */}
          </>
        )}

      {advertisingOrders[id] && (
        <>
          <h4>Advertising Order</h4>
          {advertisingOrders[id][0].title && (
            <table style={{ marginBottom: `2rem` }}>
              <thead>
                <tr>
                  <th>Details</th>
                  <th>Date Issued</th>
                </tr>
              </thead>
              <tbody>
                {advertisingOrders[id].map((order, i) => (
                  <tr key={`${id}_${i}`}>
                    <td>
                      <a
                        href={require(`../../docs/${order.doc}`)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFilePdf
                          style={{
                            fontSize: `1.5rem`,
                            marginRight: `.5rem`,
                          }}
                        />
                        <span>{order.title}</span>
                      </a>
                    </td>
                    <td>{order.date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {advertisingOrders[id][0].inCompliance && (
            <Alert type="success">
              NOTE: This vendor and/or builder has since come into compliance.
            </Alert>
          )}
        </>
      )}

      {suspensionOrders[id] && (
        <>
          <h4>Suspension Order</h4>
          {suspensionOrders[id][0].title && (
            <table style={{ marginBottom: `2rem` }}>
              <thead>
                <tr>
                  <th>Details</th>
                  <th>Date Issued</th>
                </tr>
              </thead>
              <tbody>
                {suspensionOrders[id].map((order, i) => (
                  <tr key={`${id}_${i}`}>
                    <td>
                      <a
                        href={require(`../../docs/${order.doc}`)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFilePdf
                          style={{
                            fontSize: `1.5rem`,
                            marginRight: `.5rem`,
                          }}
                        />
                        <span>{order.title}</span>
                      </a>
                    </td>
                    <td>{order.date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </>
  );
};

export default NOPtable;
