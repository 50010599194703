import React from "react";
import PageBackLink from "../components/PageBackLink/PageBackLink";
import PageHeader from "../components/PageHeader/PageHeader";
import PageFooter from "../components/PageFooter/PageFooter";
import Nav from "../components/Nav/Nav";
import Loader from "../components/Loader/Loader";
import Alert from "../components/Alert/Alert";
import { Container, Col } from "react-grid-system";

const PageLayout = ({
  pageHeader,
  pageSubHeader,
  backLink,
  backLinkUrl,
  backLinkText,
  loading,
  home,
  error,
  ...props
}) => {
  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <Nav home={home} />
          {/* {(backLink || backLinkUrl) &&
                        <PageBackLink  linkStr={backLinkUrl} text={backLinkText}/>
                    } */}
          <PageHeader header={pageHeader} subheader={pageSubHeader} />
          <Container>
            {error ? (
              <Alert type="error">
                <p>
                  Sorry, there has been an error: {error.status}{" "}
                  {error.statusText}
                </p>
              </Alert>
            ) : (
              <Col>
                <main className="pageBody">{props.children}</main>
              </Col>
            )}
          </Container>
          <PageFooter />
        </>
      )}
    </>
  );
};

export default PageLayout;
