import React from "react";
import { useMediaQuery } from "react-responsive";
import { FaFilePdf } from "react-icons/fa";

const DisciplineDecisionsTable = ({
  disciplineAllegations,
  disiplineDecisions,
  ...props
}) => {
  const isMobile = useMediaQuery({ maxWidth: 990 });

  return (
    <div className="overflowContainer">
      {!isMobile ? (
        <>
          <table>
            <thead>
              <tr>
                <th>Decision Date</th>
                <th>Licensee</th>
                <th>Decision</th>
                <th>Outcome</th>
                <th>Reasons</th>
                <th>Under Appeal</th>
              </tr>
            </thead>

            <tbody>
              {disiplineDecisions.map((row, i) => (
                <tr className="topAlign">
                  <td>{row.decisionDate}</td>
                  <td>{row.licensee}</td>
                  <td style={{ minWidth: `24rem` }}>{row.decisionTxt}</td>
                  <td style={{ minWidth: `24rem` }}>{row.outcomesTxt}</td>
                  <td style={{ minWidth: `16rem` }}>
                    <a
                      href={require(`../../docs/${row.doc}`)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFilePdf
                        style={{
                          fontSize: `1.5rem`,
                          marginRight: `.5rem`,
                        }}
                      />
                      <span>Decision Reasons</span>
                    </a>
                  </td>

                  <td>{row.appealed ? "Yes" : "---"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <>
          {disiplineDecisions.map((row) => (
            <div className="tile">
              <div>
                <span className="label">Decision Date: </span>
                <span>{row.decisionDate}</span>
              </div>

              <div>
                <span className="label">Licensee: </span>
                <span>{row.licensee}</span>
              </div>

              <div>
                <span className="label">Decision: </span>
                <span>{row.decisionTxt}</span>
              </div>

              <div>
                <span className="label">Outcome: </span>
                <span>{row.outcomesTxt}</span>
              </div>

              <div>
                <span className="label">Reasons: </span>
                <span>
                  <a
                    href={require(`../../docs/${row.doc}`)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFilePdf
                      style={{
                        fontSize: `1.5rem`,
                        marginRight: `.5rem`,
                      }}
                    />
                    <span>Decision Reasons</span>
                  </a>
                </span>
              </div>

              <div>
                <span className="label">Under Appeal(s): </span>
                <span>
                  {row.appealed ? (
                    <span>{row.hearingDate}</span>
                  ) : (
                    <span>---</span>
                  )}
                </span>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default DisciplineDecisionsTable;
