import React from 'react';
import ClassNames from 'classnames';
import componentStyles from './PillInput.module.scss';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

const PillSortInput = ({
    name, 
    label, 
    id,
    active,
    order,
    clickEvt, 
    ...props}) => {

    return(
        <button 
            id={id}
            className={active ? ClassNames(componentStyles.pillLabel, componentStyles.checked) : componentStyles.pillLabel}
            onClick={clickEvt}>
            <div className={componentStyles.pseudoCheck}>
               {order==='desc' ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            <span>{label}</span>
        </button>
    )
}

export default PillSortInput;