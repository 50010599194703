import React from "react";
import { useMediaQuery } from "react-responsive";

const PDOlist = ({ pdoArr, ...props }) => {
  const isMobile = useMediaQuery({ maxWidth: 990 });
  return (
    <div className="overflowContainer">
      {!isMobile ? (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Role(s)</th>
              <th>Contact (phone or email)</th>
            </tr>
          </thead>
          <tbody>
            {pdoArr.map((person, i) => (
              <tr key={`${person.name.replace(/\s/g, "")}_${i}`}>
                <td>{person.name}</td>
                <td>{person.positionsArr.sort().join(", ")}</td>
                <td>{person.contact ? person.contact : `---`}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <>
          {pdoArr.map((person, i) => {
            return (
              <div
                className="tile"
                key={`${person.name.replace(/\s/g, "")}_${i}`}
              >
                <div>
                  <span className="label">Name: </span>
                  <span>{person.name}</span>
                </div>

                <div>
                  <span className="label">Role(s): </span>
                  <span>{person.positionsArr.sort().join(", ")}</span>
                </div>

                <div>
                  <span className="label">Contact: </span>
                  <span>{person.contact ? person.contact : `---`}</span>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default PDOlist;
