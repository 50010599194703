import {
  GET_BUILDERS,
  GET_BUILDERINFO,
  GET_BUILDERCONDOS,
  GET_BUILDERPROPS,
  GET_BUILDERENROLMENTS,
  GET_PDOCONVICTIONS,
  GET_BUILDER_NAMES,
  GET_ENROLMENT_CITY_NAMES,
  GET_CITY_NAMES,
  CLEAR_SUGGESTIONS,
  CLEAR_BUILDERS,
  UPDATE_SEARCH,
  CLEAR_SEARCH,
  ORDER_SEARCH_RESULTS,
  FILTER_SEARCH_RESULTS,
  GET_ENROLMENTS,
  ORDER_ENROLMENT_SEARCH_RESULTS,
  CLEAR_FILTERS,
  SET_SEARCHPAGE,
  BUILDERS_LOADING,
  ITEMS_LOADING,
  PROPERTIES_LOADING,
  ERROR_LOADING,
  GET_CONVICTINFO,
} from "../actions/types";
import { sortAsc, sortDesc } from "../utils/sortFunctions";

const initialState = {
  builders: [],
  enrolments: [],

  searchTerms: {
    builderNum: "",
    builderName: "",
    builderLocation: "",
    licenceStatus: "",
    officerDirector: "",
    umbrellaCo: "",

    enrolAddress: "",
    enrolCity: "",
    enrolLot: "",
    enrolPlan: "",
  },
  autoCompleteNames: [],
  autoCompleteCities: [],
  autoCompleteEnrolCities: [],

  searchPagination: 1,
  searchTotalCount: 0,

  searchOrderBy: "",
  searchOrderDirection: "asc",

  searchFilters: {
    licensed: "",
    licenceState: "",
    city: [],
    hometype: "",
  },

  profiledata: "",
  profileContact: "",

  profilePDOs: [],
  profileenrolments: [],
  profilecondos: [],
  profileconvictions: [],
  profileMembers: [],
  profilePDOconvictions: [],
  profileAgVals: [],
  profileConditions: [],

  profileproperties: [],
  propertiesLoading: false,

  loading: false,
  itemsLoading: false,
  errorMessage: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BUILDERS:
      return {
        ...state,
        builders:
          state.searchOrderDirection === "desc"
            ? sortDesc(action.payload.builders, state.searchOrderBy)
            : sortAsc(action.payload.builders, state.searchOrderBy),
        loading: false,
        searchTotalCount: action.payload.searchTotalCount,
        errorMessage: "",
      };
    case GET_BUILDERINFO:
      return {
        ...state,
        profiledata: action.payload.profiledata,
        profileMembers: sortAsc(action.payload.profileMembers, "memberName"),
        profileContact: action.payload.profileContact,
        profilePDOs: action.payload.profilePDOs,
        profileconvictions: action.payload.profileConvictions,
        profileConditions: action.payload.profileConditions,

        loading: false,
        errorMessage: "",
      };
    case GET_PDOCONVICTIONS:
      return {
        ...state,
        profilePDOconvictions: action.payload,
        itemsLoading: false,
      };
    case GET_BUILDERPROPS:
      return {
        ...state,
        profileproperties: action.payload,
        propertiesLoading: false,
      };
    case GET_BUILDERCONDOS:
      return {
        ...state,
        profilecondos: action.payload,
        itemsLoading: false,
      };
    case GET_BUILDERENROLMENTS:
      return {
        ...state,
        profileenrolments: action.payload,
        itemsLoading: false,
      };
    case GET_BUILDER_NAMES:
      return {
        ...state,
        autoCompleteNames: action.payload,
      };
    case GET_CITY_NAMES:
      return {
        ...state,
        autoCompleteCities: action.payload,
      };
    case GET_ENROLMENT_CITY_NAMES:
      return {
        ...state,
        autoCompleteEnrolCities: action.payload,
      };
    case GET_ENROLMENTS:
      return {
        ...state,
        enrolments: action.payload.enrolments,
        searchTerms: action.payload.searchTerms,
        loading: false,
        errorMessage: "",
      };
    case CLEAR_SUGGESTIONS:
      return {
        ...state,
        autoCompleteNames: [],
        autoCompleteCities: [],
        autoCompleteEnrolCities: [],
      };
    case GET_CONVICTINFO:
      return {
        ...state,
        profiledata: action.payload[0],
        profileproperties: [],
        profilecondos: [],
        profileConditions: [],
        profileconvictions: action.payload,
        loading: false,
        errorMessage: "",
      };
    case UPDATE_SEARCH:
      return {
        ...state,
        searchTerms: action.payload,
      };
    case CLEAR_SEARCH:
      return {
        ...state,
        searchTerms: {},
      };
    case SET_SEARCHPAGE:
      return {
        ...state,
        searchPagination: action.payload,
      };
    case ORDER_SEARCH_RESULTS:
      return {
        ...state,
        builders:
          action.payload.order === "desc"
            ? sortDesc(state.builders, action.payload.filter)
            : sortAsc(state.builders, action.payload.filter),
        searchOrderDirection: action.payload.order
          ? action.payload.order
          : "asc",
        searchOrderBy: action.payload.filter,
      };
    case ORDER_ENROLMENT_SEARCH_RESULTS:
      return {
        ...state,
        enrolments:
          action.payload.order === "desc"
            ? sortDesc(state.enrolments, action.payload.filter)
            : sortAsc(state.enrolments, action.payload.filter),
        searchOrderDirection: action.payload.order
          ? action.payload.order
          : "asc",
        searchOrderBy: action.payload.filter,
      };

    case FILTER_SEARCH_RESULTS:
      return {
        ...state,
        searchFilters: action.payload,
        searchPagination: 1,
      };
    case CLEAR_FILTERS:
      return {
        ...state,
        searchFilters: {
          licensed: "",
          licenceState: "",
          city: [],
          hometype: "",
        },
      };
    case CLEAR_BUILDERS:
      return {
        ...state,
        builders: [],
        searchOrderBy: "",
        searchPagination: 1,
        searchOrderDirection: "asc",
        searchFilters: {
          licensed: "",
          licenceState: "",
          city: [],
          hometype: "",
        },
      };
    case BUILDERS_LOADING:
      return {
        ...state,
        errorMessage: "",
        loading: true,
      };
    case ITEMS_LOADING:
      return {
        ...state,
        errorMessage: "",
        itemsLoading: true,
      };
    case PROPERTIES_LOADING:
      return {
        ...state,
        errorMessage: "",
        propertiesLoading: true,
      };
    case ERROR_LOADING:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
}
