export const GET_BUILDERS = 'GET_BUILDERS';
export const GET_BUILDERINFO = 'GET_BUILDERINFO';
export const GET_BUILDERCONDOS = 'GET_BUILDERCONDOS';
export const GET_BUILDERPROPS = 'GET_BUILDERPROPS';
export const GET_UMBRELLAPROPS = 'GET_UMBRELLAPROPS';

export const GET_BUILDERENROLMENTS = 'GET_BUILDERENROLMENTS';
export const GET_BUILDERCONVICTIONS = 'GET_BUILDERCONVICTIONS';
export const GET_PDOCONVICTIONS = 'GET_PDOCONVICTIONS';
export const GET_BUILDER_NAMES = 'GET_BUILDER_NAMES';

export const GET_CITY_NAMES = 'GET_CITY_NAMES';
export const GET_ENROLMENT_CITY_NAMES = 'GET_ENROLMENT_CITY_NAMES';
export const CLEAR_BUILDERS = 'CLEAR_BUILDERS';
export const CLEAR_SUGGESTIONS = 'CLEAR_SUGGESTIONS';

export const BUILDERS_LOADING = 'BUILDERS_LOADING';
export const ITEMS_LOADING = 'ITEMS_LOADING'
export const PROPERTIES_LOADING = 'PROPERTIES_LOADING'

export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';

export const SET_SEARCHPAGE = 'SET_SEARCHPAGE';
export const ORDER_SEARCH_RESULTS = 'ORDER_SEARCH_RESULTS';
export const FILTER_SEARCH_RESULTS = 'FILTER_SEARCH_RESULTS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';

export const GET_ENROLMENTS = 'GET_ENROLMENTS';
export const ORDER_ENROLMENT_SEARCH_RESULTS = 'ORDER_ENROLMENT_SEARCH_RESULTS';
export const FILTER_ENROLMENT_SEARCH_RESULTS = 'FILTER_ENROLMENT_SEARCH_RESULTS';

export const ERROR_LOADING = 'ERROR_LOADING';

export const GET_CONVICTINFO = 'GET_CONVICTINFO';

export const GET_SFTEST = 'GET_SFTEST';

