import React, { useState } from "react";
import ClassNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { FaPlus, FaMinus } from 'react-icons/fa';
import componentStyles from './SortControls.module.scss';

const SortControls = ({children, ...props}) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [accordianOpen, toggleAccoridan] = useState(false);

    return (
        <div className={componentStyles.filtersMain}>
            <div className={componentStyles.toggleControls}>
                <span className={componentStyles.instructions}>Sort Results:</span>

                {isMobile &&
                    <button onClick={() => toggleAccoridan(!accordianOpen)}>
                        {accordianOpen ? <FaMinus /> : <FaPlus />}
                    </button> 
                }
            </div>
            
            <div 
                aria-expanded={accordianOpen} 
                className={accordianOpen ? ClassNames(componentStyles.active, componentStyles.filtersContainer) : componentStyles.filtersContainer}>
                {children}
            </div>

        </div>
    );
  }
 
export default SortControls;