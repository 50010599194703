import React from "react";
import { MdCheckCircle, MdCancel } from "react-icons/md";
import { sortAsc } from "./sortFunctions";
import { determineStatusOverride } from "./nopOverrides";

const licenseStateObj = {
  820180000: "Licensed",
  820180004: "Refused",
  820180005: "Revoked",
  820180006: "Expired",
};
// 820180001, 820180002 = NO EXAMPLES

export const getLicenceStatus = (stateCode) => {
  return licenseStateObj[`${stateCode}`] ? licenseStateObj[`${stateCode}`] : "";
};

// normalizeBuilderData
// Params: arr of builder objs from vb table
// Returns: normalized arr of objs
export const normalizeBuilderData = (builderArr) => {
  return builderArr.map((builder) => {
    let type, licensed, licenceStatus;
    if (builder["LICENSESTATUS"] === "NULL/UMBRELLA") {
      type = "umbrella";
      licensed = null;
      licenceStatus = null;
    } else if (builder["LICENSESTATUS"] === "NULL/TarionConviction") {
      type = "unlicensed";
      licensed = false;
      licenceStatus = "Charged and/or Convicted";
    } else {
      type = "vb";
      licensed =
        builder["LICENSESTATUS"] === "Licensed" ||
        builder["LICENSESTATUS"] === "Licensed with Conditions"
          ? "true"
          : "false";
      licenceStatus = builder["LICENSESTATUS"] ? builder["LICENSESTATUS"] : "";
    }

    return {
      nameSort: builder["VB.NAME"],
      type: type,
      id: builder["ACCOUNTNUMBER"],
      licenceNumber: builder["HCRA_LICENSENUMBER"],
      umbrellaId: builder["Umbrella ID"],
      name: builder["NAME"],
      operatingName: builder["OPERATINGNAME"]
        ? builder["OPERATINGNAME"]
        : builder["NAME"],
      city: builder["ADDRESS_2_CITY"]
        ? builder["ADDRESS_2_CITY"].toLowerCase().trim()
        : "",
      charIndex:
        typeof builder["NAMECHARINDEX"] === "number"
          ? builder["NAMECHARINDEX"]
          : null,
      licensed: licensed,
      licenseState: determineStatusOverride(
        builder["ACCOUNTNUMBER"],
        licenceStatus
      ),
      totalCount: builder["TOTALCOUNT"],
    };
  });
};

export const normalizeUmbrellaData = (umbrellaArr) => {
  return umbrellaArr.map((umbrella) => {
    return {
      type: "umbrella",
      nameSort: umbrella["NAME"],
      id: umbrella["Account Number"],
      licenceNumber: null,
      umbrellaId: null,
      name: umbrella["NAME"],
      city: "",
      charIndex:
        typeof umbrella["NAMECHARINDEX"] === "number"
          ? umbrella["NAMECHARINDEX"]
          : null,

      licensed: null,
      licenseState: null,
    };
  });
};

// normalizeConvictData
// Params: arr of convict objs from agg convictions table
// Returns: normalized arr of objs
export const normalizeConvictData = (convictArr) =>
  convictArr.map((builder) => {
    let idinfo = {};
    if (builder.VB_NUMBER && builder.VB_NUMBER !== "NULL") {
      idinfo.id = builder.VB_NUMBER;
      idinfo.type = "VB_NUMBER";
    } else if (builder.PERSON_ID && builder.PERSON_ID !== "NULL") {
      idinfo.id = builder.PERSON_ID;
      idinfo.type = "PERSON_ID";
    } else {
      idinfo.id = builder.ORGANIZATION_ID;
      idinfo.type = "ORGANIZATION_ID";
    }
    return {
      id: idinfo.id,
      idtype: idinfo.type,
      licenceNumber: null,
      nameSort: builder.CHARGE_AGAINST,
      name: builder.CHARGE_AGAINST,
      umbrellaId: null,
      umbrellaName: null,
      city: builder.CITY_OFFENCE.toLowerCase().split(",")[0],
      licensed: false,
      licenseState: "Charged and/or Convicted",
    };
  });

// normalizePDOs
// params: arr of builder pdos with various roles
// returns: obj of pdos with keys of roles
export const normalizePDOs = (pdoArr) => {
  // pdoObj
  // personIds - arr of person ID's, used in PDO conviction query
  //persons{
  //     personID{
  //         name
  //         positionsArr
  //         contact
  //     }
  // }
  const personsTempObj = pdoArr.reduce((acc, person) => {
    if (!acc[person["TOUNIQUENUMBER"]]) {
      acc[person["TOUNIQUENUMBER"]] = {
        name: person["TO"],
        positionsArr: [person["Relationship Type"]],
        contact: person["PDO_CONTACT_INFO"] ? person["PDO_CONTACT_INFO"] : null,
        status: person["STATUS"],
      };
    } else {
      acc[person["TOUNIQUENUMBER"]].positionsArr.push(
        person["Relationship Type"]
      );
    }
    return acc;
  }, {});

  const personsArr = [];
  Object.keys(personsTempObj).map((id) => personsArr.push(personsTempObj[id]));

  const pdoObj = {
    personIds: Object.keys(personsTempObj),
    persons: sortAsc(personsArr, "name"),
  };

  return pdoObj;
};

// getUmbrellaList
// Params: arr of convict objs from agg convictions table
// Returns: normalized obj
export const getUmbrellaMemberList = (memberArr) => {
  return memberArr.map((row) => {
    let licenceStatus;
    if (row["MEMBERLICENCESTATUS"] === "NULL/TarionConviction") {
      licenceStatus = "Charged and/or Convicted";
    } else {
      licenceStatus = row["MEMBERLICENCESTATUS"]
        ? row["MEMBERLICENCESTATUS"]
        : "";
    }
    return {
      memberNum: row["MEMBERVBNUM"],
      memberName: row["MEMBERNAME"],
      memberOperatingName: row["MEMBEROPERATINGNAME"]
        ? row["MEMBEROPERATINGNAME"]
        : row["MEMBERNAME"],
      memberStatus: determineStatusOverride(row["MEMBERVBNUM"], licenceStatus),
    };
  });
};

// isLicensedCheck
// Params: string (licence status value from VB view)
// Returns: bool
export const isLicensedCheck = (statusString) => {
  if (
    statusString === "REG" ||
    statusString === "CON" ||
    statusString === "Licensed – NOP Under Appeal"
  )
    return true;
  else return false;
};

// normalizeLicenceStatus
// Params: string (licence status value from VB view)
// Returns: string
export const normalizeLicenceStatus = (statusString) => {
  if (statusString === "REG") return "Licensed";
  else if (statusString === "CON") return "Licensed with Conditions";
  else if (statusString === "EXP") return "Expired";
  else if (statusString === "REF") return "Refused";
  else if (statusString === "REV") return "Revoked";
  else if (statusString === "CNV") return "Charged and/or Convicted";
  else return "N/A";
};

// determineBoolClass
// Params: string
// Returns: string (CSS class to be applied)
export const determineBoolClass = (statusStr) => {
  if (
    statusStr &&
    (statusStr === "REG" ||
      statusStr === "Licensed" ||
      statusStr === "Suspended" ||
      statusStr === "CON" ||
      statusStr.includes("Licensed"))
  )
    return "licensed bold";
  else if (
    statusStr &&
    (statusStr === "CNV" || statusStr === "Charged and/or Convicted")
  )
    return "convicted bold";
  else return "unlicensed bold";
};

// determineLicenceClass
// Params: string (licence status)
// Returns: string (CSS class to be applied)
export const determineLicenceClass = (statusStr) => {
  if (statusStr === "REG" || statusStr === "Licensed") return "licensed bold";
  if (
    statusStr === "CNV" ||
    statusStr === "Charged and Convicted" ||
    statusStr === "Charged and/or Convicted"
  )
    return "convicted bold";
  else return "unlicensed bold";
};

// licenceBadge
// Params: string (licence status)
// Returns: JSX obj (badge with corresponding icon & classes)
export const licenceBadge = (statusStr) => {
  if (
    statusStr === "REG" ||
    statusStr === "Licensed" ||
    statusStr === "CON" ||
    statusStr === "Suspended" ||
    statusStr.indexOf("Licensed") > -1
  ) {
    return (
      <>
        <MdCheckCircle
          style={{
            verticalAlign: "center",
            marginRight: ".5rem",
            fontSize: "1.5rem",
          }}
        />{" "}
        Yes
      </>
    );
  } else if (statusStr === "N/A") {
    return <span className="bold">N/A</span>;
  } else {
    return (
      <>
        <MdCancel
          style={{
            verticalAlign: "center",
            marginRight: ".5rem",
            fontSize: "1.5rem",
          }}
        />
        No
      </>
    );
  }
};
