import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getUmbrellaInfo } from "../actions/builderActions";
import { Container, Row, Col } from "react-grid-system";

import ProfileLayout from "../layouts/ProfileLayout";
import Alert from "../components/Alert/Alert";
import TabSidebar from "../components/TabSidebar/TabSidebar";
import AtAGlance from "../components/AtAGlance/AtAGlance";
import {
  FaUserCircle,
  FaFileAlt,
  FaBalanceScale,
  FaGavel,
  FaHome,
  FaBuilding,
  FaBriefcase,
} from "react-icons/fa";
import Loader from "../components/Loader/Loader";

const MemberList = React.lazy(() =>
  import("../components/MemberList/MemberList")
);
const PropertiesList = React.lazy(() =>
  import("../components/PropertiesList/PropertiesList")
);
const CondoProjectsList = React.lazy(() =>
  import("../components/CondoProjectsList/CondoProjectsList")
);

const UmbrellaProfilePage = ({
  getUmbrellaInfo,
  loading,
  builderDetails,
  contactDetails,
  profilePDOs,
  profileProperties,
  profileEnrolments,
  profileMembers,
  ...props
}) => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const trySetActiveTab = (tabInd, disabled) => {
    if (disabled) return;
    else setActiveTab(tabInd);
  };

  useEffect(() => {
    getUmbrellaInfo(id);
  }, [id]);
  return (
    <React.Suspense fallback={<Loader />}>
      <ProfileLayout
        builderName={
          builderDetails && builderDetails.UMBRELLANAME
            ? builderDetails.UMBRELLANAME
            : null
        }
        umbrella={null}
        umbrellaId={null}
        licencestatus={null}
        lastUpdate={null}
        initiallyLicensed={
          builderDetails && builderDetails.INITIALLYLICENSED
            ? builderDetails.INITIALLYLICENSED
            : null
        }
        loading={loading}
      >
        <Container>
          {!builderDetails && (
            <Alert>
              <p>Sorry, no builder matching that ID can be found.</p>
            </Alert>
          )}

          {builderDetails && (
            <TabSidebar activeTab={activeTab} clickEvt={trySetActiveTab}>
              <div id="members" text="Related Companies" icon={<FaBriefcase />}>
                <div>
                  <div>
                    <h2>Related Companies</h2>
                    <p>
                      Related companies are vendors or builders that share
                      common leadership (Principals, Directors or Officers).
                      <br />
                      Information on related companies may be helpful for
                      consumers researching builders and vendors, to give a full
                      picture of the company’s operations and history –
                      including other projects the leadership has been involved
                      with, their experience and track record of conduct.
                    </p>
                    <MemberList members={profileMembers} />
                  </div>
                </div>
              </div>

              {/* OVERVIEW */}
              <div id="overview" text="Overview" icon={<FaUserCircle />}>
                <AtAGlance
                  clickEvt={trySetActiveTab}
                  profileType="umbrella"
                  totalunits={
                    builderDetails["SUMM_TOTAL"]
                      ? builderDetails["SUMM_TOTAL"]
                      : 0
                  }
                  lowrise={
                    builderDetails["SUMM_FREEHOLD"]
                      ? builderDetails["SUMM_FREEHOLD"]
                      : 0
                  }
                  condoProjects={
                    builderDetails["SUMM_CONDO"]
                      ? builderDetails["SUMM_CONDO"]
                      : 0
                  }
                  // notices
                  // orders
                  chargeableConciliations={
                    builderDetails.CHARGEABLECONCILIATION
                  }
                  totalClaims={builderDetails.TOTAL_CLAIMS}
                  outstanding={builderDetails.OUTSTANDINGAMT}
                  breach={builderDetails.BREACH}
                  tab={builderDetails.OUTSTANDINGAMTTAB}
                  isUmbrella={true}
                />

                <div style={{ marginTop: `2rem` }}>
                  <h2>Overview</h2>
                  <div className="fieldset">
                    <Container>
                      <Row>
                        {builderDetails["ADDRESS"] && (
                          <Col md={6}>
                            <span className="bold">Address</span>
                            <p>
                              {builderDetails["ADDRESS"]}
                              <br />
                              {builderDetails["City"]}
                            </p>
                          </Col>
                        )}

                        {builderDetails["WEBSITEURL"] &&
                          builderDetails["WEBSITEURL"] !== "NULL" &&
                          builderDetails["WEBSITEURL"] !== " " && (
                            <Col md={6}>
                              <span className="bold">Website</span>
                              <p>{builderDetails["WEBSITEURL"]}</p>
                            </Col>
                          )}

                        {builderDetails["EMAIL"] &&
                          builderDetails["EMAIL"] !== "NULL" && (
                            <Col md={6}>
                              <span className="bold">Email</span>
                              <p>{builderDetails["EMAIL"]}</p>
                            </Col>
                          )}

                        {builderDetails["TELEPHONE"] &&
                          builderDetails["TELEPHONE"] !== "NULL" && (
                            <Col md={6}>
                              <span className="bold">Phone Number</span>
                              <p>{builderDetails["TELEPHONE"]}</p>
                            </Col>
                          )}

                        {builderDetails["FAX"] &&
                          builderDetails["FAX"] !== "NULL" && (
                            <Col md={6}>
                              <span className="bold">Fax Number</span>
                              <p>{builderDetails["FAX"]}</p>
                            </Col>
                          )}
                      </Row>
                    </Container>
                  </div>
                </div>
              </div>

              <div id="properties" text="Properties" icon={<FaHome />}>
                <h2>Properties</h2>
                <PropertiesList isUmbrella={true} />
              </div>

              <div
                id="condoProjects"
                text="Condo Projects"
                icon={<FaBuilding />}
              >
                <h2>Condo Projects</h2>
                <p>
                  Information received from Tarion is updated daily and
                  available on the Ontario Builder Directly within 24 hours of
                  receipt. As of January 1st, 2023, details on the number of
                  condominium purchase agreements that a builder or vendor has
                  terminated, along with the reasons for the termination are
                  disclosed under the “# of Purchase Agreements Terminated by
                  Vendor” heading.
                </p>
                <p>
                  If you have any questions about the information shown below,
                  or can't find the project you are searching for, please
                  contact us.
                </p>
                <CondoProjectsList
                  queryID="umbrella"
                  members={profileMembers}
                />
              </div>
            </TabSidebar>
          )}
        </Container>
      </ProfileLayout>
    </React.Suspense>
  );
};

const mapStateToProps = (state) => ({
  builderDetails: state.builder.profiledata,
  contactDetails: state.builder.profileContact,
  profilePDOs: state.builder.profilePDOs,
  profileProperties: state.builder.profileproperties,
  profileEnrolments: state.builder.profileenrolments,
  profileMembers: state.builder.profileMembers,
  loading: state.builder.loading,
});

export default connect(mapStateToProps, { getUmbrellaInfo })(
  UmbrellaProfilePage
);
